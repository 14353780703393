import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {FormArray, FormGroup} from '@angular/forms';
import {getForm} from 'apc-forms';

@Component({
  selector: 'app-new-lessons',
  templateUrl: './new-lessons.component.html',
  styleUrls: ['./new-lessons.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class NewLessonsComponent implements OnInit {
  form: FormGroup;

  courses = {
    title: '',
    thumbnail: '',
    cover: '',
    description: '',
    intro: '',
    learn: ['', '', '', ''],
    // requirements: ['', '', '', ''],
    video_sections: [{ uid: '', title: '', lessons: [] || [{ uid: '', title: '', startDate: '', duration: 0, video: '', poster: '', files: [] }] }],
    // video_sections: [{ uid: '', title: '', lessons: [] }],
  };

  constructor() { }

  ngOnInit() {
    this.form = getForm(this.courses);
  }

  manageFormItem(name, index = null) {
    return index !== null ? (this.form.get(name) as FormArray).removeAt(index) :
      (this.form.get(name) as FormArray).push(getForm(this.courses[name][0]));
  }

}
