import {Component, OnDestroy, OnInit} from '@angular/core';
import {environment} from '../../../environments/environment';
import {forkJoin, of, Subject} from 'rxjs';
import {ToastService} from '../../services/app-toast.service';
import {ActivatedRoute, Router} from '@angular/router';
import {MediatorService} from '../../services/mediator.service';
import {RequestsService} from '../../services/requests.service';
import {LoggedUserService} from '../../services/logged-user.service';
import {FranchisesService} from '../franchises/franchises.service';
import {concatMap, takeUntil} from 'rxjs/operators';
import {StudentsService} from '../students/students.service';
import {OverviewService} from './overview.service';
import {AddDebtDialogComponent} from '../../shared/components/dialogs/add-debt-dialog/add-debt-dialog.component';
import {MatDialog, MatDialogConfig} from '@angular/material';
import {animate, keyframes, transition, trigger} from '@angular/animations';
import * as kf from '../../animations/keyframes';
import * as moment from 'moment';
import {GenerateCertificateDialogComponent} from '../../shared/components/dialogs/generate-certificate-dialog/generate-certificate-dialog.component';
import {TableService} from "../../services/table.service";
import {AddEditFranchiseRoyaltyComponent} from "../../shared/components/dialogs/add-edit-franchise-royalty/add-edit-franchise-royalty.component";

@Component({
  selector: 'app-overview',
  templateUrl: './overview.component.html',
  styleUrls: ['./overview.component.scss'],
  animations: [
    trigger('fadeIn', [
      transition(':enter', animate(500, keyframes(kf.fadeIn)))
    ])
  ]
})
export class OverviewComponent implements OnInit, OnDestroy {
  url = '';
  listLabel = '';
  selectedTab = 0;
  paymentUrl = '';
  paymentUntilUrl = '';
  paymentRevenueUrl = '';
  date = moment().format('YYYY-MM-DD');
  environment = environment;

  destroy$: Subject<boolean> = new Subject();

  showSelects: boolean;

  constructor(
    private router: Router,
    public dialog: MatDialog,
    private toast: ToastService,
    private route: ActivatedRoute,
    private table: TableService,
    public students: StudentsService,
    public overview: OverviewService,
    public mediator: MediatorService,
    private requests: RequestsService,
    public franchises: FranchisesService,
    public loggedUser: LoggedUserService,
  ) {
  }

  ngOnInit() {
    this.route.parent.parent.params.pipe(
      concatMap((params: any) => this.getData(params)), takeUntil(this.destroy$)).subscribe((data: any) => {
      if (data != null) {
        this.franchises.isSummaryVisible = true;
        this.setPaymentData(data);
        if (data.hasOwnProperty(2)) {
          this.franchises.franchiseList = data[2].children;
        }
      }
    });

    this.mediator.searchValue$.pipe(takeUntil(this.destroy$)).subscribe(val => {
      if (val != null) {
        this.selectedTab = 0;
      }
    });

    this.mediator.showStudentSelects$.pipe(takeUntil(this.destroy$)).subscribe(value => {
      this.selectedTab = 0;
      this.showSelects = value;
    });

    this.franchises.stateChange$.pipe(takeUntil(this.destroy$)).subscribe((command: string) => {
      switch (command) {
        case 'studentAdded':
        case 'studentEdited': {
          this.updateFranchiseInfo();
          break;
        }
        case 'groupAdded':
        case 'groupEdited':
        case 'franchiseEdited': {
          this.updateFranchiseInfo();
          break;
        }
      }
    });

    this.franchises.transactionCompletes$.pipe(
      concatMap(id => {
        if (this.loggedUser.isAdmin && this.overview.isOwnOverview) {
          return this.requests.svc_get(this.paymentUrl, {}, this.loggedUser.getToken());
        } else {
          return forkJoin([
            this.requests.svc_get(this.paymentUntilUrl, {}, this.loggedUser.getToken()),
            this.requests.svc_get(this.paymentRevenueUrl, {}, this.loggedUser.getToken())
          ]);
        }
      }), takeUntil(this.destroy$)).subscribe((data: any) => this.setPaymentData(data));

    this.students.transactionCompletes$.pipe(
      concatMap(id => {
        const url = `/api/student/${id}/payments`;
        return this.requests.svc_get(url, {}, this.loggedUser.getToken());
      }), takeUntil(this.destroy$)).subscribe((data: any) => this.setPaymentData(data));
  }

  ngOnDestroy() {
    this.mediator.showStudentSelects$.next(false);

    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  getData(params: any) {
    if ('franchise-id' in params) {
      this.franchises.id = +params['franchise-id'];
    }

    this.overview.overviewType = this.route.routeConfig.data.hasOwnProperty('overviewType') ?
      this.route.routeConfig.data.overviewType : '';

    const isMaster = this.route.parent.parent.routeConfig.path !== 'students' &&
      ('master-franchise-id' in params || (this.overview.isMasterOverview ||
        (this.overview.isOwnOverview && this.loggedUser.isFranchisor && !this.loggedUser.isAdmin)));

    if (isMaster) {
      this.franchises.id = +params['master-franchise-id'];
      this.franchises.currentFranchise = this.franchises.masterFranchiseData;
    } else {
      this.franchises.currentFranchise = this.franchises.franchiseData;
    }

    this.franchises.franchiseDataSets$.next(true);

    if (this.overview.isOwnOverview && this.loggedUser.isAdmin) {
      this.listLabel = 'masterFranchises';
      this.paymentUrl = `/api/top-franchise/planed_revenue?date=${this.date}`;
    } else {
      const id = this.loggedUser.franchises[0].id;
      this.paymentUntilUrl = `/api/master-franchises/${id}/planed_until_date?date=${this.date}`;
      this.paymentRevenueUrl = `/api/master-franchises/${id}/planed_revenue?date=${this.date}`;

      if (this.overview.isOwnOverview &&
        (this.loggedUser.isFranchisor && !(this.loggedUser.isAdmin || this.loggedUser.isInstructor) && this.loggedUser.isMaster)) {
        this.listLabel = 'franchises';
      } else if (this.overview.isOwnOverview && (this.loggedUser.isInstructor || this.loggedUser.isFranchisor) && !this.loggedUser.isMaster) {
        this.listLabel = 'groups';
      } else if (this.overview.isOwnOverview) {
        this.listLabel = 'franchises';
      }
    }

    let baseUrl = '';
    if (this.overview.isMasterOverview) {
      const paymentBaseUrl = `/api/master-franchises/${this.franchises.masterFranchiseData.id}`;
      this.listLabel = 'franchises';
      this.paymentUntilUrl = `${paymentBaseUrl}/planed_until_date?date=${this.date}`;
      this.paymentRevenueUrl = `${paymentBaseUrl}/planed_revenue?date=${this.date}`;
    } else if (this.overview.isFranchiseOverview) {
      baseUrl = `/api/franchises/${this.franchises.currentFranchise.id}`;
      this.listLabel = 'groups';
      this.paymentUntilUrl = `${baseUrl}/planed_until_date?date=${this.date}`;
      this.paymentRevenueUrl = `${baseUrl}/planed_revenue?date=${this.date}`;
    } else if (this.loggedUser.isInstructor && this.overview.isOwnOverview) {
      baseUrl = `/api/franchises/${this.franchises.currentFranchise.id}`;
      this.paymentUntilUrl = `${baseUrl}/planed_until_date?date=${this.date}`;
      this.paymentRevenueUrl = `${baseUrl}/planed_revenue?date=${this.date}`;
    } else if (this.overview.isGroupOverview) {
      this.listLabel = 'students';
      baseUrl = `/api/franchises/${this.franchises.currentFranchise.id}`;
      this.paymentUntilUrl = `${baseUrl}/planed_until_date?date=${this.date}`;
      this.paymentRevenueUrl = `${baseUrl}/planed_revenue?date=${this.date}`;
    } else if (this.overview.isStudentOverview) {
      this.listLabel = '';
    }

    if (this.overview.isOwnOverview && this.loggedUser.isAdmin) {
      return this.requests.svc_get(this.paymentUrl, {}, this.loggedUser.getToken());
    } else if (!this.overview.isStudentOverview && !(this.loggedUser.isInstructor && !this.loggedUser.isFranchisor)) {
      const childrenUrl = `/api/franchises/${this.franchises.currentFranchise.parent.id}/children`;
      return forkJoin([
        this.requests.svc_get(this.paymentUntilUrl, {}, this.loggedUser.getToken()),
        this.requests.svc_get(this.paymentRevenueUrl, {}, this.loggedUser.getToken()),
        this.requests.svc_get(childrenUrl, {}, this.loggedUser.getToken())
      ]);
    } else if ((this.loggedUser.isInstructor || this.loggedUser.isFranchisor) && !this.loggedUser.isMaster && this.overview.isOwnOverview) {
      return forkJoin([
        this.requests.svc_get(this.paymentUntilUrl, {}, this.loggedUser.getToken()),
        this.requests.svc_get(this.paymentRevenueUrl, {}, this.loggedUser.getToken()),
      ]);
    } else if (this.overview.isStudentOverview) {
      return this.requests.svc_get(`/api/student/${this.students.studentData.id}/payments`, {}, this.loggedUser.getToken());
    }
  }

  onAdd(url) {
    this.navigate(url);
  }

  addPayment() {
    this.dialog.open(AddDebtDialogComponent, {
      panelClass: 'custom-dialog',
      data: {studentId: this.students.studentData.id}
    } as MatDialogConfig).afterClosed().subscribe((confirmed: boolean) => {
      if (confirmed) {
        this.students.transactionCompletes$.next(this.students.studentData.id);
      }
    });
  }

  setPaymentData(data: any) {
    if (data.hasOwnProperty('payments') || data.hasOwnProperty('parts')) {
      if (this.overview.isStudentOverview) {
        this.students.paymentData = data.payments;
        this.students.plannedPaymentData = data.parts;
        this.students.paymentsSummary.currency = data.currency;
        this.students.paymentsSummary['total-summary'] = data.total;
        this.students.paymentsSummary['parts-summary'] = data['parts-summary'];
        this.students.paymentsSummary['payments-summary'] = data['payments-summary'];
      }
    } else {
      if (this.loggedUser.isAdmin && this.overview.isOwnOverview) {
        this.franchises.isSummaryVisible = false;
        this.franchises.topPaymentData = data.profit_and_loss_report;
      } else {
        this.franchises.paymentUntilData.planed = data[0].planed;
        this.franchises.paymentUntilData.realised = data[0].realised;
        this.franchises.paymentUntilData.summary.planed = data[0].planed_summary;
        this.franchises.paymentUntilData.summary.realised = data[0].realised_summary;
        this.franchises.paymentUntilData.summary.total = data[0].total;
        this.franchises.paymentUntilData.summary.currency = data[0].currency;

        this.franchises.paymentRevenueData.planed = data[1].planed;
        this.franchises.paymentRevenueData.realised = data[1].realised;
        this.franchises.paymentRevenueData.summary.planed = data[1].planed_summary;
        this.franchises.paymentRevenueData.summary.realised = data[1].realised_summary;
        this.franchises.paymentRevenueData.summary.total = data[1].total;
        this.franchises.paymentRevenueData.summary.currency = data[1].currency;
      }
    }
  }

  generateCertificate() {
    this.dialog.open(GenerateCertificateDialogComponent, {
      panelClass: 'custom-dialog',
      data: {studentId: this.students.studentData.id, group: this.students.studentData.group}
    } as MatDialogConfig).afterClosed().subscribe((response: any) => {
      if (response && response.success) {
      }
    });
  }

  generateCertificates() {
    setTimeout( () => {
      if (!this.showSelects) {
        this.mediator.showStudentSelects$.next(true);
      } else {
        if (this.franchises.certificateStudentIds && this.franchises.certificateStudentIds.length > 0) {
          this.dialog.open(GenerateCertificateDialogComponent, {
            panelClass: 'custom-dialog',
            data: {studentIds: this.franchises.certificateStudentIds, group: this.franchises.groupData}
          } as MatDialogConfig).afterClosed().subscribe((response: any) => {
            if (response && response.success) {
              this.mediator.showStudentSelects$.next(false);
              this.franchises.certificateStudentIds = [];
            }
          });
        } else {
          this.toast.showError();
        }
      }
    });
  }

  cancelCertificates() {
    this.showSelects = false;
    this.mediator.showStudentSelects$.next(false);
    this.franchises.certificateStudentIds = [];
  }

  editInfo(suffix: string = '') {
    this.router.navigateByUrl(this.router.url + '/edit' + suffix);
  }

  navigate(url) {
    this.router.navigate([url], {relativeTo: this.route.parent});
  }

  updateFranchiseInfo() {
    this.requests.svc_get(`/api/franchises/${this.franchises.currentFranchise.id}`, {}, this.loggedUser.getToken())
      .pipe(takeUntil(this.destroy$)).subscribe(resp => {
      this.franchises.franchiseData = resp;
      this.franchises.currentFranchise = this.franchises.franchiseData;
      this.franchises.franchiseGroups  = this.franchises.franchiseData.groups;
      this.franchises.franchiseDataSets$.next(true);
      this.table.isLoadingVisible = false;
    }, err => {
        this.toast.showError();
        this.table.isLoadingVisible = false;
    });
  }

  addProgramme() {
    this.router.navigateByUrl(this.router.url + '/programme/add');
  }

  addRoyalty() {
    this.dialog.open(AddEditFranchiseRoyaltyComponent, {
      panelClass: 'custom-dialog',
      data: null
    } as MatDialogConfig).afterClosed().subscribe((response: any) => {
      if (response.result) {
        this.franchises.royaltyAdded$.next(true);
      }
    });
  }
}
