import {Component, OnInit} from '@angular/core';
import {BreakpointObserver, Breakpoints} from '@angular/cdk/layout';
import {Observable} from 'rxjs';
import {map, shareReplay} from 'rxjs/operators';
import {MediatorService} from '../services/mediator.service';
import {ToastService} from '../services/app-toast.service';
import {TranslationService} from '../utils/translate.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset).pipe(map(result => result.matches), shareReplay());

  constructor(
    private toast: ToastService,
    public mediator: MediatorService,
    public translate: TranslationService,
    private breakpointObserver: BreakpointObserver
  ) {
  }

  ngOnInit() {
    this.mediator.isProgressBarVisible = false;
  }

  setOverflow() {
    if (!this.mediator.isSidenavVisible) {
      this.mediator.sideMenuOverflow = true;
    }
  }

  unsetOverflow() {
    if (!this.mediator.isSidenavVisible) {
      this.mediator.sideMenuOverflow = false;
    }
  }
}
