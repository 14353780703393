import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-delete-programme-dialog',
  templateUrl: './delete-programme-dialog.component.html',
  styleUrls: ['./delete-programme-dialog.component.scss']
})
export class DeleteProgrammeDialogComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<DeleteProgrammeDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    ) { }

  ngOnInit() {
  }

  onDelete() {

  }

}
