import {Component, OnDestroy, OnInit} from '@angular/core';
import {environment} from '../../../environments/environment';
import * as moment from "moment";
import {forkJoin, Subject} from "rxjs";
import {ActivatedRoute, Router} from "@angular/router";
import {MatDialog, MatDialogConfig} from "@angular/material/dialog";
import {ToastService} from "../../services/app-toast.service";
import {TableService} from "../../services/table.service";
import {StudentsService} from "../students/students.service";
import {OverviewService} from "../overview/overview.service";
import {MediatorService} from "../../services/mediator.service";
import {RequestsService} from "../../services/requests.service";
import {FranchisesService} from "../franchises/franchises.service";
import {LoggedUserService} from "../../services/logged-user.service";
import {concatMap, takeUntil} from "rxjs/operators";
import {AddDebtDialogComponent} from "../../shared/components/dialogs/add-debt-dialog/add-debt-dialog.component";
import {GenerateCertificateDialogComponent} from "../../shared/components/dialogs/generate-certificate-dialog/generate-certificate-dialog.component";
import {animate, keyframes, transition, trigger} from "@angular/animations";
import * as kf from "../../animations/keyframes";

@Component({
  selector: 'app-programmes',
  templateUrl: './programmes.component.html',
  styleUrls: ['./programmes.component.scss'],
  animations: [
    trigger('fadeIn', [
      transition(':enter', animate(500, keyframes(kf.fadeIn)))
    ])
  ]
})
export class ProgrammesComponent implements OnInit, OnDestroy {
  date = moment().format('YYYY-MM-DD');
  environment = environment;

  selectedTab = 0;

  listLabel: Array<string> = ['programmes', 'royalties'];

  destroy$: Subject<boolean> = new Subject();

  constructor(
    private router: Router,
    public dialog: MatDialog,
    private toast: ToastService,
    private route: ActivatedRoute,
    private table: TableService,
    public students: StudentsService,
    public overview: OverviewService,
    public mediator: MediatorService,
    private requests: RequestsService,
    public franchises: FranchisesService,
    public loggedUser: LoggedUserService,
  ) {
  }

  ngOnInit() {

  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  navigate(url) {
    this.router.navigate([url], {relativeTo: this.route.parent});
  }
}
