import { Injectable } from '@angular/core';
import {MediatorService} from './mediator.service';

@Injectable({providedIn: 'root'})
export class SearchService {
  list = [];
  placeholder = '';
  isActive = false;
  isEnabled = true;
  displayValue = [];
  shouldEmitText = true;
  hasAutoComplete = false;

  constructor(private mediator: MediatorService) { }

  emitValue(value) {
    this.mediator.inputValue = value;
    this.mediator.searchValueSubject.next(value);
  }

  emitSelectedItem(e) {
    if (this.shouldEmitText) {
      this.mediator.searchValueSubject.next(this.setDisplayValue(e.option.value));
    } else {
      this.mediator.searchValueSubject.next(e.option.value);
    }
    this.mediator.closeMobileOverlay = false;
    this.mediator.closeDropdownOverlay = false;
  }

  setDisplayValue(item) {
    if (this.displayValue !== undefined) {
      const displayValue = [];
      this.displayValue.forEach(value => {
        if (item.hasOwnProperty(value) && item[value] != null) {
          displayValue.push(item[value]);
        }
      });
      return displayValue.join(' ');
    }
  }

  reset() {
    this.mediator.inputValue = '';
    this.mediator.closeDropdownOverlay = false;
    this.mediator.searchValueSubject.next('');
  }

  resetContext() {
    this.list = [];
    this.placeholder = '';
    this.displayValue = [];
    this.shouldEmitText = true;
    this.hasAutoComplete = false;
  }
}
