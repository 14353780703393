import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, NavigationEnd, PRIMARY_OUTLET, Router} from '@angular/router';
import {distinctUntilChanged, filter} from 'rxjs/operators';
import {MediatorService} from '../../../services/mediator.service';
import {BreadCrumb} from '../../models/breadcrumb.model';

@Component({
  selector: 'app-breadcrumbs',
  templateUrl: './breadcrumbs.component.html',
  styleUrls: ['./breadcrumbs.component.scss']
})
export class BreadcrumbsComponent implements OnInit {
  breadcrumbs = [];

  constructor(
    public router: Router,
    public mediator: MediatorService,
    public activatedRoute: ActivatedRoute
  ) {
    // listens for route change
    this.router.events.pipe(
      filter(ev => {
        return ev instanceof NavigationEnd;
      }),
      distinctUntilChanged()
    ).subscribe(() => {
        this.breadcrumbs = this.setBreadCrumbs(this.activatedRoute.root).filter(bc => bc.label.length > 0);

        // sends breadcrumbs for components that need it
        this.mediator.breadCrumbs = this.breadcrumbs.map(breadcrumb => {
          if (breadcrumb.linkCode !== null) {
            return breadcrumb;
          }
        });
        this.mediator.breadcrumbsSubject.next(this.mediator.breadCrumbs);

        // if saved params are present, checks if saved route params should be put on right breadcrumb
        if (Object.keys(this.mediator.savedBreadcrumb).length > 0) {
          this.breadcrumbs.forEach((bc) => {
            if (bc.label !== 'Home' && bc.linkCode === this.mediator.savedBreadcrumb[`linkCode`] &&
              bc.label === this.mediator.savedBreadcrumb[`label`]) {
              bc.params = this.mediator.savedRouteParams;
            }
          });
        }
      }
    );
  }

  ngOnInit() {
  }

  setBreadCrumbs(route: ActivatedRoute, url: string = '', breadcrumbs: Array<BreadCrumb> = []): Array<BreadCrumb> {
    const children: ActivatedRoute[] = route.children;

    if (children.length === 0) {
      return breadcrumbs;
    }

    for (const child of children) {
      if (child.outlet !== PRIMARY_OUTLET) {
        continue;
      }

      if (!child.snapshot.data.hasOwnProperty('breadcrumb')) {
        return this.setBreadCrumbs(child, url, breadcrumbs);
      }

      const routeURL: string = child.snapshot.url.map(segment => segment.path).join('/');

      url += `/${routeURL}`;

      const homeUrl = '';
      const isDisabled = child.snapshot.data[`path`] === '' && child.snapshot.data[`breadcrumb`] !== 'Home';

      const breadcrumb = {
        label: child.snapshot.data[`breadcrumb`],
        url: child.snapshot.data[`breadcrumb`] === 'Home' ? homeUrl : url,
        params: child.snapshot.data[`breadcrumb`] === 'Home' ? {} : child.snapshot.queryParams,
        linkCode: child.snapshot.data[`linkCode`],
        disabled: isDisabled
      };

      if (Object.keys(child.snapshot.queryParams).length > 0 && breadcrumb.label !== '') {
        this.mediator.savedRouteParams = child.snapshot.queryParams;
        this.mediator.savedBreadcrumb = breadcrumb;
      }

      breadcrumbs.push(breadcrumb);
      return this.setBreadCrumbs(child, url, breadcrumbs);
    }
  }

  navigateToUrl(breadcrumb) {
    if (breadcrumb.disabled) {
      return;
    }

    if (breadcrumb.label === 'Home') {
      this.mediator.savedRouteParams = {};
      this.mediator.savedBreadcrumb = {};
      this.router.navigate([breadcrumb.url], {queryParams: {}});
    } else {
      if (Object.keys(breadcrumb.params).length > 0 &&
        this.mediator.savedBreadcrumb[`linkCode`] === breadcrumb.linkCode &&
        this.mediator.savedBreadcrumb[`label`] === breadcrumb.label) {
        this.router.navigate([breadcrumb.url], {queryParams: breadcrumb.params});
      } else {
        this.router.navigate([breadcrumb.url]);
      }
    }
  }
}
