import {Pipe, PipeTransform} from '@angular/core';

interface FilterData {
  filterKey: string;
  dataSource: { color: string, [prop: string]: any }[];
  defaultColor?: string;
}

@Pipe({name: 'dynamicColor'})
export class DynamicColorPipe implements PipeTransform {
  // TODO: set default color property based on environment variable that determines dark or light theme presence
  transform(currItem: string,
            filterData: FilterData): string {
    const found: {color: string, [prop: string]: any} = filterData.dataSource.find(item => {
      return item.hasOwnProperty(filterData.filterKey) ? item[filterData.filterKey] === currItem : false;
    });
    return found !== undefined ? found.color : (filterData.hasOwnProperty('defaultColor') ? filterData.defaultColor : '#000');
  }
}
