import {Component, OnDestroy, OnInit} from '@angular/core';
import {FormBuilder, Validators} from '@angular/forms';
import {Subject} from 'rxjs';
import {ActivatedRoute, Router} from '@angular/router';
import {ToastService} from '../../services/app-toast.service';
import {MediatorService} from '../../services/mediator.service';
import {RequestsService} from '../../services/requests.service';
import {LoggedUserService} from '../../services/logged-user.service';
import {TranslationService} from '../../utils/translate.service';
import {takeUntil} from 'rxjs/operators';
import {animate, keyframes, style, transition, trigger} from '@angular/animations';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss'],
  animations: [
    trigger('fadeIn', [
      transition(':enter',
        animate(200,
          keyframes([
            style({opacity: 0, offset: 0}),
            style({opacity: 1, offset: 1})
          ])
        ))
    ])
  ]
})
export class ResetPasswordComponent implements OnInit, OnDestroy {
  hash;
  isVisible = false;
  isFormDisabled = false;
  isPasswordReset = false;

  resetPasswordForm = this.fb.group({
    newPassword: this.fb.control(null, Validators.required),
    repeatPassword: this.fb.control(null, Validators.required)
  });

  destroy$: Subject<boolean> = new Subject();

  constructor(
    private router: Router,
    private fb: FormBuilder,
    private toast: ToastService,
    private route: ActivatedRoute,
    public mediator: MediatorService,
    private requests: RequestsService,
    public loggedUser: LoggedUserService,
    private translate: TranslationService
  ) { }

  get newPassword() {
    return this.resetPasswordForm.get('newPassword');
  }

  get repeatPassword() {
    return this.resetPasswordForm.get('repeatPassword');
  }

  ngOnInit() {
    this.route.params.pipe(takeUntil(this.destroy$)).subscribe(params => {
      if (params.hasOwnProperty('hash')) {
        this.hash = params.hash;
      } else {
        this.router.navigate([]);
      }
    });
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  submit() {
    if (this.resetPasswordForm.valid && this.newPassword.value === this.newPassword.value) {
      this.isFormDisabled = true;
      this.requests.svc_post(`/user/password/change/${this.hash}`, {
        new_password: this.repeatPassword.value
      }).pipe(takeUntil(this.destroy$)).subscribe(resp => {
        this.isFormDisabled = false;
        this.isPasswordReset = true;
      }, err => {
        this.isFormDisabled = false;
        if (err.hasOwnProperty('error') && err.error.id_error === 20) {
          this.toast.showInfoToast(this.translate.instant('enterValidData'));
        } else {
          this.toast.showInfoToast(this.translate.instant('errorOccurred'));
        }
      });
    } else {
      this.toast.showInfoToast(this.translate.instant('passwordsNotMatch'));
    }
  }

  back() {
    this.router.navigate(['login', 'first-step']);
  }
}
