import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import {ResetPasswordComponent} from './reset-password/reset-password.component';
import {ForgotPasswordComponent} from './forgot-password/forgot-password.component';
import {LoginComponent} from './login/login.component';
import {LoginFirstStepComponent} from './login/login-first-step/login-first-step.component';
import {LoginSecondStepComponent} from './login/login-second-step/login-second-step.component';
import {ChangePasswordComponent} from './change-password/change-password.component';
import {ChangePasswordDialogComponent} from './change-password-dialog/change-password-dialog.component';

import {Step2GuardService} from '../guards/step-2-guard.service';
import {ResetPasswordGuardService} from '../guards/reset-password-guard.service';
import { GuestLoginComponent } from './login/guest-login/guest-login.component';
import {GuestLoginNewComponent} from './login/guest-login-new/guest-login-new.component';
import {NotFoundComponent} from '../not-found/not-found.component';

const routes: Routes = [
  {
    path: 'login/guest', component: GuestLoginNewComponent
  },
  {
    path: 'login', component: LoginComponent,
    children: [
      {path: '', redirectTo: 'first-step', pathMatch: 'prefix'},
      {path: 'first-step', component: LoginFirstStepComponent},
      {path: 'second-step', component: LoginSecondStepComponent, canActivate: [Step2GuardService]},
      {path: 'forgot-password', component: ForgotPasswordComponent},
      {path: 'reset-password/:hash', component: ResetPasswordComponent, canActivate: [ResetPasswordGuardService]},
      {path: 'not-found', component: NotFoundComponent, data: {message: 'Page not found!'}},
      {path: '**', redirectTo: '/not-found'}
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AuthRoutingModule { }
