import {Component, OnInit, Input, Output, EventEmitter} from '@angular/core';
import {FormArray} from '@angular/forms';

@Component({
  selector: 'app-form-learn',
  templateUrl: './form-learn.component.html',
  styleUrls: ['./form-learn.component.scss']
})
export class FormLearnComponent {
  @Output() manageFormItem: EventEmitter<any> = new EventEmitter();
  @Input() formArray: FormArray;
  @Input() config: any;
  @Input() id;
  @Input() multi = true;
  @Input() single = false;

  manageEmit(i) {
    this.manageFormItem.emit(i);
  }
}
