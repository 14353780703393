import {PipeTransform, Pipe} from '@angular/core';

@Pipe({name: 'padTreeNode'})
export class PadTreeNodePipe implements PipeTransform {
  transform(node: any, isSidenavOpen: boolean): string {
    if (isSidenavOpen) {
      return node.level === 0 ? '1.5rem' : (node.level * 1.5) + 1.5 + 'rem';
    } else {
      return '1.5rem';
    }
  }
}
