// FOR THIS TO WORK ADD "allowJs": true TO tsconfig.json IN THE "compilerOptions" SECTION
import {Subject} from 'rxjs';
import {Injectable} from '@angular/core';
import {tr_map as _en } from './translations/en.ftr';
import {tr_map as _rs } from './translations/rs.ftr';
import {tr_map as _me } from './translations/me.ftr';
import {tr_map as _hr } from './translations/hr.ftr';
import {tr_map as _si } from './translations/si.ftr';
import {tr_map as _ba } from './translations/ba.ftr';
import {TranslateService} from '@ngx-translate/core';

@Injectable()
export class TranslationService {
  langs = ['RS', 'EN', 'ME', 'BA', 'HR', 'SI'];
  lang = 'EN';
  langChanged = new Subject<string>();

  constructor(private tr: TranslateService) {
    tr.addLangs(this.langs);
    tr.setTranslation('RS', _rs);
    tr.setTranslation('EN', _en);
    tr.setTranslation('ME', _me);
    tr.setTranslation('BA', _ba);
    tr.setTranslation('SI', _si);
    tr.setTranslation('HR', _hr);
    tr.setDefaultLang(this.lang);

    const defaultLang = localStorage.getItem('lang');
    this.lang = defaultLang || 'RS';
    this.tr.use(this.lang);
  }

  getLang() {
    return this.lang;
  }

  getBlogLang() {
    let lang = this.lang;
    switch (lang) {
      case 'RS': lang = 'sr'; break;
      case 'BA': lang = 'bh'; break;
      case 'SI': lang = 'sl'; break;
    }
    return lang.toLowerCase();
  }

  getAvailableLangs() {
    return this.tr.getLangs();
  }

  setLang(lang) {
    if (this.langs.indexOf(lang) === -1) {
        alert(`THERE IS NO LANGUAGE: ${lang}`);
        return;
    }

    this.lang = lang;
    this.tr.use(this.lang);
    localStorage.setItem('lang', this.lang);

    this.langChanged.next(this.lang);
  }

  instant(str) {
    return this.tr.instant(str);
  }
}
