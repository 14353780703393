import {Injectable} from '@angular/core';
import {ActivatedRoute, ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';
import {LoggedUserService} from '../services/logged-user.service';
import {AuthorizationService} from '../services/authorization.service';
import {LookupService} from '../utils/lookup.service';
import {FranchiseType} from '../pages/franchises/franchises.service';
import { GuestService } from '../services/guest.service';
import {MediatorService} from "../services/mediator.service";

@Injectable()
export class RouterGuardService implements CanActivate, CanActivateChild {

  constructor(
    private router: Router,
    private mediator: MediatorService,
    private lookup: LookupService,
    private loggedUser: LoggedUserService,
    private authService: AuthorizationService,
    private guest: GuestService,
  ) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Promise<boolean> | Observable<boolean> {
    return new Promise((resolve) => {
      this.guest.checkGuest().subscribe((resp) => {
        this.guest.loginGuest();
        resolve(true);
        this.router.navigate(['learnability']);
      }, err => {
        this.loggedUser.checkUser().subscribe((r: any) => {
          this.lookup.userRoles = r.roles;
          this.loggedUser.login(r);
          resolve(true);
          }, error => {
          console.log(error);
          this.loggedUser.logout(error);
          resolve(false);

            // Learnability login redirect
          if (this.mediator.isLearnability()) {
            this.router.navigate(['learnability'], { skipLocationChange: true });
          } else {
            this.router.navigate(['login/first-step']);
          }
        });
      });
    });
  }

  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.canActivate(route, state);
  }
}
