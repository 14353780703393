import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-qa-container',
  template: `<app-qa></app-qa>`,
  styles: []
})
export class QaContainerComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
