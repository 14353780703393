import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class PublicVideosService {

  // videos = [
  //   {
  //     videoName: 'video1',
  //     videoPath: 'neka putanja',
  //     description: 'opis 1',
  //   },
  //   {
  //     videoName: 'video2',
  //     videoPath: 'neka putanja',
  //     description: 'opis 2',
  //   }
  // ];
  //
  // addVideo(name: string, path: string, description: string) {
  //   this.videos.push({videoName: name, videoPath: path, description});
  // }
  //
  // getThem() {
  //   return this.videos.slice();
  // }
}
