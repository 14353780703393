import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {FormBuilder, Validators} from "@angular/forms";
import {ToastService} from "../../../../services/app-toast.service";

@Component({
  selector: 'app-change-programme-name-dialog',
  templateUrl: './change-programme-name-dialog.component.html',
  styleUrls: ['./change-programme-name-dialog.component.scss']
})
export class ChangeProgrammeNameDialogComponent implements OnInit {

  changeProgrammeTitleForm = this.fb.group({
    name: ['', Validators.required]
  })

  constructor(
    public dialogRef: MatDialogRef<ChangeProgrammeNameDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private toast: ToastService,
    private fb: FormBuilder) { }

  ngOnInit() {
    this.changeProgrammeTitleForm.patchValue({
      name: this.data.name,
    });
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onChangeName() {
    if (this.changeProgrammeTitleForm.valid) {
      this.dialogRef.close(this.changeProgrammeTitleForm.value);
    } else {
      this.toast.showError();
    }
  }
}
