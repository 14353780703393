import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-courses-overview-two-banners',
  templateUrl: './courses-overview-two-banners.component.html',
  styleUrls: ['./courses-overview-two-banners.component.scss']
})
export class CoursesOverviewTwoBannersComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

  navigateToReadMore() {}

  navigateToApply() {

  }
}
