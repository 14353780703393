import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {SharedModule} from '../../shared/shared.module';
import {StudentsModule} from '../students/students.module';

import {FranchisesService} from './franchises.service';

import {FranchisesComponent} from './franchises.component';
import { FranchiseComponent } from './franchise/franchise.component';
import { FranchiseListComponent } from './franchise-list/franchise-list.component';
import { FranchiseFormComponent } from './franchise/franchise-form/franchise-form.component';
import { GroupListComponent } from './group-list/group-list.component';

@NgModule({
  declarations: [
    FranchiseComponent,
    FranchisesComponent,
    FranchiseFormComponent,
    FranchiseListComponent,
    GroupListComponent,
  ],
  imports: [
    CommonModule,
    StudentsModule,
    SharedModule,
  ],
  providers: [
    FranchisesService
  ],
  exports: [
    FranchisesComponent,
    FranchiseListComponent,
  ]
})
export class FranchisesModule { }
