import {Component, OnDestroy, OnInit} from '@angular/core';
import { ProgrammeService } from 'src/app/services/programme.service';
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {fadeIn} from '../../../animations/animations';
import {ActivatedRoute} from '@angular/router';
import {GuestService} from '../../../services/guest.service';

@Component({
  selector: 'app-course-container',
  templateUrl: './course-container.component.html',
  styleUrls: ['./course-container.component.scss'],
  animations: [fadeIn],
})
export class CourseContainerComponent implements OnInit, OnDestroy {

  destroy$: Subject<boolean> = new Subject<boolean>();

  programme: any = null;
  lesson: number;

  svgString = `
  <svg id="diagtext" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="100%" height="100%">
    <style type="text/css">text { fill: darkgray; font-family: Avenir, Arial, Helvetica, sans-serif; }</style>
    <defs>
        <pattern id="twitterhandle" patternUnits="userSpaceOnUse" width="400" height="200">
            <text y="30" font-size="40" id="name">
                TEST
            </text>
        </pattern>
<!--        <pattern xlink:href="#twitterhandle">-->
<!--            <text y="120" x="200" font-size="29" id="occupation">-->
<!--                test-->
<!--            </text>-->
<!--        </pattern>-->
        <pattern id="combo" xlink:href="#twitterhandle" patternTransform="rotate(-45)"><use xlink:href="#name" />
            <use xlink:href="#occupation" />
        </pattern>
    </defs>
    <rect width="100%" height="100%" fill="url(#combo)" />
  </svg>
`;

  constructor(public courseService: ProgrammeService,
              public guestService: GuestService,
              private route: ActivatedRoute) {
    this.route.paramMap.subscribe({
      next: value => {
        this.courseService.getProgramme(value.get('id'));
        this.lesson = value.get('lesson') ? +value.get('lesson') : 1;
        this.courseService.activeLesson$.next(this.lesson);
      }
    });
  }

  ngOnInit() {
    this.courseService.activeLesson$.subscribe({
      next: value => {
        this.lesson = value + 1;
      }
    });
    this.courseService.programme$.pipe(takeUntil(this.destroy$)).subscribe({
      next: value => {
        if (value !== null) {
          this.programme = value;
        }
      }
    });
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  get lessons() {
    return this.programme.items.programme;
  }

  lessonIndex(lesson) {
    this.lessons.findIndex(lesson);
  }

  toggleWatched() {

  }

  getWaterMark() {
    return 'url(\'data:image/svg+xml;base64,' + btoa(this.svgString) + '\')';
  }
}
