import { Injectable } from '@angular/core';
import {RequestsService} from '../../services/requests.service';
import {LoggedUserService} from '../../services/logged-user.service';
import {FranchisesService} from '../franchises/franchises.service';

@Injectable({providedIn: 'root'})
export class ProfitAndLossService {
  url = '/api/franchise/';

  constructor(
    private requests: RequestsService,
    private franchises: FranchisesService,
    private loggedUser: LoggedUserService) { }

  getPaymentData() {
    return this.requests.svc_get(`${this.url}/${this.franchises.franchiseId}`, {}, this.loggedUser.getToken());
  }
}
