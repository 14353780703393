import {Injectable} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {MediatorService} from './mediator.service';
import {TableDataSource} from './table-data-source';
import {RequestsService} from './requests.service';
import {LookupService} from '../utils/lookup.service';
import {LoggedUserService} from './logged-user.service';

@Injectable({providedIn: 'root'})
export class TableService {
  data = [];
  dataSource: TableDataSource;

  paramData = {};
  customParams = {};

  total = 0;
  pageIndex = 0;
  pageSize = 10;

  search = '';

  offset = 0;
  limit = 50;
  orderBy = '';

  isLoadMoreVisible = true;
  isContentVisible = false;
  isLoadingVisible = false;
  isErrorMessageVisible = false;

  currentRoute: ActivatedRoute;

  constructor(public router: Router,
              public api: RequestsService,
              public lookup: LookupService,
              public mediator: MediatorService,
              public loggedUser: LoggedUserService) {
    this.dataSource = new TableDataSource(this.api, this.loggedUser);
  }

  getData(url, {responseDataTitle = 'data', hasDefaultTableParams = true, defaultTableParams = {}, customParams = {}} = {}) {
    this.isContentVisible = false;
    this.customParams = customParams;

    if (hasDefaultTableParams) {
      this.paramData = this.prepareParams(defaultTableParams, customParams);
      this.setParams(this.paramData, this.customParams);
      this.pageIndex = this.paramData['offset'] / this.paramData['limit'];
    } else {
      this.paramData = this.customParams;
    }

    return this.dataSource.loadData(url, this.paramData, responseDataTitle);
  }

  setTotal(total) {
    this.total = total;
    this.pageSize = this.limit;
    this.isLoadMoreVisible = true;

    if (this.total < this.limit) {
      this.pageSize = this.total;
      this.isLoadMoreVisible = false;
    }
    if (this.total === this.limit) {
      this.isLoadMoreVisible = false;
    }
    if (total === 0) {
      this.pageSize = 0;
    }
  }

  setParams(params, customParams) {
    this.limit = params.limit;
    this.offset = params.offset;
    this.search = params.search;
    this.orderBy = params.order_by;
    this.customParams = customParams;
  }

  prepareParams(params, customParams = {}) {
    const data = {
      limit: params.limit != null ? +params.limit : this.limit,
      offset: params.offset != null ? +params.offset : this.offset,
      search: params.search != null ? params.search : this.search,
      order_by: params.order_by != null ? params.order_by : this.orderBy,
    };
    for (const param in customParams) {
      if (customParams.hasOwnProperty(param)) {
        data[param] = customParams[param];
      }
    }
    return data;
  }

  updateCustomParams(params) {
    this.customParams = params;
  }

  setQueryParams(params?) {
    let parameters = {};

    if (params !== null) {
      parameters = this.prepareParams(this.paramData, this.customParams);
    }

    const url = [];
    this.currentRoute.snapshot.pathFromRoot.forEach(route => {
      if (route.url.length > 0) {
        route.url.forEach(segment => url.push(segment.path));
      }
    });

    this.router.navigate(url, {queryParams: parameters});
  }

  setDefaultQueryParams() {
    this.paramData = this.prepareParams({
      limit: this.limit,
      offset: this.offset,
      search: this.search,
      order_by: this.orderBy,
    }, this.customParams);
    this.setQueryParams(this.paramData);
  }

  resetQueryParams() {
    this.setQueryParams(null);
  }

  setOrderBy(columnName, shouldRefreshData = true) {
    this.orderBy = columnName;
    this.offset = 0;
    if (shouldRefreshData) {
      this.setDefaultQueryParams();
    }
  }

  filter(value) {
    if (value !== undefined && value !== null && value.length >= 2) {
      this.pageIndex = 0;
      this.search = value;
      this.offset = this.pageIndex * this.pageSize;
      this.setDefaultQueryParams();
    } else {
      this.resetSearch();
    }
  }

  resetSearch() {
    this.mediator.savedRouteParams = {};
    this.mediator.savedBreadcrumb = {};
    this.search = '';
    this.paramData = this.prepareParams({
      limit: this.limit,
      offset: this.offset,
      search: this.search,
      order_by: this.orderBy,
    }, this.customParams);
    this.resetQueryParams();
    this.mediator.inputValue = '';
  }

  resetTableData() {
    this.search = '';
    this.paramData = this.prepareParams({
      limit: this.limit,
      offset: this.offset,
      search: this.search,
      order_by: this.orderBy,
    }, this.customParams);
    this.data = [];
    this.isContentVisible = false;
    this.isErrorMessageVisible = false;
  }

  changePage(e) {
    this.offset = e.pageIndex * this.pageSize;
    this.setDefaultQueryParams();
  }

  showMore() {
    if (this.limit + 10 > this.total) {
      this.limit = this.total;
      this.setDefaultQueryParams();
    } else {
      this.limit += 10;
      this.setDefaultQueryParams();
    }
  }
}
