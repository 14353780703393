import {Component, OnInit, ViewEncapsulation} from '@angular/core';

export interface Question {
  question: string;
  description: string;
  date: Date;
  answers?: string[];
}

@Component({
  selector: 'app-qa',
  templateUrl: './qa.component.html',
  styleUrls: ['./qa.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class QaComponent implements OnInit {
  questions: Question[] = [
    {
      question: 'Didnt understand snippet',
      description: 'What are we oding by new ingredient(). are we creating an array? Why simply not (me',
      date: new Date('February 8, 2020 11:13:00'),
      answers: ['...', '...']
    },
    {
      question: 'Is the event ingredientsCharged triggered by susscbribe???',
      description: 'So i have almost finished the course, and the main reason why I learnt angular was',
      date: new Date('February 13, 2020 10:13:00'),
    },
    {
      question: 'Bug with navigating to another route.',
      description: 'So I\'m using this same method to redirect the user in my application, the only differe',
      date: new Date('February 13, 2020 9:13:00'),
    },
  ];

  constructor() { }

  ngOnInit() {
  }

}
