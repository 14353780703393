import {NgModule} from '@angular/core';
import {HomeComponent} from './home/home.component';
import {ExtraOptions, RouterModule, Routes} from '@angular/router';
import {RouterGuardService} from './guards/router-guard.service';
import {NotFoundComponent} from './not-found/not-found.component';
import {PermissionGuardService} from './guards/permission-guard.service';
import {DashboardComponent} from './pages/dashboard/dashboard.component';
import {FranchiseListComponent} from './pages/franchises/franchise-list/franchise-list.component';
import {StudentFormComponent} from './pages/students/student/student-form/student-form.component';
import {AdministrationGuardService} from './guards/administration-guard.service';
import {OverviewComponent} from './pages/overview/overview.component';
import {StudentListComponent} from './pages/students/student-list/student-list.component';
import {FranchiseType, OverviewType} from './pages/franchises/franchises.service';
import {FranchiseFormComponent} from './pages/franchises/franchise/franchise-form/franchise-form.component';
import {NewFranchiseGuardService} from './guards/new-franchise.service';
import {PlaceholderComponent} from './shared/components/placeholder/placeholder.component';
import {AdminFranchiseGuardService} from './guards/admin-franchise-guard.service';
import {AdminMasterGuardService} from './guards/admin-master-guard.service';
import {ProfileContainerComponent} from './pages/profile/profile-container.component';
import {EduComponent} from './pages/edu/edu.component';
import {NewLessonsContainerComponent} from './pages/edu/new-lessons/new-lessons-container.component';
import {InstructorFormComponent} from './pages/instructor-form/instructor-form.component';
import {CoursesContainerComponent} from './pages/courses/courses-container/courses-container.component';
import {NewCourseComponent} from './pages/courses/new-course/new-course.component';
import {GroupFormComponent} from './pages/courses/group-form/group-form.component';
import {FranchiseResolver} from './pages/franchises/franchise.resolver';
import {StudentResolver} from './pages/students/student.resolver';
import {LanguageMapComponent} from './pages/language-map/language-map.component';
import {GroupListComponent} from './pages/franchises/group-list/group-list.component';
import {GroupResolver} from './pages/franchises/group.resolver';
import {ProgrammeFormComponent} from "./pages/programmes/programme-form/programme-form.component";
import {ProgrammeResolver} from "./pages/programmes/programme.resolver";

const routerOptions: ExtraOptions = {
  anchorScrolling: 'enabled',
  // enableTracing: true,
};

const routes: Routes = [
  {
    path: '',
    component: HomeComponent,
    data: {breadcrumb: 'Home', path: '', linkCode: '0.1'},
    canActivate: [RouterGuardService],
    children: [
      {path: '', component: PlaceholderComponent, canActivate: [AdministrationGuardService]},
      {
        path: 'profile',
        component: ProfileContainerComponent,
        canActivate: [PermissionGuardService],
        data: {breadcrumb: 'profile', path: ''},
      },
      {
        path: 'dashboard',
        component: DashboardComponent,
        canActivate: [PermissionGuardService],
        data: {breadcrumb: 'dashboard', linkCode: '0.1'}
      },
      {
        path: 'courses',
        component: PlaceholderComponent,
        canActivate: [AdminMasterGuardService],
        data: {breadcrumb: 'courses', linkCode: '0.4'},
        children: [
          {
            path: '',
            component: CoursesContainerComponent,
            data: {breadcrumb: '', linkCode: '0.4'},
          },
          {
            path: 'new',
            component: NewCourseComponent,
            data: {breadcrumb: 'addingCourse', linkCode: '0.4'},
          },
        ]
      },
      {
        path: 'administration',
        component: PlaceholderComponent,
        canActivate: [PermissionGuardService],
        data: {breadcrumb: 'administration', path: '', linkCode: '0.2'},
        children: [
          {
            path: '',
            data: {breadcrumb: '', linkCode: '0.2'},
            component: PlaceholderComponent,
            children: [
              {
                path: FranchiseType.BASIC,
                component: PlaceholderComponent,
                resolve: {franchise: FranchiseResolver},
                canActivate: [AdminFranchiseGuardService],
                data: {breadcrumb: 'franchises', linkCode: '0.2'},
                children: [
                  {
                    path: '',
                    component: OverviewComponent,
                    data: {breadcrumb: '', linkCode: '0.2', overviewType: OverviewType.OWN},
                    children: [{path: '', component: FranchiseListComponent, data: {breadcrumb: '', linkCode: '0.2'}}]
                  },
                  {
                    path: 'new-franchise',
                    component: FranchiseFormComponent,
                    canActivate: [NewFranchiseGuardService],
                    data: {breadcrumb: 'addingFranchise', linkCode: '0.2'},
                  },
                  {
                    path: ':franchise-id',
                    component: PlaceholderComponent,
                    resolve: {franchise: FranchiseResolver},
                    data: {breadcrumb: 'franchiseOverview', linkCode: '0.2'},
                    children: [
                      {
                        path: '', component: PlaceholderComponent, data: {breadcrumb: '', linkCode: '0.2'},
                        children: [
                          {
                            path: '',
                            component: OverviewComponent,
                            data: {breadcrumb: '', linkCode: '0.2', overviewType: OverviewType.BASIC},
                            children: [{path: '', component: GroupListComponent, data: {breadcrumb: '', linkCode: '0.2'}}]
                          },
                          {
                            path: 'new-instructor',
                            component: InstructorFormComponent,
                            data: {breadcrumb: 'addingInstructor', linkCode: '0.2'},
                          },
                          {
                            path: 'edit',
                            component: FranchiseFormComponent,
                            data: {breadcrumb: 'editingFranchise', linkCode: '0.2', mode: 'edit'},
                          },
                          // Remove when feature gets finished
                          {
                            path: 'new-group',
                            component: GroupFormComponent,
                            data: {breadcrumb: 'addingGroup', linkCode: '0.2'},
                          },
                          {
                            path: 'group/:group-id',
                            component: PlaceholderComponent,
                            resolve: {group: GroupResolver},
                            data: {breadcrumb: 'groupOverview', linkCode: '0.2'},
                            children: [
                              {
                                path: '',
                                component: OverviewComponent,
                                data: {breadcrumb: '', linkCode: '0.2', overviewType: OverviewType.GROUP},
                                children: [{path: '', component: StudentListComponent, data: {breadcrumb: '', linkCode: '0.2'}}]
                              },
                              {
                                path: 'edit',
                                component: GroupFormComponent,
                                data: {breadcrumb: 'editingGroup', linkCode: '0.2', mode: 'edit'},
                              },
                              {
                                path: 'new-student',
                                component: StudentFormComponent,
                                data: {breadcrumb: 'addingStudent', linkCode: '0.2'},
                              },
                              {
                                path: 'new-instructor',
                                component: InstructorFormComponent,
                                data: {breadcrumb: 'addingInstructor', linkCode: '0.2'},
                              },
                              {
                                path: ':student-id',
                                pathMatch: 'full',
                                component: OverviewComponent,
                                resolve: {student: StudentResolver},
                                data: {breadcrumb: 'studentOverview', linkCode: '0.2', overviewType: OverviewType.STUDENT},
                              },
                              {
                                path: ':student-id/edit',
                                pathMatch: 'full',
                                component: StudentFormComponent,
                                resolve: {student: StudentResolver},
                                data: {breadcrumb: 'editingStudent', linkCode: '0.2', mode: 'edit'},
                              }
                            ]
                          },
                        ]
                      },
                    ]
                  },
                ]
              },
              {
                path: FranchiseType.MASTER,
                component: PlaceholderComponent,
                resolve: {franchise: FranchiseResolver},
                canActivate: [AdminMasterGuardService],
                data: {breadcrumb: 'masterFranchises', linkCode: '0.2'},
                children: [
                  {
                    path: '',
                    component: OverviewComponent,
                    data: {breadcrumb: '', linkCode: '0.2', overviewType: OverviewType.OWN},
                    children: [{path: '', component: FranchiseListComponent, data: {breadcrumb: '', linkCode: '0.2'}}]
                  },
                  {
                    path: 'new-franchise',
                    component: FranchiseFormComponent,
                    canActivate: [NewFranchiseGuardService],
                    data: {breadcrumb: 'addingFranchise', linkCode: '0.2'},
                  },
                  {
                    path: 'programme/add',
                    component: ProgrammeFormComponent,
                    data: {breadcrumb: 'addingProgramme', linkCode: '0.2'}
                  },
                  {
                    path: 'programme/edit/:id-programme',
                    component: ProgrammeFormComponent,
                    resolve: {franchise: ProgrammeResolver},
                    data: {breadcrumb: 'editingProgramme', linkCode: '0.2', mode: 'edit'},
                  },
                  {
                    path: ':master-franchise-id',
                    component: PlaceholderComponent,
                    resolve: {franchise: FranchiseResolver},
                    data: {breadcrumb: '', linkCode: '0.2'},
                    children: [
                      {path: '', redirectTo: '/not-found', pathMatch: 'full'},
                      {
                        path: 'franchises',
                        component: PlaceholderComponent,
                        data: {breadcrumb: 'franchises', linkCode: '0.2'},
                        children: [
                          {
                            path: '',
                            component: OverviewComponent,
                            data: {breadcrumb: '', linkCode: '0.2', overviewType: OverviewType.MASTER},
                            children: [{path: '', component: FranchiseListComponent, data: {breadcrumb: '', linkCode: '0.2'}}]
                          },
                          {
                            path: 'new-franchise',
                            component: FranchiseFormComponent,
                            canActivate: [NewFranchiseGuardService],
                            data: {breadcrumb: 'addingFranchise', linkCode: '0.2'},
                          },
                          {
                            path: ':franchise-id',
                            component: PlaceholderComponent,
                            resolve: {franchise: FranchiseResolver},
                            data: {breadcrumb: 'franchiseOverview', linkCode: '0.2'},
                            children: [
                              {
                                path: '', component: PlaceholderComponent, data: {breadcrumb: '', linkCode: '0.2'},
                                children: [
                                  {
                                    path: '',
                                    component: OverviewComponent,
                                    data: {breadcrumb: '', linkCode: '0.2', overviewType: OverviewType.BASIC},
                                    children: [{path: '', component: GroupListComponent, data: {breadcrumb: '', linkCode: '0.2'}}]
                                  },
                                  {
                                    path: 'edit',
                                    component: FranchiseFormComponent,
                                    data: {breadcrumb: 'editingFranchise', linkCode: '0.2', mode: 'edit'},
                                  },
                                  {
                                    path: 'new-group',
                                    component: GroupFormComponent,
                                    data: {breadcrumb: 'addingGroup', linkCode: '0.2'},
                                  },
                                  {
                                    path: 'new-instructor',
                                    component: InstructorFormComponent,
                                    data: {breadcrumb: 'addingInstructor', linkCode: '0.2'},
                                  },
                                  {
                                    path: 'group/:group-id',
                                    resolve: {group: GroupResolver},
                                    component: PlaceholderComponent,
                                    data: {breadcrumb: 'groupOverview', linkCode: '0.2'},
                                    children: [
                                      {
                                        path: '',
                                        component: OverviewComponent,
                                        data: {breadcrumb: '', linkCode: '0.2', overviewType: OverviewType.GROUP},
                                        children: [{path: '', component: StudentListComponent, data: {breadcrumb: '', linkCode: '0.2'}}]
                                      },
                                      {
                                        path: 'edit',
                                        component: GroupFormComponent,
                                        data: {breadcrumb: 'editingGroup', linkCode: '0.2', mode: 'edit'},
                                      },
                                      {
                                        path: 'new-student',
                                        component: StudentFormComponent,
                                        data: {breadcrumb: 'addingStudent', linkCode: '0.2'},
                                      },
                                      {
                                        path: 'new-instructor',
                                        component: InstructorFormComponent,
                                        data: {breadcrumb: 'addingInstructor', linkCode: '0.2'},
                                      },
                                      {
                                        path: ':student-id',
                                        pathMatch: 'full',
                                        component: OverviewComponent,
                                        resolve: {student: StudentResolver},
                                        data: {breadcrumb: 'studentOverview', linkCode: '0.2', overviewType: OverviewType.STUDENT},
                                      },
                                      {
                                        path: ':student-id/edit',
                                        pathMatch: 'full',
                                        component: StudentFormComponent,
                                        resolve: {student: StudentResolver},
                                        data: {breadcrumb: 'editingStudent', linkCode: '0.2', mode: 'edit'},
                                      }
                                    ]
                                  },
                                ]
                              },
                            ]
                          },
                        ]
                      },
                    ]
                  },
                ]
              },
              {
                path: 'students',
                component: PlaceholderComponent,
                resolve: {franchise: FranchiseResolver},
                // canActivate: [AdminInstructorGuardService],
                data: {breadcrumb: 'groupsOverview', linkCode: '0.2'},
                children: [
                  {
                    path: '', component: PlaceholderComponent, data: {breadcrumb: '', linkCode: '0.2'},
                    children: [
                      {
                        path: '',
                        component: OverviewComponent,
                        data: {breadcrumb: '', linkCode: '0.2', overviewType: OverviewType.OWN},
                        children: [{path: '', component: GroupListComponent, data: {breadcrumb: '', linkCode: '0.2'}}]
                      },
                      {
                        path: 'new-group',
                        component: GroupFormComponent,
                        data: {breadcrumb: 'addingGroup', linkCode: '0.2'},
                      },
                      {
                        path: 'edit-franchise',
                        component: FranchiseFormComponent,
                        data: {breadcrumb: 'editingFranchise', linkCode: '0.2', mode: 'edit'},
                      },
                      {
                        path: 'group/:group-id',
                        resolve: {group: GroupResolver},
                        component: PlaceholderComponent,
                        data: {breadcrumb: 'groupOverview', linkCode: '0.2'},
                        children: [
                          {
                            path: '',
                            component: OverviewComponent,
                            data: {breadcrumb: '', linkCode: '0.2', overviewType: OverviewType.GROUP},
                            children: [{path: '', component: StudentListComponent, data: {breadcrumb: '', linkCode: '0.2'}}]
                          },
                          {
                            path: 'edit',
                            component: GroupFormComponent,
                            data: {breadcrumb: 'editingGroup', linkCode: '0.2', mode: 'edit'},
                          },
                          {
                            path: 'new-student',
                            component: StudentFormComponent,
                            data: {breadcrumb: 'addingStudent', linkCode: '0.2'},
                          },
                          {
                            path: ':student-id',
                            pathMatch: 'full',
                            component: OverviewComponent,
                            resolve: {student: StudentResolver},
                            data: {breadcrumb: 'studentOverview', linkCode: '0.2', overviewType: OverviewType.STUDENT},
                          },
                          {
                            path: ':student-id/edit',
                            pathMatch: 'full',
                            component: StudentFormComponent,
                            resolve: {student: StudentResolver},
                            data: {breadcrumb: 'editingStudent', linkCode: '0.2', mode: 'edit'},
                          }
                        ]
                      },
                    ]
                  },
                ]
              },
            ]
          },
          {
            path: 'master-franchisors',
            component: FranchiseListComponent,
            data: {breadcrumb: 'masterFranchisors', linkCode: '0.2.1'}
          },
          {
            path: 'franchisors-and-instructors',
            component: FranchiseListComponent,
            data: {breadcrumb: 'franchisorsAndInstructors', linkCode: '0.2.3'}
          },
        ]
      },
      {
        path: 'new-lessons',
        component: NewLessonsContainerComponent,
        canActivate: [PermissionGuardService],
        data: {breadcrumb: 'new-lessons', linkCode: '0.5'},
      },
      {
        path: 'edu',
        component: EduComponent,
        canActivate: [PermissionGuardService],
        data: {breadcrumb: 'edu', linkCode: '0.3'},
      },
    ]
  },
  {
    path: 'login',
    loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule)
   },
  {
    path: 'learnability',
    loadChildren: () => import('./edu/edu.module').then(m => m.EduModule)
  },
  {path: 'map', component: LanguageMapComponent},
  {path: 'not-found', component: NotFoundComponent, data: {message: 'Page not found!'}},
  {path: '**', redirectTo: '/not-found'}
];

@NgModule({
  imports: [RouterModule.forRoot(routes, routerOptions)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
