import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'arrayfilter',
  pure: false
})
export class ArrayfilterPipe implements PipeTransform {
  transform(items: any[], property: string, filter: string): any {
    if (!items || !filter) {
      return items;
    }
    // filter items array, items which match and return true will be
    // kept, false will be filtered out
    return items.filter(item => spec_strip(item[property].toLowerCase()).indexOf(spec_strip(filter.toLowerCase())) !== -1);
  }
}
function spec_strip(s: string): string {
  s = s.replace('š', 's');
  s = s.replace('ć', 'c');
  s = s.replace('č', 'c');
  s = s.replace('đ', 'dj');
  s = s.replace('ž', 'z');

  return s;
}
