import {Component, EventEmitter, Input, OnChanges, Output, SimpleChanges} from '@angular/core';
import {CdkStepper} from '@angular/cdk/stepper';
import {slideLeftRight} from '../../../animations/animations';

@Component({
  selector: 'app-progress-stepper',
  templateUrl: './progress-stepper.component.html',
  styleUrls: ['./progress-stepper.component.scss'],
  providers: [{provide: CdkStepper, useExisting: ProgressStepperComponent}],
  animations: [slideLeftRight]
})
export class ProgressStepperComponent extends CdkStepper implements OnChanges {
  @Input() limit: number;
  @Input() isSaved = false;
  @Input() isCanceled = false;
  @Input() isEditMode = false;
  @Input() selectedStep: number;
  @Input() titles: string[] = [];
  @Output() valueSelected = new EventEmitter<number>();

  onClick(index: number) {
    if (this.isEditMode && index <= this.limit) {
      if (index === this.selectedIndex && index !== 0) {
        this.selectedIndex = index - 1;
      } else {
        this.selectedIndex = index;
      }
      this.valueSelected.emit(this.selectedIndex);
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.limit) {
      this.limit = changes.limit.currentValue;
    }
    if (changes.isEditMode) {
      if (changes.isEditMode && changes.isEditMode.currentValue === true) {
        this.selectedStep = this.selectedIndex;
      }
      this.isEditMode = changes.isEditMode.currentValue;
    }
    if (changes.selectedStep) {
      this.selectedIndex = changes.selectedStep.currentValue;
      this.selectedStep = this.selectedIndex;
    }
    if (changes.isSaved && changes.isSaved.currentValue === true) {
      this.selectedStep = this.selectedIndex;
    }
    if (changes.isCanceled && changes.isCanceled.currentValue === true) {
      this.selectedIndex = this.selectedStep;
    }
  }
}
