import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subject} from 'rxjs';
import {ActivatedRoute} from '@angular/router';
import {MediatorService} from '../../../services/mediator.service';
import {RequestsService} from '../../../services/requests.service';
import {LoggedUserService} from '../../../services/logged-user.service';
import {FranchisesService} from '../../franchises/franchises.service';
import {OverviewService} from '../overview.service';
import {StudentsService} from '../../students/students.service';

@Component({
  selector: 'app-general-info',
  templateUrl: './general-info.component.html',
  styleUrls: ['./general-info.component.scss']
})
export class GeneralInfoComponent implements OnInit, OnDestroy {
  destroy$: Subject<boolean> = new Subject();

  constructor(
    public mediator: MediatorService,
    public students: StudentsService,
    public overview: OverviewService,
    public loggedUser: LoggedUserService,
    public franchises: FranchisesService,
  ) { }

  ngOnInit() {
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
}
