import {PipeTransform, Pipe} from '@angular/core';
import * as moment from 'moment';

@Pipe({name: 'dateFormatterPipe'})
export class DateFormatterPipe implements PipeTransform {
  transform(date: any, format: string): any {
    if (!format) {
      return date;
    }
    if (date && date.indexOf('-') > -1) {
      const year = date.split('-')[0];
      const month = date.split('-')[1];
      const day = date.split('-')[2];

      return `${day}.${month}.${year}.`;
    } else {
      // This line throws warning in console when date format like 2019-05-02 is passed
      // return moment(date).format(format) === 'Invalid date' ? '' : moment(date).format(format);
    }
  }
}
