import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-bookmarks-container',
  template: `<app-bookmarks></app-bookmarks>`,
  styles: [],
})
export class BookmarksContainerComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
