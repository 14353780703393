import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {MatDialog, MatDialogConfig, MatSnackBar} from '@angular/material';
import {LoggedUserService} from './logged-user.service';
import {ImageCroppedEvent} from 'ngx-image-cropper';
import {ImageCropperDialogComponent} from '../shared/components/dialogs/image-cropper-dialog/image-cropper-dialog.component';
import {TranslationService} from '../utils/translate.service';

@Injectable({providedIn: 'root'})
export class CropperService {
  api = '';
  chosenImage = '';
  croppedImage = '';
  isDialogOpen = false;
  isCropperVisible = false;
  imageChangedEvent: any = '';

  defaultFormat = 'jpg';
  maxAllowedSize = 90000000; // TODO: set to 1000000
  format = this.defaultFormat;
  allowedFileTypes = ['jpg', 'png'];

  constructor(
    private http: HttpClient,
    public dialog: MatDialog,
    private snackbar: MatSnackBar,
    public loggedUser: LoggedUserService,
    private translate: TranslationService,
  ) {
  }

  fileChangeEvent(event: any): void {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      const size = event.target.files[0].size;
      const extension = file.name.substring(file.name.lastIndexOf('.') + 1, file.name.length);

      const hasValidType = this.allowedFileTypes.some(type => type === extension);
      if (hasValidType && size <= this.maxAllowedSize) {
        this.imageChangedEvent = event;
        this.format = extension;
        this.isDialogOpen = true;
        this.isCropperVisible = true;
      } else {
        this.snackbar.open(`${this.translate.instant('supportedImageFormats')}: ${this.allowedFileTypes.join(', ')}. \n
             ${this.translate.instant('maximumAllowedFileSizeIs')}: 1Mb`, null, {duration: 4000});
      }
    }
  }

  openDialog(dialogData: any = {}) {
    return this.dialog.open(ImageCropperDialogComponent, {
      data: dialogData,
      autoFocus: false,
      panelClass: 'custom-dialog'
    } as MatDialogConfig).afterClosed();
  }

  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
  }

  imageLoaded() {
    this.isCropperVisible = true;
  }

  cropperReady() {
  }

  loadImageFailed() {
    this.format = this.defaultFormat;
    this.snackbar.open(this.translate.instant('loadingImageFailed'), null,
      {duration: 4000, verticalPosition: 'top'});
  }

  changeProfileImage(url: string, data: any, name: string) {
    return this.http.post(url, data).subscribe(res => {
    });
  }

  resetCropper() {
    this.chosenImage = '';
    this.croppedImage = '';
    this.isDialogOpen = false;
    this.imageChangedEvent = '';
    this.isCropperVisible = false;
    this.format = this.defaultFormat;
  }

  cancelCrop() {
    if (this.chosenImage.length === 0) {
      this.croppedImage = '';
    }
    this.isDialogOpen = false;
    this.imageChangedEvent = '';
    this.isCropperVisible = false;
    this.format = this.defaultFormat;
  }
}
