import {Injectable} from '@angular/core';
import {ActivatedRoute, ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';
import {LoggedUserService} from '../services/logged-user.service';

@Injectable()
export class AdminFranchiseGuardService implements CanActivate, CanActivateChild {
  constructor(private loggedUser: LoggedUserService, private router: Router, private route: ActivatedRoute) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Promise<boolean> | Observable<boolean> {
    return new Promise((resolve) => {
      if ((this.loggedUser.isFranchisor && !this.loggedUser.isInstructor) && !this.loggedUser.isAdmin) {
        resolve(true);
      } else {
        resolve(false);
        this.router.navigate(['dashboard']);
      }
    });
  }

  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.canActivate(route, state);
  }
}

