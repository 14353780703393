import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {MediatorService} from '../../../services/mediator.service';
import {RequestsService} from '../../../services/requests.service';
import {LoggedUserService} from '../../../services/logged-user.service';
import {FranchisesService} from '../../franchises/franchises.service';
import {Subject} from 'rxjs';
import {environment} from '../../../../environments/environment';
import {takeUntil} from 'rxjs/operators';
import {StudentsService} from '../../students/students.service';
import {OverviewService} from '../overview.service';
import {CropperService} from '../../../services/cropper.service';
import {ToastService} from '../../../services/app-toast.service';

@Component({
  selector: 'app-info-header',
  templateUrl: './info-header.component.html',
  styleUrls: ['./info-header.component.scss']
})
export class InfoHeaderComponent implements OnInit, OnDestroy {
  imageType = '';
  chosenCoverPhoto = '';
  chosenProfilePhoto = '';
  environment = environment;

  destroy$: Subject<boolean> = new Subject();

  constructor(
    private router: Router,
    private toast: ToastService,
    private route: ActivatedRoute,
    public cropper: CropperService,
    public mediator: MediatorService,
    public overview: OverviewService,
    public students: StudentsService,
    private requests: RequestsService,
    public loggedUser: LoggedUserService,
    public franchises: FranchisesService,
  ) {
  }

  ngOnInit() {
    this.setImages();
  }

  ngOnDestroy() {
    this.cropper.resetCropper();
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  optionSelect(id) {
    if (this.overview.isMasterOverview) {
      this.router.navigate(['administration', 'master-franchises', id, 'franchises']);
    } else if (this.overview.isGroupOverview) {
      this.router.navigate(['group', id], {relativeTo: this.route.parent.parent});
    } else {
      this.router.navigate([id], {relativeTo: this.route.parent.parent.parent});
    }
  }

  fileChanged(event) {
    this.cropper.fileChangeEvent(event);
    const uploadTrigger: any = document.getElementById('uploadTrigger');

    if (this.cropper.isCropperVisible) {
      this.cropper.openDialog({
        type: this.imageType,
        cropper: this.cropper,
      }).pipe(takeUntil(this.destroy$)).subscribe((confirmed: boolean) => {
        if (confirmed) {
          this.cropper.isDialogOpen = false;
          this.cropper.isCropperVisible = false;
          this.cropper.chosenImage = this.cropper.croppedImage;

          let target = '';
          if (this.imageType === 'profile') {
            if (this.overview.isStudentOverview) {
              target = 'target-user-profile';
            } else {
              target = 'franchise-profile';
            }
          } else {
            target = 'franchise-cover';
          }
          this.requests.svc_post('/api/upload-image', {
            target_name: target,
            image: this.cropper.croppedImage,
            target_id: this.overview.isStudentOverview ? this.students.studentData.id : this.franchises.currentFranchise.id,
          }, this.loggedUser.getToken()).pipe(takeUntil(this.destroy$)).subscribe((resp: any) => {
            if (this.imageType === 'profile') {
              this.chosenProfilePhoto = this.cropper.croppedImage;
            } else {
              this.chosenCoverPhoto = this.cropper.croppedImage;
            }
            this.toast.showInfoToast('photoSuccessfullyUploaded');
          }, err => {
            this.setImages();
            this.toast.showError();
          });
        } else {
          this.cropper.cancelCrop();
        }
        if (uploadTrigger) {
          uploadTrigger.value = '';
        }
      });
    } else {
      if (uploadTrigger) {
        uploadTrigger.value = '';
      }
    }
  }

  setImages() {
    this.chosenProfilePhoto = this.franchises.currentFranchise.image ?
      this.franchises.currentFranchise.image : '/assets/img/default-franchise-profile.jpg';
    if (this.overview.isOwnOverview && this.loggedUser.isAdmin) {
      this.chosenCoverPhoto = this.franchises.currentFranchise.profile_image ?
        this.franchises.currentFranchise.profile_image : '/assets/img/top-default-franchise-cover.jpg';
    } else if (this.overview.isMasterOverview) {
      this.chosenCoverPhoto = this.franchises.currentFranchise.profile_image ?
        this.franchises.currentFranchise.profile_image : '/assets/img/master-default-franchise-cover.jpg';
    } else if (this.overview.isFranchiseOverview) {
      this.chosenCoverPhoto = this.franchises.currentFranchise.profile_image ?
        this.franchises.currentFranchise.profile_image : '/assets/img/basic-default-franchise-cover.jpg';
    } else if (this.overview.isStudentOverview) {
      this.chosenCoverPhoto = this.students.studentData.franchise.franchise_cover_image ?
        this.students.studentData.franchise.franchise_cover_image : '/assets/img/basic-default-franchise-cover.jpg';
      this.chosenProfilePhoto = this.students.studentData.image ?
        this.students.studentData.image : '/assets/img/default-user-profile.jpg';
    } else {
      this.chosenCoverPhoto = this.franchises.currentFranchise.profile_image ?
        this.franchises.currentFranchise.profile_image : '/assets/img/basic-default-franchise-cover.jpg';
    }
  }

  back() {
    this.router.navigate(['../'], {relativeTo: this.route});
  }
}
