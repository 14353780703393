import { Injectable } from '@angular/core';
import {RequestsService} from './requests.service';
import {GuestService} from './guest.service';
import {throwError} from 'rxjs';
import {ToastService} from './app-toast.service';
import {Router} from '@angular/router';
import {MediatorService} from './mediator.service';
import {LoggedUserService} from './logged-user.service';

@Injectable({
  providedIn: 'root'
})
export class FaqService {
  private questionSubmittedId: number;

  constructor(private requests: RequestsService, private guest: GuestService, private toastService: ToastService, private router: Router,
              private mediator: MediatorService, private loggedUser: LoggedUserService) { }

  getFAQList() {}

  getFAQ() {
  }

  submitQuestion(question) {
    const data: any = {
      question_to_answer: question,
    };

    if (this.guest.id) {
      data.guest_id = this.guest.id;
    }

    this.requests.submitQuestion(data).subscribe({
      next: (value: any) => {
        this.questionSubmittedId = value.id_question;

        if (this.guest.id) {
          this.toastService.showInfoToast('questionSubmitted');
        } else {
          this.mediator.questionWithoutGuestId = true;
          this.router.navigateByUrl('/login/guest');
        }
      },
    });
  }

  bindQuestion() {
    if (this.questionSubmittedId !== undefined && this.guest.id !== undefined) {
      let data: any = {
        id_question: this.questionSubmittedId,
        guest_id: this.guest.id,
      };

      this.requests.bindQuestion(data).subscribe({
        next: value => {
          this.toastService.showInfoToast('questionSubmitted');
        }
      });
    }
  }

  getAllSubmittedQuestions() {
    return this.requests.svc_get('/api/question', null, this.loggedUser.getToken());
  }
}

