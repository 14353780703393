import { Injectable, OnInit } from '@angular/core';
import {RequestsService} from './requests.service';
import {LoggedUserService} from './logged-user.service';
import {BehaviorSubject, Subject} from 'rxjs';
import {GuestService} from './guest.service';
import {MediatorService} from './mediator.service';
import {lang} from 'moment';

export interface Lecture {
  id: number;
  name: string;
  videos?: string[];
  duration: number;
  watched: boolean;
  files?: string[];
  description?: string;
}

export interface Section {
  id: number;
  title: string;
  lectures?: Lecture[];
}

export interface Course {
  id: number;
  title: string;
  description: string;
  introductionVideo: string;
  sections: Section[];
}

interface Country {
  name: string;
  id: string;
}

@Injectable({
  providedIn: 'root'
})
export class ProgrammeService {

  public courses = [];
  public publicVideoList = [];

  private grades: Array<any> = Array.from(Array(12), (_, i) => i + 1);

  programme;
  programme$: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  countries: Country[] = [];
  countries$: BehaviorSubject<Array<Country>> = new BehaviorSubject<Array<Country>>([]);

  activeLesson$: Subject<number> = new Subject<number>();

  constructor(private requests: RequestsService,
              private guest: GuestService,
              public mediator: MediatorService,
              private loggedUser: LoggedUserService) {
  }

  getCountries() {
    this.requests.svc_get('/api/countries', {}, this.loggedUser.getToken()).subscribe({
      next: value => {
        this.countries = (value as any).countries as Array<Country>;
        this.countries$.next(this.countries);
      }
    });
  }

  public getCourseLessions(id: number): Section[] {
    return this.courses[id].sections;
  }

  public getAvailableCourses() {
    return this.requests.svc_get(
      '/api/online-guest-course-public',
      {country: this.guest.country ? this.guest.country : 'srb',
            grade: this.guest.grade ? this.guest.grade : '1'});
  }

  public getAvailablePublicVideos() {
    const language = localStorage.getItem('lang');
    return this.requests.getPublicVideos(this.mediator.languageToCountryId(language));
  }

  public getAllAvailablePublicVideos() {
    return this.requests.svc_get('/api/public-videos/all', null, this.loggedUser.getToken());
  }

  public deletePublicVideo(id) {
    return this.requests.svc_delete(`/api/public-videos/delete/${id}`, null, this.loggedUser.getToken());
  }

  public getAllOnlineProgrammes() {
    return this.requests.svc_get('/api/onlinecourse/guests/all', null, this.loggedUser.getToken());
  }

  public getProgramme(id) {
    this.requests.svc_get(`/api/online-course/${id}`, null, false).subscribe({
      next: value => {
        if (value !== null) {
          this.programme = value;
          this.programme$.next(this.programme);
        }
      },
    });
  }

  public addProgramme(programme) {
    // programme = {...programme, grades: programme.grades.join(', ')};
    return this.requests.svc_put('/api/onlinecourse/guests', programme, this.loggedUser.getToken());
  }

  public editProgramme(id, programme) {
    // programme = {...programme, grades: programme.grades.join(', ')};
    return this.requests.svc_put(`/api/online-course/${id}`, programme, this.loggedUser.getToken());
  }

  public deleteProgramme(id) {
    return this.requests.svc_delete(`/api/online-course/${id}`, null, this.loggedUser.getToken());
  }

  public getGrades() {
    return this.grades;
  }

  public videoURL(url, onlyCodes: boolean = false) {
    if (url.indexOf('youtu') !== -1) {
      const regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
      const match = url.match(regExp);
      return onlyCodes ? (match && match[7].length === 11) ? match[7] : false : 'https://www.youtube.com/embed/' + ((match && match[7].length === 11) ? match[7] : false);
    } else if (url.indexOf('vimeo') !== -1) {
      const regExp = /(http|https)?:\/\/(www\.)?vimeo.com\/(?:channels\/(?:\w+\/)?|groups\/([^\/]*)\/videos\/|)(\d+)(?:|\/\?)/;
      const match = url.match(regExp);
      return onlyCodes ? match[4] : 'https://player.vimeo.com/video/' + match[4];
    }
  }

  changeLessonViewStatus(programmeId, lessonId, viewed: boolean) {
    this.guest.changeLessonViewStatus(programmeId, lessonId, viewed);
  }
}
