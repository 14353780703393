import {Component, OnDestroy, OnInit} from '@angular/core';
import {MediatorService} from '../services/mediator.service';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {environment} from '../../environments/environment';
import {LoggedUserService} from '../services/logged-user.service';
import {RequestsService} from '../services/requests.service';
import {Router} from '@angular/router';
import {ToastService} from '../services/app-toast.service';
import {LINK_DATA} from '../shared/components/sidenav/link-data';
import {TranslationService} from '../utils/translate.service';
import {version} from '../../app.version.js';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy {
  chosenLang = 'EN';
  isSearchEnabled = false;
  environment = environment;
  version = version;

  destroy$: Subject<boolean> = new Subject();

  constructor(
    private router: Router,
    public toast: ToastService,
    private requests: RequestsService,
    private mediator: MediatorService,
    public translate: TranslationService,
    public loggedUser: LoggedUserService,
  ) {
  }

  ngOnInit() {
    this.chosenLang = this.translate.lang.toLocaleUpperCase();
    this.mediator.searchInputState$.pipe(takeUntil(this.destroy$)).subscribe(isEnabled => {
      if (!isEnabled) {
        this.mediator.inputValue = '';
      }
      this.isSearchEnabled = isEnabled;
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  changeLanguage(lang) {
    this.chosenLang = lang;
    this.translate.setLang(lang);
    localStorage.setItem('lang', lang);
  }

  goToProfile() {
    this.router.navigate(['profile']);
  }

  logout() {
    this.mediator.linkData = [...LINK_DATA];
    this.requests.logoutUser().pipe(takeUntil(this.destroy$)).subscribe(res => {
        this.loggedUser.logout(res);
        this.mediator.isTreeBuilt = false;
        this.loggedUser.loginData = {username: '', password: ''};
        this.router.navigate(['login']);
      }, error => {
        this.mediator.isTreeBuilt = false;
        this.loggedUser.logout(error);
        this.toast.showError();
        this.router.navigate(['login']);
      }
    );
  }
}
