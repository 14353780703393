import {AfterViewInit, Component, OnDestroy, OnInit} from '@angular/core';
import {Subject} from 'rxjs';
import {ActivatedRoute, Router} from '@angular/router';
import {ToastService} from '../../../services/app-toast.service';
import {MediatorService} from '../../../services/mediator.service';
import {TableService} from '../../../services/table.service';
import {TranslationService} from '../../../utils/translate.service';
import {LoggedUserService} from '../../../services/logged-user.service';
import {mergeMap, takeUntil} from 'rxjs/operators';
import {StudentsService} from '../students.service';
import {FranchisesService} from '../../franchises/franchises.service';
import {MatDialog, MatDialogConfig} from '@angular/material';
import {AddDebtDialogComponent} from '../../../shared/components/dialogs/add-debt-dialog/add-debt-dialog.component';
import {OverviewService} from '../../overview/overview.service';
import {environment} from '../../../../environments/environment';

@Component({
  selector: 'app-student-list',
  templateUrl: './student-list.component.html',
  styleUrls: ['./student-list.component.scss'],
  providers: [TableService, StudentsService]
})
export class StudentListComponent implements OnInit, AfterViewInit, OnDestroy {
  url = '';
  franchiseId;
  customParams;
  environment = environment;
  searchValue = '';

  displayedColumns = ['image', 'name', 'email', 'country', 'city', 'instructor', 'group', 'info'];
  showSelects: boolean = false;
  selectedIDs: Array<string> = [];

  destroy$: Subject<boolean> = new Subject();

  constructor(
    private router: Router,
    public dialog: MatDialog,
    private toast: ToastService,
    private route: ActivatedRoute,
    public overview: OverviewService,
    public students: StudentsService,
    public mediator: MediatorService,
    public tableService: TableService,
    public translate: TranslationService,
    public franchises: FranchisesService,
    private loggedUser: LoggedUserService
  ) {
  }

  ngOnInit() {
    this.tableService.currentRoute = this.route.parent.parent;
    this.mediator.searchInputStateSubject.next(true);
    this.mediator.searchContextSubject.next({
      shouldEmitText: true,
      hasAutocomplete: false,
      placeholder: 'searchStudents'
    });
    this.mediator.searchValueSubject.pipe(takeUntil(this.destroy$)).subscribe({
      next: value => this.searchValue = value,
    });

    this.franchises.franchiseDataSets$.pipe(takeUntil(this.destroy$)).subscribe(isDataSet => {
      if (isDataSet) {
        this.overview.overviewType = this.route.parent.routeConfig.data.hasOwnProperty('overviewType') ?
          this.route.parent.routeConfig.data.overviewType : '';

        this.franchiseId = this.franchises.currentFranchise.id;

        if (this.loggedUser.isInstructor || this.loggedUser.isFranchisor) {
          this.displayedColumns = ['image', 'name', 'email',
            // 'country',
            // 'city',
            // 'instructor', 'group', 'info',
            'actions'];
        } else {
          // this.customParams = {};
          // this.url = `/api/franchises/${this.franchiseId}/students`;
          // this.tableService.getData(this.url, {responseDataTitle: 'students', customParams: this.customParams});
        }

        this.franchises.currentGroup$.subscribe({
          next: value => this.filterStudents(value)
        });

        this.filterStudents();
        setTimeout(() => this.tableService.isContentVisible = this.tableService.data.length > 0);
      }
    });

    this.mediator.searchValue$.pipe(takeUntil(this.destroy$)).subscribe(val => {
      if (val != null) {
        this.tableService.filter(val);
      }
    });

    this.mediator.showStudentSelects$.pipe(takeUntil(this.destroy$)).subscribe(show => {
      this.showSelects = !this.showSelects;
      if (this.showSelects) {
        this.displayedColumns.unshift('select');
      } else {
        this.selectedIDs = [];
        this.displayedColumns.shift();
      }
    });
  }

  ngAfterViewInit() {
    this.tableService.dataSource.data$.pipe(
      mergeMap(data => {
        if ((data != null && data.length > 0)) {
          this.tableService.data = data;
          this.tableService.isContentVisible = true;
        } else {
          // this.tableService.isContentVisible = false;
        }
        Promise.resolve(null).then(() => this.tableService.isContentVisible = true);
        return this.tableService.dataSource.counter$;
      }),
      mergeMap(count => {
        this.tableService.setTotal(count);
        return this.tableService.dataSource.loading$;
      }), takeUntil(this.destroy$)
    ).subscribe(isLoading => {
      // This resolves Error: ExpressionChangedAfterItHasBeenCheckedError: Expression has changed after it was checked
      Promise.resolve(null).then(() => this.tableService.isLoadingVisible = isLoading);
    }, err => {
    });
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();

    this.mediator.searchInputStateSubject.next(false);
    this.mediator.searchValueSubject.next('');

    this.franchises.certificateStudentIds = [];
  }

  openDialog(e, id) {
    e.stopPropagation();
    this.dialog.open(AddDebtDialogComponent, {
      data: {studentId: id},
      panelClass: 'custom-dialog'
    } as MatDialogConfig)
      .afterClosed().subscribe((confirmed: boolean) => {
      if (confirmed) {
        this.franchises.transactionCompletes$.next(this.franchiseId);
      }
    });
  }

  filterStudents(idGroup = null) {
    if (idGroup === null) {
      if (this.route.snapshot.params['group-id']) {
        this.tableService.data = this.franchises.currentFranchise.students.filter( student =>
          student.group.includes(+this.route.snapshot.params['group-id']) );
      } else {
        this.tableService.data = this.franchises.currentFranchise.students;
      }
    } else {
      this.tableService.data = this.franchises.currentFranchise.students.filter( student => student.group.includes(idGroup));
    }
  }

  rowSelected(data) {
    if (this.showSelects) {
      this.selectStudent(data.id);
    } else {
      this.router.navigate([data.id], {relativeTo: this.route.parent});
    }
  }

  selectStudent(id: string) {
    if (!this.isStudentSelected(id)) {
      this.selectedIDs.push(id);
    } else {
      const index = this.selectedIDs.indexOf(id);
      if (index > -1) {
        this.selectedIDs.splice(index, 1);
      }
    }

    this.franchises.certificateStudentIds = this.selectedIDs;
  }

  isStudentSelected(id: string) {
    return this.selectedIDs.includes(id);
  }


}
