import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {TranslationService} from '../../../../utils/translate.service';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {FormBuilder, Validators} from '@angular/forms';
import * as moment from 'moment';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {RequestsService} from '../../../../services/requests.service';
import {ToastService} from '../../../../services/app-toast.service';
import {LoggedUserService} from '../../../../services/logged-user.service';
import {ActivatedRoute} from '@angular/router';
import {MediatorService} from '../../../../services/mediator.service';
import {animate, keyframes, style, transition, trigger} from '@angular/animations';

@Component({
  selector: 'app-add-debt-dialog',
  templateUrl: './add-debt-dialog.component.html',
  styleUrls: ['./add-debt-dialog.component.scss'],
  animations: [
    trigger('fadeIn', [
      transition(':enter',
        animate(200,
          keyframes([
            style({opacity: 0, offset: 0}),
            style({opacity: 0.6, offset: 1})
          ])
        ))
    ])
  ]
})
export class AddDebtDialogComponent implements OnInit, OnDestroy {
  lastPaymentDate = localStorage.getItem('lastPaymentDate') ?
    moment(localStorage.getItem('lastPaymentDate')).toISOString() : moment().toISOString();

  paymentForm = this.fb.group({
    amount: this.fb.control(null, Validators.required),
    date: this.fb.control({value: this.lastPaymentDate, disabled: true}, Validators.required),
    description: this.fb.control(null),
  });

  minDate = new Date(2018, 0, 1);
  maxDate = new Date();

  destroy$: Subject<boolean> = new Subject();

  constructor(
    private fb: FormBuilder,
    private toast: ToastService,
    private route: ActivatedRoute,
    public mediator: MediatorService,
    private requests: RequestsService,
    public translate: TranslationService,
    private loggedUser: LoggedUserService,
    public dialogRef: MatDialogRef<AddDebtDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
  }

  get date() {
    return this.paymentForm.get('date');
  }

  get amount() {
    return this.paymentForm.get('amount');
  }

  ngOnInit() {
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  confirm() {
    if (this.paymentForm.valid) {
      let url = '';
      let data: {};
      this.mediator.isProgressBarVisible = true;

      if (this.data.hasOwnProperty('id')) {
        url = `/api/franchises/${this.data.id}/payment-received`;
        data = {
          ...this.paymentForm.getRawValue(),
          amount: +this.amount.value,
          date: moment(this.date.value).format('YYYY-MM-DD')
        };
        this.requests.svc_put(url, data, this.loggedUser.getToken()).pipe(takeUntil(this.destroy$)).subscribe(resp => {
          localStorage.setItem('lastPaymentDate', this.date.value);
          this.toast.showInfoToast(this.translate.instant('successfullyPaid'));
          this.mediator.isProgressBarVisible = false;
          setTimeout(() => this.dialogRef.close(true), 500);
        }, err => {
          if (err.error.hasOwnProperty('message') && err.error.message === 'Too much') {
            this.toast.showInfoToast(this.translate.instant('paidTooMuch'));
          } else {
            this.toast.showError();
          }
          this.mediator.isProgressBarVisible = false;
        });
      } else if (this.data.hasOwnProperty('studentId')) {
        url = `/api/student/${this.data.studentId}/payments`;
        data = {
          ...this.paymentForm.getRawValue(),
          amount: +this.amount.value,
          date: moment(this.date.value).format('YYYY-MM-DD')
        };
        this.requests.svc_patch(url, data, this.loggedUser.getToken()).pipe(takeUntil(this.destroy$)).subscribe(resp => {
          localStorage.setItem('lastPaymentDate', this.date.value);
          this.toast.showInfoToast(this.translate.instant('successfullyPaid'));
          this.mediator.isProgressBarVisible = false;
          setTimeout(() => this.dialogRef.close(true), 500);
        }, err => {
          if (err.error.hasOwnProperty('message') && err.error.message === 'Too much') {
            this.toast.showInfoToast(this.translate.instant('paidTooMuch'));
          } else {
            this.toast.showError();
          }
          this.mediator.isProgressBarVisible = false;
        });
      }
    } else {
      this.toast.showInfoToast(this.translate.instant('enterValidData'));
    }
  }

  cancel() {
    this.dialogRef.close(false);
  }
}
