import {Component, OnInit, Input, OnDestroy} from '@angular/core';
import { Section, ProgrammeService } from 'src/app/services/programme.service';
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';

@Component({
  selector: 'app-lessons-container',
  templateUrl: './lessons-container.component.html',
  styleUrls: ['./lessons-container.component.scss']
})
export class LessonsContainerComponent implements OnInit, OnDestroy {
  destroy$: Subject<boolean> = new Subject<boolean>();

  lessions;
  panelOpenState = false;

  activeLesson = 0;

  constructor(public courseService: ProgrammeService) { }

  ngOnInit() {
    this.courseService.programme$.pipe(takeUntil(this.destroy$)).subscribe({
      next: value => {
        if (value !== null) {
          this.lessions = value.items.programme;
        }
      }
    });
    this.courseService.activeLesson$.subscribe({
      next: value => {
        this.activeLesson = value;
      }
    });
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  showLesson(id) {
    this.courseService.activeLesson$.next(id);
  }

}
