export const LINK_DATA = [
  // {
  //   title: 'dashboard',
  //   disabled: true,
  //   path: '',
  //   linkIcon: 'dashboard',
  //   active: false,
  //   code: '0.1',
  //   expandable: false
  // },
  // {
  //   title: 'programmes',
  //   disabled: true,
  //   path: '',
  //   linkIcon: 'dashboard',
  //   active: false,
  //   code: '0.1',
  //   expandable: false
  // },
  // {
  //   title: 'edu',
  //   disabled: false,
  //   path: '/edu',
  //   linkIcon: 'school',
  //   active: false,
  //   code: '0.3',
  //   expandable: false
  // },
  // {
  //   title: 'New Lessons',
  //   disabled: false,
  //   path: '/new-lessons',
  //   linkIcon: 'school',
  //   active: false,
  //   code: '0.5',
  //   expandable: false
  // },
  // {
  //   title: 'permissionManagement',
  //   disabled: true,
  //   path: '',
  //   linkIcon: 'people',
  //   active: false,
  //   code: '0.6',
  //   expandable: false
  // }
];
