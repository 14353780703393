import {Component, ElementRef, OnDestroy, OnInit, ViewChild, ViewEncapsulation, Output} from '@angular/core';
import {LoggedUserService} from '../../services/logged-user.service';
import {TranslationService} from '../../utils/translate.service';
import {ActivatedRoute, Router} from '@angular/router';
import {Subject} from 'rxjs';
import {animate, keyframes, transition, trigger} from '@angular/animations';
import * as kf from '../../animations/keyframes';
import {MediatorService} from '../../services/mediator.service';
import {MatDialog, MatDialogConfig} from '@angular/material';
import {ChangePasswordDialogComponent} from '../../auth/change-password-dialog/change-password-dialog.component';
import {takeUntil} from 'rxjs/operators';
import {CropperService} from '../../services/cropper.service';
import {RequestsService} from '../../services/requests.service';
import {FormBuilder, Validators} from '@angular/forms';
import {ToastService} from '../../services/app-toast.service';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
  animations: [
    trigger('fadeIn', [
      transition(':enter', animate(500, keyframes(kf.fadeIn)))
    ])
  ],
  encapsulation: ViewEncapsulation.None
})
export class ProfileComponent implements OnInit, OnDestroy {
  imageType = '';
  chosenCoverPhoto  = `${this.loggedUser.coverPhoto}`;
  chosenProfilePhoto = `${this.loggedUser.profilePhoto}`;
  editMode = false;
  value = '';

  // TODO: fill with appropriate data
  profileForm = this.formBuilder.group({
    email: [this.loggedUser.email || '', Validators.required],
    country: ['—', Validators.required],
    city: ['—', Validators.required],
    address: ['—', Validators.required],
    phoneNumber: ['—', Validators.required],
    firstName: [this.loggedUser.firstName, Validators.required],
    lastName: [this.loggedUser.lastName, Validators.required]
  });

  @ViewChild('uploadTrigger', {static: true}) fileUploader: ElementRef;
  @ViewChild('firstName', {static: true}) firstNameField: ElementRef;

  destroy$: Subject<boolean> = new Subject();

  constructor(
    private router: Router,
    public dialog: MatDialog,
    public toast: ToastService,
    private route: ActivatedRoute,
    public cropper: CropperService,
    private formBuilder: FormBuilder,
    public mediator: MediatorService,
    private requests: RequestsService,
    public translate: TranslationService,
    public loggedUser: LoggedUserService
  ) {
  }

  ngOnInit() {
    this.profileForm.disable();
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  update() {
    if (this.editMode) {
      // ...
      console.log(this.profileForm.value);
    }
    this.editMode = false;
  }

  edit() {
    this.profileForm.enable({emitEvent: this.editMode = true});
    this.firstNameField.nativeElement.focus();
  }

  cancel() {
    this.profileForm.disable({emitEvent: this.editMode = false});
  }

  openDialog() {
    this.dialog.open(ChangePasswordDialogComponent, {panelClass: 'custom-dialog'} as MatDialogConfig);
    this.cancel();
  }

  fileChanged(event) {
    this.cropper.fileChangeEvent(event);
    const uploadTrigger: any = document.getElementById('uploadTrigger');

    if (this.cropper.isCropperVisible) {
      this.cropper.openDialog({
        type: this.imageType,
        cropper: this.cropper,
      }).pipe(takeUntil(this.destroy$)).subscribe((confirmed: boolean) => {
        if (confirmed) {
          this.cropper.isDialogOpen = false;
          this.cropper.isCropperVisible = false;
          this.cropper.chosenImage = this.cropper.croppedImage;

          let target = '';
          if (this.imageType === 'profile') {
            target = 'self-user-profile';
          } else {
            target = 'self-user-cover';
          }
          this.requests.svc_post('/api/upload-image', {
            target_name: target,
            image: this.cropper.croppedImage,
          }, this.loggedUser.getToken()).pipe(takeUntil(this.destroy$)).subscribe((resp: any) => {
            if (this.imageType === 'profile') {
              this.chosenProfilePhoto = `/assets/upload-images/${resp.fname}`;
            } else {
              this.chosenCoverPhoto = `/assets/upload-images/${resp.fname}`;
            }
            this.toast.showInfoToast('photoSuccessfullyUploaded');
          }, err => this.toast.showError());
        } else {
          this.cropper.cancelCrop();
        }
        if (uploadTrigger) {
          uploadTrigger.value = '';
        }
      });
    } else {
      if (uploadTrigger) {
        uploadTrigger.value = '';
      }
    }
  }
}
