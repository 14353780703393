import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {Router} from '@angular/router';
import {BannersService} from '../../services/banners.service';

@Component({
  selector: 'app-courses-descr-banner',
  templateUrl: './courses-descr-banner.component.html',
  styleUrls: ['./courses-descr-banner.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CoursesDescrBannerComponent implements OnInit {

  slideConfig = {
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    pauseOnHover: true,
    infinite: true,
    dots: true,
    arrows: false,
  };

  slideConfigFirst = {
    ...this.slideConfig,
    autoplaySpeed: 7000,
  };

  slideConfigSecond = {
    ...this.slideConfig,
    autoplaySpeed: 4000,
  };

  slides: Array<any>;
  slides2: Array<any>;
  constructor(private router: Router, private svc: BannersService) {
    this.svc.banners$.subscribe({
      next: banners => {
        this.slides = banners.programmes;
        this.slides2 = banners.news;
      }
    });

    this.svc.getBanners();
  }

  ngOnInit() {
  }

  getImageLink(img: string): string {
    return `/static/storage/blog_images/${img}`;
  }

  openPost(link) {
    window.open(link);
  }
}
