/* tslint:disable:no-string-literal */
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {BehaviorSubject, of, throwError} from 'rxjs';

import { RequestsService } from './requests.service';
import {ToastService} from "./app-toast.service";

@Injectable({
  providedIn: 'root'
})
export class GuestService {
  public id: string;
  public userName: string;
  public firstName: string;
  public lastName: string;
  public age: number;
  public grade: number;
  public country: string;
  public city: string;
  public lessons: Array<any>;

  public isGuest: boolean = false;
  public isGuest$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(private router: Router, private requests: RequestsService, private toast: ToastService) { }

  setGuest(guest: object) {
    this.id = guest['id'];
    localStorage.setItem('guest-id', this.id);
    localStorage.setItem('guest-info', JSON.stringify(guest));
    localStorage.setItem('guest-lessons', JSON.stringify([]));
  }

  checkGuest() {
    // tslint:disable-next-line:no-conditional-assignment
    if ((this.id = localStorage.getItem('guest-id')) !== null) {
      return this.requests.checkGuest(this.id);
    } else {
      return throwError('no guest-id');
    }
  }

  getGuestInfo() {
    const guestinfo = JSON.parse(localStorage.getItem('guest-info'));

    this.userName = guestinfo['username'];
    this.firstName = guestinfo['first_name'];
    this.lastName = guestinfo['last_name'];
    this.age = guestinfo['age'];
    this.city = guestinfo['city'];
    this.grade = guestinfo['grade'];
    this.country = guestinfo['country'];

    this.lessons = JSON.parse(localStorage.getItem('guest-lessons'));
  }

  setGuestInfo() {
    const guestinfo = {};
    guestinfo['username'] = this.userName;
    guestinfo['first_name'] = this.firstName;
    guestinfo['last_name'] = this.lastName;
    guestinfo['age'] = this.age;
    guestinfo['city'] = this.city;
    guestinfo['grade'] = this.grade;

    localStorage.setItem('guest-info', JSON.stringify(guestinfo));
  }

  patchGuestInfo(newGuestInfo) {
    this.requests.svc_patch(`/api/guests/${this.id}`, newGuestInfo).subscribe({
      next: value => {
        this.userName = newGuestInfo.username ? newGuestInfo.username : this.userName;
        // this.firstName = newGuestInfo['first_name'];
        // this.lastName = newGuestInfo['last_name'];
        this.age = newGuestInfo.age ? newGuestInfo.age : this.age;
        this.city = newGuestInfo.city ? newGuestInfo.city : this.city;
        this.grade = newGuestInfo.grade ? newGuestInfo.grade : this.grade;

        this.setGuestInfo();
        this.toast.showInfoToast('infoUpdated');
      },
      error: err => {
        this.toast.showError();
      }
    });
  }


  loginGuest() {
    this.isGuest$.next(true);
    this.isGuest = true;
    this.getGuestInfo();
  }

  logoutGuest() {
    this.id = null;
    localStorage.removeItem('guest-id');
    localStorage.removeItem('guest-info');
    localStorage.removeItem('guest-lessons');
    this.isGuest$.next(false);
  }

  registerGuest(username: string, data: object) {
    return this.requests.registerGuest(username, data);
  }

  changeLessonViewStatus(programmeId, lessonId, viewed: boolean) {
    let progIndex = this.lessons.findIndex(programme => programme.id === programmeId);

    if (progIndex === -1) {
      progIndex = this.lessons.push({id: programmeId, lessons: {}});
      progIndex -= 1;
    }

    this.lessons[progIndex].lessons.lessonId = viewed;
  }

}
