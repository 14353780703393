import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router';

@Component({
  selector: 'app-courses-overview-cta',
  templateUrl: './courses-overview-cta.component.html',
  styleUrls: ['./courses-overview-cta.component.scss']
})
export class CoursesOverviewCtaComponent implements OnInit {

  constructor(private router: Router) { }

  ngOnInit() {
  }

  navigateToSignUp() {
    this.router.navigate(['/login/guest']);
  }
}
