import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-edu-table',
  templateUrl: './edu-table.component.html',
  styleUrls: ['./edu-table.component.scss']
})
export class EduTableComponent implements OnInit {

  @Input() items: Array<any>;
  @Input() columnsToDisplay: Array<string>;
  @Input() columnNames: any;
  @Input() actionButtons: Array<string>;

  @Output() actionClicked: EventEmitter<object> = new EventEmitter<object>();

  constructor() { }

  ngOnInit() {
  }

  onActionClick(type: string, id: number) {
    this.actionClicked.emit({type, id});
  }

}
