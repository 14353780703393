import {of as observableOf} from 'rxjs';
import {Injectable} from '@angular/core';
import {LoggedUserService} from './logged-user.service';
import {environment} from '../../environments/environment';
import {HttpClient, HttpHeaders} from '@angular/common/http';

@Injectable()
export class RequestsService {

  constructor(private http: HttpClient, private loggedUser: LoggedUserService) {
  }

  getApiUrl(url) {
    return `${environment.apiUrl}${url}`;
  }

  loginUser(data) {
    return this.http.post(this.getApiUrl('/user/login'), data);
  }

  logoutUser() {
    return this.http.post(this.getApiUrl('/user/logout'), {});
  }

  registerGuest(_username: string, _data: object) {
    const body = {
      username: _username,
      data: _data
    };
    return this.http.put(this.getApiUrl('/api/guests'), body);
  }

  checkGuest(id: string) {
    return this.http.get(this.getApiUrl(`/api/guests/${id}`), {});
  }

  forgotPassword(data) {
    return this.http.put(this.getApiUrl('/user/forgot'), data);
  }

  submitQuestion(data) {
    return this.http.put(this.getApiUrl('/api/question'), data);
  }

  bindQuestion(data) {
    return this.http.patch(this.getApiUrl('/api/ask_me'), data);
  }

  getPublicVideos(lang) {
    return this.http.get(this.getApiUrl(`/api/public-videos/${lang}`));
  }

  // get_clients(data) {
  //   const options = new HttpHeaders({Authorization: this.loggedUser.getToken()});
  //   options['search'] = data;
  //   return this.http.get(this.getApiUrl('/api/admin/client_users'), options);
  // }

  svc_get(url, data, token = null) {
    return this._svc_call('GET', url, data, token);
  }

  svc_put(url, data, token = null) {
    return this._svc_call('PUT', url, data, token);
  }

  svc_post(url, data, token = null) {
    return this._svc_call('POST', url, data, token);
  }

  svc_patch(url, data, token = null) {
    return this._svc_call('PATCH', url, data, token);
  }

  svc_delete(url, data, token = null) {
    return this._svc_call('DELETE', url, data, token);
  }

  _svc_call(method, url, data, token = null) {
    const options = {};

    if (data) {
      if (method === 'GET' || method === 'DELETE') {
        options['params'] = data;
      }
    }

    if (token) {
      options['headers'] = new HttpHeaders({Authorization: this.loggedUser.getToken()});
    }

    // options.headers = new Headers({Authorization: this.loggedUser.getToken()});

    if (method === 'GET') {
      return this.http.get(this.getApiUrl(url), options);
    }

    if (method === 'PUT') {
      return this.http.put(this.getApiUrl(url), data, options);
    }

    if (method === 'POST') {
      return this.http.post(this.getApiUrl(url), data, options);
    }

    if (method === 'PATCH') {
      return this.http.patch(this.getApiUrl(url), data, options);
    }

    if (method === 'DELETE') {
      return this.http.delete(this.getApiUrl(url), options);
    }

    return observableOf(false);
  }

  postImage(img) {
    return this.http.post(this.getApiUrl('/upl/profile-upload'), {
      upload: img,
      token: this.loggedUser.getToken()
    });
  }
}
