import {Component, OnDestroy, OnInit} from '@angular/core';
import { GuestService } from 'src/app/services/guest.service';
import {LoggedUserService} from '../../services/logged-user.service';
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {FormBuilder} from '@angular/forms';
import {MediatorService} from '../../services/mediator.service';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit, OnDestroy {
  isGuest: boolean = false;
  isUser: boolean = false;

  guestForm = this.fb.group({
    username: [''],
    age: [''],
    city: [''],
    grade: [{value: '', disabled: true}, ]
  });

  destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(public guest: GuestService, public loggedUser: LoggedUserService,
              private fb: FormBuilder, public mediator: MediatorService) { }

  ngOnInit() {
    this.guest.isGuest$.pipe(takeUntil(this.destroy$)).subscribe({
      next: value => this.isGuest = value,
    });
    this.loggedUser.isUserLoggedIn$.pipe(takeUntil(this.destroy$)).subscribe({
      next: value => this.isUser = value,
    });

    this.guestForm.patchValue({
      username: this.guest.userName,
      age: this.guest.age,
      grade: this.mediator.getGradeName(+this.guest.grade).name,
      city: this.guest.city
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  patchGuestInfo() {
    let guestData = this.guestForm.getRawValue();
    guestData = {...guestData, grade: this.mediator.getGradeID(guestData.grade)};

    this.guest.patchGuestInfo(guestData);
  }

}
