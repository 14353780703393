import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'searchAutocompleteValue'})
export class SearchAutocompleteValuePipe implements PipeTransform {
  transform(value: any, item: any, valueParam: any): any {
    let displayValue = '';
    valueParam.forEach(val => {
      if (item.hasOwnProperty(val) && item[val] !== null) {
        displayValue += ' ' + item[val];
      }
    });
    return displayValue;
  }
}
