import { Injectable } from '@angular/core';

@Injectable({providedIn: 'root'})
export class SidenavService {

  constructor() { }

  getLink(dataSource, linkCode: string, linkTitle: string) {
    return dataSource.find(link => link.code === linkCode && link.title === linkTitle) !== undefined;
  }
}
