import {AfterViewInit, Component, OnDestroy, OnInit} from '@angular/core';
import {Subject} from 'rxjs';
import {ActivatedRoute, Router} from '@angular/router';
import {ToastService} from '../../../services/app-toast.service';
import {MediatorService} from '../../../services/mediator.service';
import {TableService} from '../../../services/table.service';
import {TranslationService} from '../../../utils/translate.service';
import {FranchisesService} from '../franchises.service';
import {LoggedUserService} from '../../../services/logged-user.service';
import {mergeMap, takeUntil} from 'rxjs/operators';
import {AddDebtDialogComponent} from '../../../shared/components/dialogs/add-debt-dialog/add-debt-dialog.component';
import {MatDialog, MatDialogConfig} from '@angular/material';
import {OverviewService} from '../../overview/overview.service';
import {environment} from '../../../../environments/environment';

@Component({
  selector: 'app-franchise-list',
  templateUrl: './franchise-list.component.html',
  styleUrls: ['./franchise-list.component.scss'],
  providers: [TableService]
})
export class FranchiseListComponent implements OnInit, AfterViewInit, OnDestroy {
  url = '';
  searchValue = '';
  customParams;
  mouseOverIndex = -1;
  environment = environment;
  displayedColumns = ['image', 'name', 'country', 'regNumber', 'vatNumber', 'studentsNum', 'actions'];

  destroy$: Subject<boolean> = new Subject();

  constructor(
    private router: Router,
    public dialog: MatDialog,
    public table: TableService,
    private toast: ToastService,
    private route: ActivatedRoute,
    public overview: OverviewService,
    public mediator: MediatorService,
    public translate: TranslationService,
    public franchises: FranchisesService,
    private loggedUser: LoggedUserService) {
  }

  ngOnInit() {
    this.table.currentRoute = this.route;
    this.mediator.searchInputStateSubject.next(true);
    this.mediator.searchContextSubject.next({
      shouldEmitText: true,
      hasAutocomplete: false,
      placeholder: 'searchFranchises'
    });
    this.mediator.searchValueSubject.pipe(takeUntil(this.destroy$)).subscribe({
      next: value => this.searchValue = value,
    });

    this.route.parent.parent.parent.params.pipe(takeUntil(this.destroy$)).subscribe(paramData => {
      this.overview.overviewType = this.route.parent.routeConfig.data.hasOwnProperty('overviewType') ?
        this.route.parent.routeConfig.data.overviewType : '';

      let params = {};

      if (this.overview.isOwnOverview && this.loggedUser.isAdmin) {
        this.url = '/api/franchises/my';
        this.displayedColumns = ['image', 'name',
          // 'country',
          'regNumber', 'vatNumber', 'franchisesNum', 'actions'];
      } else if (this.overview.isOwnOverview && this.loggedUser.isFranchisor) {
        this.url = '/api/franchises/my';
        this.displayedColumns = ['image', 'name',
          // 'country',
          'regNumber', 'vatNumber', 'studentsNum', 'actions'];
      } else if (this.overview.isMasterOverview) {
        params = this.route.parent.parent.parent.snapshot.params;
        this.url = `/api/franchises/${+params['master-franchise-id']}`;
        this.displayedColumns = ['image', 'name',
          // 'country',
          'regNumber', 'vatNumber', 'studentsNum', 'actions'];
      }

      if (this.loggedUser.isAdmin && !this.overview.isOwnOverview) {
        this.displayedColumns = ['image', 'name',
          // 'country',
          'regNumber', 'vatNumber', 'studentsNum'];
      }

      this.customParams = {};
      this.table.getData(this.url, {
        responseDataTitle: 'f_children',
        customParams: this.customParams,
        defaultTableParams: this.route.snapshot.queryParams
      });
    });

    this.mediator.searchValue$.pipe(takeUntil(this.destroy$)).subscribe(val => {
      if (val != null) {
        this.table.filter(val);
      }
    });
  }

  ngAfterViewInit() {
    this.table.dataSource.data$.pipe(
      mergeMap(data => {
        if (data != null && data.length > 0) {
          this.table.data = data;
          this.table.isContentVisible = true;
        } else {
          this.table.isContentVisible = false;
        }
        return this.table.dataSource.counter$;
      }),
      mergeMap(count => {
        this.table.setTotal(count);
        return this.table.dataSource.loading$;
      }), takeUntil(this.destroy$)
    ).subscribe(isLoading => {
      // This resolves Error: ExpressionChangedAfterItHasBeenCheckedError: Expression has changed after it was checked
      Promise.resolve(null).then(() => this.table.isLoadingVisible = isLoading);
    }, err => {
    });

    this.table.dataSource.miscData$.pipe(takeUntil(this.destroy$)).subscribe(data => {
      if (data != null) {
        if (data.hasOwnProperty('f_children') && data.f_children.length > 0) {
          this.franchises.childrenFranchises = data.f_children;
        }
        if (data.hasOwnProperty('students') && data.students.length > 0) {
          this.franchises.students = data.students;
        }
      }
    });
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();

    this.mediator.searchInputStateSubject.next(false);
    this.mediator.searchValueSubject.next('');
  }

  rowSelected(data) {
    if (this.overview.isOwnOverview && this.loggedUser.isAdmin) {
      this.router.navigate(['administration', 'master-franchises', data.id, 'franchises']);
    } else if (!this.loggedUser.isAdmin) {
      this.router.navigate(['administration', 'franchises', data.id]);
    } else if (this.overview.isMasterOverview && this.loggedUser.isAdmin) {
      const masterFranchiseId = +this.route.parent.parent.parent.snapshot.params['master-franchise-id'];
      this.router.navigate(['administration', 'master-franchises', masterFranchiseId, 'franchises', data.id]);
    } else if (this.overview.isMasterOverview && !this.loggedUser.isAdmin) {
      this.router.navigate(['administration', 'franchises', data.id]);
    }
  }

  openDialog(e, franchiseId) {
    e.stopPropagation();
    this.dialog.open(AddDebtDialogComponent, {
      data: {id: franchiseId},
      panelClass: 'custom-dialog'
    } as MatDialogConfig)
      .afterClosed().subscribe((confirmed: boolean) => {
      if (confirmed) {
        this.franchises.transactionCompletes$.next(franchiseId);
      }
    });
  }

  onMouseOver(index) {
    this.mouseOverIndex = index;
  }
}
