import {Theme} from '../shared/models/theme.model';

export enum ROLES {
  DEVELOPER = 'DEVELOPER',
  ADMIN = 'ADMIN',
  FRANCHISOR = 'FRANCHISOR',
  INSTRUCTOR = 'INSTRUCTOR',
  STUDENT = 'STUDENT',
  USER = 'USER',
}

export enum ROLE_FLAGS {
  USER = 1,
  STUDENT = 2,
  INSTRUCTOR = 4,
  FRANCHISOR = 8,
  ADMIN = 16,
  DEVELOPER = 32,
}

export class LookupService {
  userRoles = {};
  userRolesRev = {};

  themes: Theme[] = [
    {
      themeClass: 'custom-theme',
      logo: '',
      isLight: true,
      isDark: false,
      primaryLight: '',
      accentLight: '',
      warnLight: ''
    }
  ];

  constructor() {
    this.load_lookups();
  }

  load_lookups() {
    // if (this.lookup_map_script.userRoles) {
    //   for (const r of this.lookup_map_script.userRoles) {
    //     this.userRoles[r['id']] = r['name'];
    //     this.userRolesRev[r['name']] = r['id'];
    //   }
    // }
  }
}
