import { Component, OnInit } from '@angular/core';
import {animate, keyframes, style, transition, trigger} from '@angular/animations';
import {LoggedUserService} from '../../services/logged-user.service';
import {Router} from '@angular/router';
import {GuestService} from '../../services/guest.service';
import {MediatorService} from '../../services/mediator.service';
import {fadeIn} from "../../animations/animations";

@Component({
  selector: 'app-courses-overview',
  templateUrl: './courses-overview.component.html',
  styleUrls: ['./courses-overview.component.scss'],
  animations: [ fadeIn ]
})
export class CoursesOverviewComponent implements OnInit {
  isGuest: boolean = false;
  isUser: boolean = false;

  constructor(private loggedUser: LoggedUserService,
              private guestService: GuestService,
              public mediator: MediatorService,
              private router: Router) {}

  ngOnInit() {
    this.guestService.isGuest$.subscribe({
      next: value => this.isGuest = value,
    });
    this.loggedUser.isUserLoggedIn$.subscribe({
      next: value => this.isUser = value,
    });
  }

}
