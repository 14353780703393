import {BehaviorSubject, Observable, Subscription} from 'rxjs';
import {DataSource} from '@angular/cdk/table';
import {CollectionViewer} from '@angular/cdk/collections';
import {RequestsService} from './requests.service';
import {LoggedUserService} from './logged-user.service';

export class TableDataSource implements DataSource<any> {
  public dataSourceSubject = new BehaviorSubject<any>(null);
  public data$ = this.dataSourceSubject.asObservable();

  public miscDataSubject = new BehaviorSubject<any>(null);
  public miscData$ = this.miscDataSubject.asObservable();

  private loadingSubject = new BehaviorSubject<boolean>(false);
  public loading$ = this.loadingSubject.asObservable();

  private countSubject = new BehaviorSubject<number>(0);
  public counter$ = this.countSubject.asObservable();

  responseDataTitle = '';
  requestSubscription: Subscription;

  constructor(private api: RequestsService,
              private loggedUser: LoggedUserService) {
  }

  connect(collectionViewer: CollectionViewer): Observable<any[]> {
    this.dataSourceSubject = new BehaviorSubject<any>(null);
    this.miscDataSubject = new BehaviorSubject<any>(null);
    this.data$ = this.dataSourceSubject.asObservable();
    this.miscData$ = this.miscDataSubject.asObservable();
    return this.dataSourceSubject.asObservable();
  }

  disconnect(collectionViewer: CollectionViewer): void {
    this.dataSourceSubject.complete();
    this.miscDataSubject.complete();
    if (this.requestSubscription) {
      this.requestSubscription.unsubscribe();
    }
  }

  loadData(url, body = {}, responseDataTitle = 'data') {
    this.loadingSubject.next(true);
    this.responseDataTitle = responseDataTitle;

    this.requestSubscription = this.api.svc_get(url, body, this.loggedUser.getToken()).subscribe((data: any) => {
      this.dataSourceSubject.next(data[this.responseDataTitle]);

      const miscData = {};
      for (const prop in data) {
        if (data.hasOwnProperty(prop) && prop !== data[this.responseDataTitle] && prop !== 'total') {
          miscData[prop] = data[prop];
        }
      }

      if (Object.keys(miscData).length > 0) {
        this.miscDataSubject.next(miscData);
      }
      this.countSubject.next(data.total);
      this.loadingSubject.next(false);
    }, error => {
      this.dataSourceSubject.next([]);
      this.countSubject.next(0);
      this.loadingSubject.next(false);
    });
  }
}
