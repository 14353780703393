import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {SharedModule} from '../../shared/shared.module';

import {StudentsService} from './students.service';

import {StudentsComponent} from './students.component';
import {StudentListComponent} from './student-list/student-list.component';
import {StudentFormComponent} from './student/student-form/student-form.component';

@NgModule({
  declarations: [
    StudentsComponent,
    StudentListComponent,
    StudentFormComponent,
  ],
  imports: [
    CommonModule,
    SharedModule
  ],
  providers: [
    StudentsService
  ],
  exports: [
    StudentsComponent,
    StudentListComponent
  ]
})
export class StudentsModule { }
