import {Component, OnInit} from '@angular/core';
import { fadeIn } from '../../../animations/animations';
import { ProgrammeService } from 'src/app/services/programme.service';
import {Router} from '@angular/router';
import {FaqService} from '../../../services/faq.service';
import {ToastService} from '../../../services/app-toast.service';
import { MatDialog } from '@angular/material/dialog';
import {DeleteProgrammeDialogComponent} from './delete-programme-dialog/delete-programme-dialog.component';
import {DeleteVideoDialogComponent} from './delete-video-dialog/delete-video-dialog.component';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss'],
  animations: [ fadeIn ]
})

export class AdminDashboardComponent implements OnInit {

  items: Array<any> = [];
  columnsToDisplay = [];
  columnNames = {};
  activeTab = 'programmes';

  programmes: Array<any> = [];
  programmeColumns = ['id', 'name', 'id_country', 'grade', 'is_public', 'actions'];
  programmeColumnNames = {
    id_country: 'Zemlja',
    is_public: 'Objavljen',
    actions: 'Promeni/obriši'
  };

  videos: Array<any> = [];
  videoColumns = ['id', 'videoName', 'videoDesc', 'language', 'actions'];
  videoColumnNames = {
    videoName: 'Naziv',
    videoDesc: 'Opis',
    actions: 'Promeni/obriši'
  };

  submittedQuestions: Array<any> = [];
  submittedQuestionsColumns = ['question_to_answer', 'actions'];


  constructor(private courseService: ProgrammeService,
              public dialog: MatDialog,
              private faqService: FaqService,
              private toast: ToastService,
              private router: Router) { }

  ngOnInit() {
    this.getProgrammes();
    this.getVideos();

    this.faqService.getAllSubmittedQuestions().subscribe({
      next: value => {
        this.submittedQuestions = (value as any).questions;
      }
    });
  }

  deleteProgramme(id) {
    this.courseService.deleteProgramme(id).subscribe({
      next: value => {
        this.toast.showInfoToast('Program uspešno obrisan');
        this.getProgrammes();
      }
    });
  }

  deleteVideo(id) {
    this.courseService.deletePublicVideo(id).subscribe({
      next: value => {
        this.toast.showInfoToast('Video uspešno obrisan');
        this.getVideos();
      }
    });
  }

  parseTableAction(event: any) {
    switch (event.type) {
      case 'edit':
        switch (this.activeTab) {
          case 'programmes':
            this.router.navigate([`/learnability/programmes/edit/${event.id}`]); break;
          case 'videos':
            this.router.navigate([`/learnability/public-videos/edit/${event.id}`]); break;
        }
        break;
      case 'delete':
        switch (this.activeTab) {
          case 'programmes':
            const programmeDialogRef = this.dialog.open(DeleteProgrammeDialogComponent, {
              width: '324px',
              data: {name: this.programmes.find(programme => programme.id = event.id).name}
            });

            programmeDialogRef.afterClosed().subscribe(result => {
              if (result && result === true) {
                this.deleteProgramme(event.id);
              }
            });
            break;
          case 'videos':
            const videoDialogRef = this.dialog.open(DeleteVideoDialogComponent, {
              width: '324px',
              data: {name: this.videos.find(video => video.id = event.id).videoName}
            });

            videoDialogRef.afterClosed().subscribe(result => {
              if (result && result === true) {
                this.deleteVideo(event.id);
              }
            });
            break;
        }
        break;
      default: break;
    }
  }

  changeTableItems(type: string) {
    switch (type) {
      case 'programmes':
        this.items = this.programmes;
        this.columnsToDisplay = ['name', 'id_country', 'grade', 'is_public', 'actions'];
        this.activeTab = 'programmes';
        this.columnNames = this.programmeColumnNames;
        break;
      case 'videos':
        this.items = this.videos;
        this.columnsToDisplay = ['videoName', 'videoDesc', 'language', 'actions'];
        this.columnNames = this.videoColumnNames;
        this.activeTab = 'videos';
        break;
      case 'news':
        this.activeTab = 'news';
        break;
      case 'banners':
        this.activeTab = 'banners';
        break;
      case 'questions':
        this.items = this.submittedQuestions;
        this.columnsToDisplay = ['id', 'question_to_answer', 'actions'];
        this.columnNames = {};
        this.activeTab = 'questions';
        break;
    }
  }

  getProgrammes() {
    this.courseService.getAllOnlineProgrammes().subscribe({
      next: (res: any) => {
        this.programmes = res.guests_programs;
        if (this.activeTab === 'programmes') {
          this.items = this.programmes;
          this.columnsToDisplay = ['name', 'id_country', 'grade', 'is_public', 'actions'];
          this.columnNames = this.programmeColumnNames;
        }
      }
    });
  }

  getVideos() {
    this.courseService.getAllAvailablePublicVideos().subscribe({
      next: (res: any) => {
        this.videos = res.videos;
        if (this.activeTab === 'videos') {
          this.items = this.videos;
        }
      }
    });
  }

}
