import {Component, OnDestroy, OnInit} from '@angular/core';
import {LoggedUserService} from '../../services/logged-user.service';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {MediatorService} from '../../services/mediator.service';
import {RequestsService} from '../../services/requests.service';
import {animate, keyframes, style, transition, trigger} from '@angular/animations';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  animations: [
    trigger('fadeIn', [
      transition(':enter',
        animate(200,
          keyframes([
            style({opacity: 0, offset: 0}),
            style({opacity: 0.6, offset: 1})
          ])
        ))
    ])
  ]
})
export class LoginComponent implements OnInit, OnDestroy {
  degreeBack = 14;
  degreeFront = 0;

  destroy$: Subject<boolean> = new Subject();

  constructor(
    public mediator: MediatorService,
    private requests: RequestsService,
    public loggedUser: LoggedUserService
  ) {
  }

  ngOnInit() {
    // if (this.loggedUser.getToken()) {
    //   this.requests.logoutUser().pipe(takeUntil(this.destroy$)).subscribe(res => {
    //     this.loggedUser.logout(res);
    //     this.mediator.isTreeBuilt = false;
    //     this.loggedUser.loginData = {username: '', password: ''};
    //   }, error => {
    //     this.mediator.isTreeBuilt = false;
    //     this.loggedUser.logout(error);
    //   });
    // }
    this.mediator.isTreeBuilt = false;
    this.loggedUser.loginData = {username: '', password: ''};

    this.mediator.loginStepChanges$.pipe(takeUntil(this.destroy$)).subscribe(deg => {
      this.degreeBack = deg.back;
      this.degreeFront = deg.front;
    });
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
}
