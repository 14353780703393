import { Component, OnInit } from '@angular/core';
import {fadeIn} from "../../animations/animations";

@Component({
  selector: 'app-edu-faq',
  templateUrl: './edu-faq.component.html',
  styleUrls: ['./edu-faq.component.scss'],
  animations: [fadeIn]
})
export class EduFaqComponent implements OnInit {

  public activeTab: number = 1;
  public activeQuestion;

  constructor() { }

  ngOnInit() {
  }

  makeActive(tab) {
    this.activeTab = tab;
  }

  expandQuestion(question) {
    this.activeQuestion = question;
  }

}
