import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';

@Component({
  selector: 'app-profit-and-loss-report',
  templateUrl: './profit-and-loss-report.component.html',
  styleUrls: ['./profit-and-loss-report.component.scss']
})
export class ProfitAndLossReportComponent implements OnInit, OnChanges, OnDestroy {
  @Input() paymentData: any[] = [];

  constructor() { }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.paymentData && !changes.paymentData.firstChange) {
      this.paymentData = changes.paymentData.currentValue;
    }
  }

  ngOnDestroy() {
    this.paymentData = [];
  }
}
