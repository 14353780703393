import {Injectable} from '@angular/core';
import {LookupService, ROLES} from '../utils/lookup.service';
import {environment} from '../../environments/environment';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {BehaviorSubject} from 'rxjs';

export class User {
  public id: string;
  public franchises: any[];
  public username: string;
  public email: string;
  public firstName: string;
  public lastName: string;
  public fax: string;
  public province: string;
  public homepage: string;
  public place: string;
  public telephone: string;
  public region: string;
  public roleFlags: number;
  public profilePhoto: string;
  public coverPhoto: string;

  set_data(data) {
    this.username = data.username;
    this.firstName = data.first_name;
    this.lastName = data.last_name;
    this.email = data.email || data.username;
    this.fax = data.fax;
    this.province = data.province;
    this.homepage = data.homepage;
    this.place = data.place;
    this.region = data.region;
    this.telephone = data.telephone;
    this.roleFlags = data.role_flags;

    this.coverPhoto = data.cover_photo || '/assets/img/default-user-cover.png';
    this.profilePhoto = data.profile_photo || '/assets/img/default-user-profile.jpg';

    // SET DUMMY PICTURE FOR NON PRODUCTION INSTALLATION
    if (this.profilePhoto && !environment.production) {
      // this.profilePhoto = '/assets/img/profile-image.png';
    }
    if (this.coverPhoto && !environment.production) {
      // this.coverPhoto = '/assets/img/default-user-cover.png';
    }
  }

  setId(id) {
    this.id = id;
  }

  setFranchises(franchises) {
    this.franchises = franchises;
  }
}

@Injectable()
export class LoggedUserService extends User {
  wasLogged = false;
  loggedUser = true;
  alreadyLogged = false;
  private token: string;
  private tokenName = 'token';
  defaultAvatar = '/storage/profile/default-avatar-img.png';

  isAdmin = false;
  isMaster = false;
  isFranchisor = false;
  isInstructor = false;
  isStudent = false;
  isUser = false;

  isUserLoggedIn$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  isLearnabilityAdmin$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  loginData: {username: string, password: string} = {username: '', password: ''};

  constructor(private http: HttpClient,
              private lookup: LookupService) {
    super();
    // let cookieToken;
    // if (document.cookie !== '' && document.cookie.indexOf('token') !== -1) {
    //   cookieToken = document.cookie.split('; ').find(row => row.startsWith('token')).split('=')[1];
    // } else {
    //   cookieToken = null;
    // }
    // this.token = cookieToken ? cookieToken : localStorage.getItem(this.tokenName);
    if (document.cookie !== '' && document.cookie.indexOf('token') !== -1) {
      this.http.get(environment.apiUrl + '/user/login').subscribe({
        next: value => {
          this.lookup.userRoles = (value as any).roles;
          this.login(value);
        },
      });
    }
  }

  login(resp) {
    if (resp.token) {
      this.setToken(resp.token);
    }
    this.set_data(resp);
    this.isUserLoggedIn$.next(true);
  }

  logout(resp) {
    this.lookup.userRoles = {};
    this.removeToken();
    this.setRole();
    this.isLearnabilityAdmin$.next(false);

    this.isUserLoggedIn$.next(false);
  }

  checkUser() {
    return this.http.get(environment.apiUrl + '/user/login');
  }

  setToken(token) {
    this.token = token;
    localStorage.setItem(this.tokenName, token);
    // document.cookie = `token=${token};path=/;domain=${environment.cookieDomain}`;
  }

  getToken() {
    return this.token;
  }

  removeToken() {
    delete this.token;
    localStorage.removeItem(this.tokenName);
    // document.cookie = `token='';path=/;domain=${environment.cookieDomain};expires=Thu, 01 Jan 1970 00:00:00 UTC;`;
  }

  set_data(data) {

    if (data.id) {
      super.setId(data.id);
    }
    if (data.id_user) {
      super.setId(data.id_user);
    }

    if (data.my_franchises) {
      super.setFranchises(data.my_franchises);
    }

    this.isMaster = data.is_master;
    super.set_data(data);

    this.alreadyLogged = data.already_logged;

    this.setRole();
    this.wasLogged = true;
  }

  setRole() {
    // tslint:disable:no-bitwise
    this.isAdmin = !!(this.roleFlags & this.lookup.userRoles[ROLES.ADMIN]);
    this.isFranchisor = !!(this.roleFlags & this.lookup.userRoles[ROLES.FRANCHISOR]);
    this.isInstructor = !!(this.roleFlags & this.lookup.userRoles[ROLES.INSTRUCTOR]);
    this.isStudent = !!(this.roleFlags & this.lookup.userRoles[ROLES.STUDENT]);
    this.isUser = !!(this.roleFlags & this.lookup.userRoles[ROLES.USER]);

    if (this.isAdmin) {
      this.isLearnabilityAdmin$.next(true);
    }

  }

  getInitRoute() {
    if (this.alreadyLogged) {
      return [''];
    } else {
      return ['first-login'];
    }
  }

  setProfilePicture(picture) {
    this.profilePhoto = picture;
    if (this.profilePhoto && !environment.production && !environment.staging) {
      this.profilePhoto = 'dummy_pic.jpg';
    }
  }
}
