import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';


@Pipe({ name: 'start' })
export class StartPipe implements PipeTransform {
  transform(value: Date|moment.Moment): string {
    return moment(value).locale('en').format('dddd, MMMM Do YYYY');
  }
}
