import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';


@Pipe({ name: 'fromNow' })
export class FromNowPipe implements PipeTransform {
  transform(value: Date|moment.Moment, unit?: moment.DurationInputArg2): string {
    return moment(value).locale('en').startOf(unit).fromNow();
  }
}
