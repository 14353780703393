// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  staging: true,
  apiUrl: '',
  staticUrl: 'https://fransize.dev.digitalcube.rs/static',
  domainName: window.location.hostname,
  cookieDomain: 'digitalcube.rs',
  imagesUrl: '/assets/img/',
  uploadImagesUrl: '/assets/upload-images/',
  url: `${window.location.hostname}`,    // websocket url
  urlOrigin: window.location.origin,    // websocket url
  maxUploadFileSize: 100 * 1048576,   // max file size for upload - 100MB
  maxMobWidth: 768,
  minMobWidth: 416,
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
 import 'zone.js/dist/zone-error';  // Included with Angular CLI.
