import {Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {AbstractControl, FormBuilder, FormControl, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';
import * as moment from 'moment';
import {StudentsService} from '../../students.service';
import {ToastService} from '../../../../services/app-toast.service';
import {TranslationService} from '../../../../utils/translate.service';
import {RequestsService} from '../../../../services/requests.service';
import {LoggedUserService} from '../../../../services/logged-user.service';
import {environment} from '../../../../../environments/environment';
import {CropperService} from '../../../../services/cropper.service';
import {MatDialog, MatOptionSelectionChange, MatSnackBar} from '@angular/material';
import {animate, keyframes, style, transition, trigger} from '@angular/animations';
import {MediatorService} from '../../../../services/mediator.service';
import {FranchisesService} from '../../../franchises/franchises.service';

@Component({
  selector: 'app-student-form',
  templateUrl: './student-form.component.html',
  styleUrls: ['./student-form.component.scss'],
  // providers: [StudentsService],
  animations: [
    trigger('fadeIn', [
      transition(':enter',
        animate(200,
          keyframes([
            style({opacity: 0, offset: 0}),
            style({opacity: 0.6, offset: 1})
          ])
        ))
    ])
  ]
})
export class StudentFormComponent implements OnInit, OnDestroy {
  environment = environment;
  currencies = ['rsd', 'hrk', 'bam', 'eur'];
  groups = [];
  minDate = new Date(2018, 0, 1);
  maxDate = new Date(2025, 0, 1);
  @ViewChild('uploadTrigger', {static: true}) fileUploader: ElementRef;

  lastDebtDate = localStorage.getItem('lastDebtDate') ?
    moment(localStorage.getItem('lastDebtDate')).toISOString() : moment().toISOString();

  inEditMode: boolean = false;

  chosenPhotoName = '';
  studentForm;

  destroy$: Subject<boolean> = new Subject();

  constructor(
    private router: Router,
    private fb: FormBuilder,
    public dialog: MatDialog,
    private toast: ToastService,
    public snackbar: MatSnackBar,
    private route: ActivatedRoute,
    public cropper: CropperService,
    public mediator: MediatorService,
    private requests: RequestsService,
    private students: StudentsService,
    public franchises: FranchisesService,
    public loggedUser: LoggedUserService,
    private translate: TranslationService,
  ) {
    this.route.data.subscribe({
      next: value => {
        if (value && value.mode && value.mode === 'edit') {
          this.inEditMode = true;
        }
      }
    });
  }

  get startDate() {
    return this.studentForm.get('start_date');
  }

  get firstName() {
    return this.studentForm.get('first_name') as FormControl;
  }

  get lastName() {
    return this.studentForm.get('last_name') as FormControl;
  }

  get username() {
    return this.studentForm.get('username') as FormControl;
  }

  get group() {
    return this.studentForm.get('id_group') as FormControl;
  }

  ngOnInit() {
    this.requests.svc_get(`/api/franchise/${this.franchises.franchiseData.id}/groups`, {}, this.loggedUser.getToken())
      .pipe(takeUntil(this.destroy$)).subscribe((resp: any) => {
      this.groups = resp.groups;
      if (!this.groups || this.groups.length === 0) {
        this.toast.showInfoToast('noGroupStudentAdd');
        this.router.navigate(['../'], {relativeTo: this.route});
      }

      this.studentForm = this.fb.group({
        id_group: this.fb.control(this.route.parent.snapshot.paramMap.get('group-id') ?
          +this.route.parent.snapshot.paramMap.get('group-id') : null, Validators.required),
        first_name: this.fb.control(null, [Validators.required, Validators.pattern('[A-Za-zĆćČčĐđŽžŠš ]+')]),
        last_name: this.fb.control(null, [Validators.required, Validators.pattern('[A-Za-zĆćČčĐđŽžŠš ]+')]),
        username: this.fb.control({value: null, disabled: true}, [Validators.required]),
        email: this.fb.control(null, []),
        gender: this.fb.control(null, Validators.required),
        start_date: this.fb.control({value: this.lastDebtDate, disabled: true}, Validators.required),
        first_part_amount: this.fb.control(0, Validators.required),
        part_amount: this.fb.control(null, [Validators.required, this.installmentValidator]),
        total_amount: this.fb.control(null, Validators.required),
        initially_paid: this.fb.control(0),
        currency: this.fb.control({value: this.franchises.franchiseData.currency.id, disabled: true}, Validators.required)
      });

      if (this.inEditMode) {
        this.studentForm.patchValue(this.students.studentData);
      }

      this.listenForInputChanges();
    }, err => {
      this.router.navigate(['../'], {relativeTo: this.route});
      this.toast.showError();
    });
  }

  ngOnDestroy() {
    this.mediator.isProgressBarVisible = false;
    this.cropper.resetCropper();
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  installmentValidator(control: AbstractControl): { [key: string]: boolean } | null {
    if (control.value == 0) {
      return {zeroInstallment: true};
    }
    return null;
  }

  onGroupChange(ev: MatOptionSelectionChange, index) {
    if (ev.isUserInput) {
      this.studentForm.get('part_amount').setValue(this.groups[index].price);
      this.studentForm.get('total_amount').setValue(this.groups[index].price);
      this.studentForm.get('first_part_amount').setValue(this.groups[index].price);

      this.startDate.setValue(this.groups[index].start);

      if (
        this.firstName.value && this.firstName.value.length > 0 &&
        this.lastName.value && this.lastName.value.length > 0 &&
        this.group.value
      ) {
        this.username.setValue(`${this.firstName.value}.${this.lastName.value}.${ev.source.value}`);
      } else {
        this.username.reset();
      }
    }
  }

  processName(name: string): string {
    return name.trim().split(' ').join('.').replace(/\.+/g, '.');
  }

  listenForInputChanges() {
    this.firstName.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(val => {
      if (val && val.length > 0 && this.lastName.value && this.lastName.value.length > 0 && this.group.value) {
        this.username.setValue(`${this.processName(val)}.${this.processName(this.lastName.value)}.${this.group.value}`);
      } else {
        this.username.reset();
      }
    });

    this.lastName.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(val => {
      if (val && val.length > 0 && this.firstName.value && this.firstName.value.length > 0 && this.group.value) {
        this.username.setValue(`${this.processName(this.firstName.value)}.${this.processName(val)}.${this.group.value}`);
      } else {
        this.username.reset();
      }
    });

    this.group.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(val => {
      if (val && val.length > 0 && this.firstName.value && this.firstName.value.length > 0 && this.group.value) {
        this.username.setValue(`${this.processName(this.firstName.value)}.${this.processName(val)}.${this.group.value}`);
      } else {
        this.username.reset();
      }
    });
  }

  fileChanged(event) {
    this.cropper.fileChangeEvent(event);
    const uploadTrigger: any = document.getElementById('uploadTrigger');

    if (this.cropper.isCropperVisible) {
      this.cropper.openDialog({
        type: 'profile',
        cropper: this.cropper,
      }).pipe(takeUntil(this.destroy$)).subscribe((confirmed: boolean) => {
        if (confirmed) {
          this.cropper.isDialogOpen = false;
          this.cropper.isCropperVisible = false;
          this.cropper.chosenImage = this.cropper.croppedImage;
          this.requests.svc_post('/api/upload-image', {
            target_name: 'new-user',
            image: this.cropper.croppedImage,
          }, this.loggedUser.getToken()).pipe(takeUntil(this.destroy$)).subscribe((resp: any) => {
            this.chosenPhotoName = resp.fname;
          });
        } else {
          this.cropper.cancelCrop();
        }
        if (uploadTrigger) {
          uploadTrigger.value = '';
        }
      });
    } else {
      if (uploadTrigger) {
        uploadTrigger.value = '';
      }
    }
  }

  submit() {
    const numberOfInstallments =
      ((+this.studentForm.get('total_amount').value) - (+this.studentForm.get('initially_paid').value)) /
      (+this.studentForm.get('part_amount').value);
    const tooManyInstallments: boolean = (numberOfInstallments > 20);

    if (tooManyInstallments) {
      this.toast.showInfoToast(this.translate.instant('tooManyInstallments'));
    } else if (!this.studentForm.valid) {
      this.toast.showInfoToast(this.translate.instant('enterValidData'));
    } else {
      this.mediator.isProgressBarVisible = true;
      const email = this.studentForm.get('email').value;
      const data = {
        ...this.studentForm.getRawValue(),
        first_name: this.studentForm.get('first_name').value.trim(),
        last_name: this.studentForm.get('last_name').value.trim(),
        email: email && email.length > 0 ? email : null,
        profile_filename: this.chosenPhotoName,
        start_date: moment(this.startDate.value).format('YYYY-MM-DD'),
      };

      delete data.currency;

      const url = !this.inEditMode ? `/api/franchise/${this.franchises.franchiseData.id}/student` :
        `/api/students/${this.students.studentData.id}/edit`;

      this.requests.svc_put(url, data, this.loggedUser.getToken())
        .pipe(takeUntil(this.destroy$)).subscribe(resp => {
        localStorage.setItem('lastDebtDate', this.startDate.value);
        this.resetForm();
        this.mediator.isProgressBarVisible = false;
        this.inEditMode ? this.franchises.stateChange$.next('studentEdited') : this.franchises.stateChange$.next('studentAdded');
        this.snackbar.open(this.translate.instant(
          `${this.inEditMode ? 'studentSuccessfullyEdited' : 'studentSuccessfullyAdded'}`
        ), null, {duration: 1500}).afterDismissed().subscribe(
          () => {
            this.mediator.isProgressBarVisible = false;
            if (this.inEditMode) {
              this.router.navigate(['../'], {relativeTo: this.route});
            }
          }
        );
      }, err => {
        this.toast.showError();
        this.mediator.isProgressBarVisible = false;
      });
    }
  }

  resetForm() {
    this.studentForm.get('first_name').reset();
    this.studentForm.get('last_name').reset();
    this.studentForm.get('username').reset();
    this.studentForm.get('email').reset();
    this.studentForm.get('gender').reset();
  }

  routeBack() {
    this.inEditMode ? this.franchises.stateChange$.next('studentEdited') : this.franchises.stateChange$.next('studentAdded');
    this.router.navigate(['../'], {relativeTo: this.route});
  }
}
