import {Injectable} from '@angular/core';
import {ActivatedRoute, ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';
import {FranchiseType} from '../pages/franchises/franchises.service';
import {LoggedUserService} from '../services/logged-user.service';

@Injectable()
export class PermissionGuardService implements CanActivate, CanActivateChild {
  constructor(private loggedUser: LoggedUserService, private router: Router, private route: ActivatedRoute) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Promise<boolean> | Observable<boolean> {
    return new Promise((resolve) => {
      resolve(true);
    });
  }

  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.canActivate(route, state);
  }
}

