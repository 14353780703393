import {FranchisesService, FranchiseType, OverviewType} from './franchises.service';
import {catchError, tap} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {ActivatedRoute, ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot} from '@angular/router';
import {RequestsService} from '../../services/requests.service';
import {LoggedUserService} from '../../services/logged-user.service';
import {Observable} from 'rxjs';

@Injectable({providedIn: 'root'})
export class FranchiseResolver implements Resolve<any> {
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private requests: RequestsService,
    private loggedUser: LoggedUserService,
    private franchises: FranchisesService,
  ) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
    // this.loader.loading$.next(true);
    let id;
    const isMaster = route.routeConfig.path !== 'students' && ('master-franchise-id' in route.params ||
      (this.loggedUser.isFranchisor && !this.loggedUser.isAdmin && route.routeConfig.path === FranchiseType.BASIC));

    if ('franchise-id' in route.params) {
      id = route.params['franchise-id'];
    } else if (isMaster && 'master-franchise-id' in route.params) {
      id = route.params['master-franchise-id'];
    } else if (this.loggedUser.isInstructor && !this.loggedUser.isFranchisor) {
      id = this.loggedUser.franchises[0].id;
    } else {
      id = 'my';
    }

    return this.requests.svc_get(`/api/franchises/${id}`, {}, this.loggedUser.getToken()).pipe(
      tap((data: any) => {
        if (isMaster) {
          this.franchises.masterFranchiseData = data;
          this.franchises.currentFranchise = data;
        } else {
          this.franchises.franchiseData = data;
          this.franchises.franchiseGroups = data.groups;
          this.franchises.currentFranchise = data;
        }
      },
      ),
      catchError(err => {
        // this.loader.loading$.next(false);
        return this.router.navigate(['../'], {relativeTo: this.route});
      }));
  }
}
