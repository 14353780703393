import { NgModule } from '@angular/core';
import {Routes, RouterModule, ExtraOptions} from '@angular/router';
import { CoursesOverviewComponent } from './courses-overview/courses-overview.component';
import { EduContainerComponent } from './edu-container/edu-container.component';
import { CoursesGuard } from '../guards/courses.guard';
import { CourseContainerComponent } from './course/course-container/course-container.component';
import { ProfileComponent } from './profile/profile.component';
import {CoursesOverviewFaqComponent} from './courses-overview/courses-overview-faq/courses-overview-faq.component';
import {AdminDashboardComponent} from './dashboard/admin/admin.component';
import {AdminLearnabilityGuard} from '../guards/admin-learnability-guard.guard';
import {NotFoundComponent} from '../not-found/not-found.component';
import {PublicVideosPlaceholderComponent} from './public-videos/public-videos-placeholder/public-videos-placeholder.component';
import {PublicVideoFormComponent} from './public-videos/public-video-form/public-video-form.component';
import {AdminPlaceholderComponent} from "./admin/admin-placeholder/admin-placeholder.component";
import {ProgrammeFormComponent} from "./admin/programme-form/programme-form.component";
import {BannerPageComponent} from "./banner-page/banner-page.component";
import {QuestionsComponent} from "./questions/questions.component";
import {EduFaqComponent} from "./edu-faq/edu-faq.component";

const routes: Routes = [
  {
    path: 'learnability', component: EduContainerComponent,
    canActivate: [CoursesGuard],
      children: [
        {path: '', component: CoursesOverviewComponent, pathMatch: 'prefix'},
        {path: 'banner', component: BannerPageComponent},
        {path: 'profile', component: ProfileComponent },
        {path: 'faq', component: EduFaqComponent},
        {path: 'faq/add', component: QuestionsComponent, canActivate: [AdminLearnabilityGuard]},
        {path: 'programme/:id', component: CourseContainerComponent, pathMatch: 'full'},
        {path: 'programme/:id/:lesson', component: CourseContainerComponent, pathMatch: 'prefix'},
        {path: 'dashboard/admin', component: AdminDashboardComponent, canActivate: [AdminLearnabilityGuard]},
        {path: 'public-videos', component: AdminPlaceholderComponent, canActivate: [AdminLearnabilityGuard], children: [
            {path: '', component: PublicVideoFormComponent},
            {path: 'add', component: PublicVideoFormComponent},
            {path: 'edit/:id', component: PublicVideoFormComponent}
          ]
        },
        {path: 'programmes', component: AdminPlaceholderComponent, canActivate: [AdminLearnabilityGuard], children: [
            {path: 'add', component: ProgrammeFormComponent},
            {path: 'edit/:id', component: ProgrammeFormComponent}
          ]
        },
        {path: 'not-found', component: NotFoundComponent, data: {message: 'Page not found!'}},
        {path: '**', redirectTo: '/not-found'}
      ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class EduRoutingModule { }
