import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-lessons-container',
  template: `
      <app-lessons>
          <h4 class="lessons-title">Lessons</h4>
      </app-lessons>
  `,
  styles: []
})
export class LessonsContainerComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
