import {MatSnackBar} from '@angular/material';
import {Injectable} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';

@Injectable()
export class ToastService {

  constructor(private snackBar: MatSnackBar, private translate: TranslateService) {
  }

  showInfoToast(msg: string) {
    setTimeout(() => {
      this.snackBar.open(this.translate.instant(msg), null, {duration: 3000});
    });
  }

  showError(message = 'errorOccured') {
    setTimeout(() => this.snackBar.open(this.translate.instant(message), null, {duration: 3000}));
  }
}




