import {Component, OnDestroy, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Subject} from 'rxjs';
import {Router} from '@angular/router';
import {MediatorService} from '../../../services/mediator.service';
import {TranslationService} from '../../../utils/translate.service';
import {ToastService} from '../../../services/app-toast.service';
import {GuestService} from '../../../services/guest.service';
import {takeUntil} from 'rxjs/operators';
import {fadeIn, fadeOutForm, inOutAnimation} from '../../../animations/animations';
import {FaqService} from '../../../services/faq.service';
import {MatDialog} from '@angular/material';
import {FaqSignupPopupComponent} from './faq-signup-popup/faq-signup-popup.component';

@Component({
  selector: 'app-guest-login-new',
  templateUrl: './guest-login-new.component.html',
  styleUrls: ['./guest-login-new.component.scss'],
  animations: [
    fadeIn, fadeOutForm, inOutAnimation
  ]
})

export class GuestLoginNewComponent implements OnInit, OnDestroy {
  formGroupStep1: FormGroup;
  formGroupStep2: FormGroup;
  formGroupStep3: FormGroup;
  formGroupStep4: FormGroup;
  formGroupStep5: FormGroup;

  step: number = 1;
  isEditable: boolean = true;
  signUpWithQuestion: boolean = false;

  isFormVisible: boolean = true;

  destroy$: Subject<boolean> = new Subject();

  grades = [];
  years =  [ ...Array(120).keys() ].map( i => new Date().getFullYear() - i);

  objectKeys = Object.keys;
  languages: object = { Srbija: 'srpski', Hrvatska: 'hrvatski', 'Bosna i Hercegovina': ['bosnjacki', 'hrvatski', 'srpski'],
    'Crna Gora': 'crnogorski', Slovenija: 'slovenski' };
  languageCodes: object = { srpski: 'RS', hrvatski: 'HR', bosnjacki: 'BA', crnogorski: 'ME', slovenski: 'SI' };
  countryCodes: object = { Srbija: 'srb', Hrvatska: 'cro', 'Bosna i Hercegovina': 'bih', Slovenija: 'slo', 'Crna Gora': 'cg' };
  selectedCountry: string;
  shouldChooseLanguage: boolean = false;
  isCountryLanguageChosen: boolean = false;

  constructor(private fb: FormBuilder,
              private router: Router,
              public mediator: MediatorService,
              private translate: TranslationService,
              private toast: ToastService,
              private guest: GuestService,
              private dialog: MatDialog,
              private faqService: FaqService) {}

  ngOnInit() {
    this.grades = this.mediator.getGrades();

    if (this.mediator.questionWithoutGuestId) {
      this.signUpWithQuestion = true;
      const dialogRef = this.dialog.open(FaqSignupPopupComponent, {
        width: '324px',
      });

      dialogRef.afterClosed().subscribe(result => {
        this.mediator.questionWithoutGuestId = false;
      });
    }

    const lettersPattern = '[a-zA-ZčćžšžŽŠĆČ ]*';

    this.formGroupStep1 = this.fb.group({
      country: [null, Validators.required],
    });
    this.formGroupStep2 = this.fb.group({
      city: [null, [Validators.required, Validators.pattern(lettersPattern), Validators.minLength(2)]],
    });
    this.formGroupStep3 = this.fb.group({
      age: ['', [Validators.required, Validators.min(0), Validators.max(150)]],
      grade: ['', [Validators.required, Validators.min(1), Validators.max(20)]],
    });
    this.formGroupStep4 = this.fb.group({
      fullName: ['', [Validators.required, Validators.pattern(lettersPattern), Validators.minLength(2)]],
    });
    this.formGroupStep5 = this.fb.group({
      email: [null, [Validators.required, Validators.email]]
    });
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  onCountrySelect(event) {
    this.selectedCountry = event.target.value;
    if (!(this.languages[event.target.value] instanceof Array)) {
      this.shouldChooseLanguage = false;
      this.isCountryLanguageChosen = true;
      this.changeLanguage(this.languageCodes[this.languages[event.target.value]]);
    } else {
      this.changeLanguage('EN');
      this.shouldChooseLanguage = true;
      this.isCountryLanguageChosen = false;
    }
  }

  onLanguageSelect(event) {
    this.changeLanguage(this.languageCodes[event.target.value]);
    this.isCountryLanguageChosen = true;
  }

  onAgeSelect(event) {
    this.formGroupStep3.patchValue({
      age: new Date().getFullYear() - event.target.value,
    });
  }

  onGradeSelect(event) {
    this.formGroupStep3.patchValue({
      grade: event.target.value,
    });
  }

  changeLanguage(lang) {
    this.translate.setLang(lang);
    localStorage.setItem('lang', lang);
  }

  changeFormStep(step) {
    this.isFormVisible = false;
    setTimeout(() => { this.step = step; this.isFormVisible = true; }, 400);
  }

  onNextStep() {
    switch (this.step) {
      case 1:
        if (this.formGroupStep1.valid && this.isCountryLanguageChosen && this.formGroupStep2.valid) {
          this.changeFormStep(this.step + 1);
        } else {
          if (!this.formGroupStep1.valid && this.isCountryLanguageChosen) {
            this.toast.showInfoToast(this.translate.instant('chooseCountry'));
          } else {
            this.toast.showInfoToast(this.translate.instant('chooseLanguage'));
          }
          if (!this.formGroupStep2.valid) {
            this.toast.showInfoToast(this.translate.instant('enterValidCity'));
          }
        }
        break;
      case 2:
        if (this.formGroupStep3.valid) {
          this.changeFormStep(this.step + 1);
        } else {
          this.toast.showInfoToast(this.translate.instant('enterValidAgeGrade'));
        }
        break;
      case 3:
        if (this.formGroupStep4.valid && this.formGroupStep5.valid) {
          if (this.formGroupStep4.get('fullName').value.split(' ', 2).length !== 2) {
            this.toast.showInfoToast(this.translate.instant('enterValidFullName'));
          } else {
            this.registerGuest();
            this.isFormVisible = false;
          }
        } else {
          if (!this.formGroupStep4.valid) {
            this.toast.showInfoToast(this.translate.instant('enterValidFullName'));
          } else {
            this.toast.showInfoToast(this.translate.instant('enterValidEmail'));
          }
        }
        break;
    }
  }

  onPreviousStep() {
    this.changeFormStep( this.step - 1 );
  }

  registerGuest() {

    const body = {
      first_name: this.formGroupStep4.get('fullName').value.split(' ', 2)[0],
      last_name: this.formGroupStep4.get('fullName').value.split(' ', 2)[1],
      age: this.formGroupStep3.get('age').value,
      grade: this.formGroupStep3.get('grade').value,
      country: this.countryCodes[this.formGroupStep1.get('country').value],
      city: this.formGroupStep2.get('city').value
    };

    this.guest.registerGuest(this.formGroupStep5.get('email').value, body)
      .pipe(takeUntil(this.destroy$))
      .subscribe((resp: any) => {
        this.guest.setGuest(resp.guest);
        this.guest.checkGuest().subscribe(r => {
          this.guest.loginGuest();
          this.faqService.bindQuestion();
          this.isFormVisible = false;
          setTimeout(() => this.router.navigate(['learnability']), 500);
          this.toast.showInfoToast(this.translate.instant('registrationSuccessful'));
        });
      }, err => {
        if (err.hasOwnProperty('error') && err.error.id_error === 20) {
          this.toast.showInfoToast(this.translate.instant('enterValidData'));
        } else {
          this.toast.showInfoToast(this.translate.instant('errorOccurred'));
        }
      });
  }
}
