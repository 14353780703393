import { Injectable } from '@angular/core';
import {CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router, ActivatedRoute} from '@angular/router';
import { Observable } from 'rxjs';
import {FranchiseType} from '../pages/franchises/franchises.service';
import {LoggedUserService} from '../services/logged-user.service';

@Injectable({
  providedIn: 'root'
})
export class AdminLearnabilityGuard implements CanActivate {
  constructor(private loggedUser: LoggedUserService, private router: Router, private route: ActivatedRoute) {
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return new Promise((resolve) => {
      if (this.loggedUser.isAdmin) {
        resolve(true);
      } else {
        resolve(false);
        this.router.navigate(['/learnability']);
      }
    });
  }

}
