export var tr_map  = {
    "saveAs": "srpski",
    "welcome": "Dobro došli",
    "enterEmailToContinue": "Da biste nastavili dalje, upišite svoj e-mail",
    "enterUsernameToContinue": "Da biste nastavili dalje, upišite svoje korisničko ime",
    "enterValidEmail": "Unesite ispravan e-mail",
    "forgotEmail": "Zaboravili ste svoj e-mail",
    "next": "Dalje",
    "enterEmail": "Unesite svoj e-mail",
    "enterUsername": "Unesite svoje korisničko ime",
    "email": "E-mail",
    "username": "Korisničko ime",
    "enterPassword": "Unesite lozinku",
    "password": "Lozinka",
    "forgotPassword": "Zaboravili ste svoju lozinku",
    "enterValidData": "Unesite ispravne podatke",
    "administration": "Administracija",
    "masterFranchises": "Master-franšize",
    "franchises": "Franšize",
    "students": "Polaznici",
    "homePage": "Početna",
    "notifications": "Notifikacije",
    "profile": "Profil",
    "logout": "Odjavi se",
    "revenue": "Prihodi",
    "expenditures": "Rashodi",
    "firstName": "Ime",
    "lastName": "Prezime",
    "country": "Država",
    "city": "Grad",
    "establishmentDate": "Datum osnivanja",
    "franchiseOwner": "Nosilac franšize",
    "companyNumber": "Registracioni broj",
    "taxNumber": "PIB",
    "franchiseNumber": "Broj franšiza",
    "studentsNumber": "Broj polaznika",
    "addPayment": "Dodaj uplatu",
    "addStudent": "Dodaj polaznika",
    "addInstructor": "Dodaj instruktora",
    "addFranchise": "Dodaj franšizu",
    "noDataRecords": "Nije pronađen nijedan rezultat",
    "debitDate": "Datum zaduženja",
    "paymentDate": "Datum uplate",
    "description": "Opis",
    "debitBalanceFor": "Saldo zaduženja na dan",
    "debitBalance": "Saldo zaduženja",
    "paymentsBalanceFor": "Saldo uplata na dan",
    "paymentsBalance": "Saldo uplata",
    "totalFor": "Ukupan saldo na dan",
    "total": "Ukupno",
    "cancel": "Odustani",
    "save": "Snimi",
    "newFranchise": "Nova franšiza",
    "chooseDate": "Izaberite datum",
    "territory": "Teritorija",
    "existingFranchisor": "Postojeći franšizer",
    "newFranchisor": "Novi franšizer",
    "chooseFranchiseOwner": "Izaberite nosioca franšize",
    "errorOccurred": "Došlo je do greške",
    "info": "Info",
    "instructor": "Instruktor",
    "firstLastName": "Ime i prezime",
    "group": "Grupa",
    "payments": "Plaćanja",
    "dateOfBirth": "Datum rođenja",
    "birthplace": "Mesto rođenja",
    "identityNumber": "JMBG",
    "searchStudents": "Pretraži polaznike",
    "addingPayment": "Dodavanje uplate",
    "addingStudent": "Dodavanje polaznika",
    "addingFranchise": "Dodavanje franšize",
    "amount": "Iznos",
    "add": "Dodaj",
    "gender": "Pol",
    "newStudent": "Novi polaznik",
    "currency": "Valuta",
    "maleGender": "Muški",
    "femaleGender": "Ženski",
    "firstDebitDate": "Datum prvog zaduženja",
    "sumDebit": "Ukupno zaduženje",
    "initiallyPaid": "Inicijalno plaćeno",
    "firstInstallmentAmount": "Iznos prve rate",
    "installmentAmount": "Iznos rate",
    "profilePhoto": "Profilna slika",
    "choosePhoto": "Izaberite fotografiju",
    "successfullyAdded": "Uspešno dodato",
    "close": "Zatvori",
    "open": "Otvori",
    "serbian": "srpski",
    "bosnian": "bosanski",
    "montenegrian": "crnogorski",
    "croatian": "hrvatski",
    "slovenian": "slovenački",
    "english": "engleski",
    "franchiseOverview": "Pregled franšize",
    "studentOverview": "Pregled polaznika",
    "franchisorsAndInstructors": "Franšizeri i instruktori",
    "general": "Opšte",
    "status": "Status",
    "name": "Naziv",
    "debit": "Zaduženje",
    "fieldRequired": "Polje je obavezno",
    "isInstructor": "Da li je instruktor",
    "franchiseSuccessfullyAdded": "Franšiza uspesno dodata",
    "studentSuccessfullyAdded": "Polaznik uspešno dodat",
    "paidTooMuch": "Uplata prevazilazi predviđenu sumu",
    "successfullyPaid": "Uspešno uplaćeno",
    "passwordsNotMatch": "Lozinke se ne poklapaju",
    "enterNewPassword": "Unesite novu lozinku",
    "oldPassword": "Stara lozinka",
    "newPassword": "Nova lozinka",
    "repeatPassword": "Ponovite lozinku",
    "enterEmailForgotPass": "Unesite e-mail za promenu lozinke",
    "submit": "Potvrdi",
    "chooseLanguage": "Izaberite jezik",
    "payment": "Uplata",
    "loadingImageFailed": "Otvaranje slike neuspešno",
    "supportedImageFormats": "Dozvoljeni formati slike su",
    "maximumAllowedFileSizeIs": "Najveća dozvoljena veličina fajla je",
    "cropPhoto": "Isecite sliku",
    "change": "Promeni",
    "passwordSuccessfullyChanged": "Lozinka uspešno promenjena",
    "backToLogin": "Nazad na prijavu",
    "passwordChange": "Promena lozinke",
    "passwordsShouldNotMatch": "Lozinke moraju biti različite",
    "address": "Adresa",
    "date": "Datum",
    "admin": "Administrator",
    "franchisor": "Franšizer",
    "student": "Polaznik",
    "addCoverPhoto": "Dodajte naslovnu fotografiju",
    "addPhoto": "Dodajte fotografiju",
    "changeCoverPhoto": "Promenite naslovnu fotografiju",
    "changePhoto": "Promenite fotografiju",
    "editProfile": "Izmenite podatke",
    "changePassword": "Promenite lozinku",
    "pageNotFound": "Stranica nije dostupna",
    "goBackHome": "Povratak na početnu stranu",
    "phoneNumber": "Broj telefona",
    "photoSuccessfullyUploaded": "Fotografija uspešno postavljena",
    "dashboard": "Dashboard",
    "permissionManagement": "Upravljanje pravima i pristupima",
    "edu": "Edu",
    "sumRevenue": "Ukupan prihod",
    "course": "Kurs",
    "newInstructor": "Novi instruktor",
    "addingInstructor": "Dodavanje instruktora",
    "instructorSuccessfullyAdded": "Instruktor uspešno dodat",
    "courses": "Kursevi",
    "courseTemplate": "Templejt kursa",
    "newCourse": "Novi program",
    "addGroup": "Dodaj grupu",
    "addingGroup": "Dodavanje grupe",
    "newGroup": "Nova grupa",
    "price": "Cena",
    "startDate": "Početak",
    "groupSuccessfullyAdded": "Grupa uspešno dodata",
    "programme": "Program",
    "location": "Lokacija",
    "invalidInstallmentAmount": "Neispravna cena rate",
    "tooManyInstallments": "Izračunato previše rata",
    "readMore": "Pročitaj više",
    "learnMore": "Saznaj više",
    "seeMore": "Pogledaj više",
    "signUp": "Registruj se",
    "userLogin": "Login",
    "lessons": "Lekcije",
    "yourQuestion": "Vaše pitanje ili komentar",
    "send": "Pošalji",
    "questionSendIntro": "Ovde možete postaviti neko pitanje ili izraziti nedoumicu u vezi sa\nučenjem čitanjem, pamćenjem i školovanjem uopšte.",
    "chooseQuestionGroup": "Izaberite grupu sa pitanjima\n",
    "grade": "Razred",
    "language": "Jezik",
    "chooseLanguageHere": "Izaberite jezik ovde",
    "welcomeToLearnability": "Dobro došli na našu platformu!",
    "step": "Korak",
    "of": "od",
    "age": "Broj godina",
    "back": "Nazad",
    "registrationSuccessful": "Uspešna registracija",
    "showMore": "Prikaži više",
    "enterValidFullName": "Unesite validno ime i prezime",
    "newsletterSubscribeTitle": "Prijavite se na newsletter",
    "newsletterSubscribeText": "Prijavite se na naš newsletter za najnovija obaveštenja",
    "searchFranchises": "Pretraži franšize",
    "questionSubmitted": "Pitanje uspešno poslato",
    "generateCertificate": "Generiši diplomu",
    "searchGroups": "Pretraži grupe",
    "franchiserAlreadyExists": "Franšizer već postoji",
    "groupsOverview": "Pregled grupa",
    "sr": "Srpski",
    "hr": "Hrvatski",
    "mk": "Makedonski",
    "mn": "Crnogorski",
    "bh": "Bosanski",
    "sl": "Slovenski",
    "home": "Početna",
    "faq": "FAQ",
    "blog": "Blog",
    "add-page": "Dodaj",
    "change-page": "Izmeni",
    "see-more": "Pogledaj više",
    "texts": "Tekstovi",
    "programs": "Programi",
    "news": "Vesti",
    "category": "Kategorija",
    "add-template": "Dodaj šablon",
    "enter-city": "Unesite grad",
    "title": "Naslov",
    "enter-title": "Unesite naslov",
    "intro": "Intro",
    "enter-intro": "Unesite intro text",
    "create-news": "Kreiraj vest",
    "choose-template": "Odaberi šablon",
    "one-post": "Jedan post",
    "two-posts": "Dva posta",
    "three-posts": "Tri posta",
    "page-not-found": "Stranica nije pronadjena",
    "go-back": "Idi nazad",
    "groups": "Grupe",
    "groupOverview": "Pregled grupe",
    "editStudent": "Izmeni polaznika",
    "chooseCountryBeforeProceeding": "Molimo Vas da izaberete vašu zemlju pre nego sto nastavite dalje.",
    "continue": "Nastavi",
    "emailSentCheckEmail": "Email sa linkom je poslat. Proverite Vaš email.",
    "editGroup": "Izmeni grupu",
    "editingGroup": "Izmena grupe",
    "groupSuccessfullyEdited": "Grupa uspešno izmenjena",
    "passwordSuccessfullyReset": "Šifra uspešno izmenjena",
    "errorAddingInstructor": "Greška prilikom dodavanja instruktora",
    "editFranchise": "Izmeni franšizu",
    "editingFranchise": "Izmena franšize",
    "franchiseEditedSuccessfully": "Franšiza uspešno izmenjena",
    "groupSuccessfullyDeleted": "Grupa uspešno obrisana",
    "deleteGroup": "Obriši grupu",
    "confirm": "Potvrdi",
    "deleteGroupDialogText": "Da li ste sigurni da želite da obrišete ovu grupu?",
    "certificateLocation": "Mesto izdavanja diplome",
    "programmes": "Programi",
    "addProgramme": "Dodaj program",
    "royalties": "Royalties",
    "edit": "Izmeni",
    "editRoyalty": "Edit Royalty",
    "delete": "Obriši",
    "franchise": "Franšiza",
    "royaltyAlreadyExists": "Royalty already exists",
    "royaltyDoesntExist": "Royalty doesn't exist",
    "editProgramme": "Izmena programa",
    "countryNotFound": "Greška prilikom nalaženja zemlje",
    "errorAddingProgramme": "Greška prilikom dodavanja programa",
    "programmeNotFound": "Greška prilikom nalaženja programa",
    "franchiseNotFound": "Greška prilikom nalaženja franšize",
    "invalidRoyalty": "Invalid royalty",
    "errorCreatingRoyalty": "Error creating royalty",
    "errorChangingRoyalty": "Error changing royalty",
    "groupDoesntExist": "Greška prilikom nalaženja grupe",
    "royaltySuccessfullyAdded": "Royalty successfully added",
    "royaltySuccessfullyDeleted": "Royalty successfully deleted",
    "addingProgramme": "Dodavanje programa",
    "newProgramme": "Novi program",
    "editingProgramme": "Menjanje programa",
    "generateCertificates": "Generiši diplome"
};