import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SharedModule } from '../shared/shared.module';
import { AuthRoutingModule } from './auth-routing.module';

import {ResetPasswordComponent} from './reset-password/reset-password.component';
import {ForgotPasswordComponent} from './forgot-password/forgot-password.component';
import {ChangePasswordComponent} from './change-password/change-password.component';
import { LoginComponent } from './login/login.component';
import {LoginFirstStepComponent} from './login/login-first-step/login-first-step.component';
import {LoginSecondStepComponent} from './login/login-second-step/login-second-step.component';
import {ChangePasswordDialogComponent} from './change-password-dialog/change-password-dialog.component';
import { GuestLoginComponent } from './login/guest-login/guest-login.component';
import { GuestLoginNewComponent } from './login/guest-login-new/guest-login-new.component';
import { FaqSignupPopupComponent } from './login/guest-login-new/faq-signup-popup/faq-signup-popup.component';

@NgModule({
  declarations: [
    ResetPasswordComponent,
    ForgotPasswordComponent,
    ChangePasswordComponent,
    LoginComponent,
    LoginFirstStepComponent,
    LoginSecondStepComponent,
    ChangePasswordDialogComponent,
    GuestLoginComponent,
    GuestLoginNewComponent,
    FaqSignupPopupComponent,
  ],
  imports: [
    CommonModule,
    AuthRoutingModule,
    SharedModule
  ],
  entryComponents: [
    FaqSignupPopupComponent
  ]
})
export class AuthModule {
}
