export var tr_map  = {
    "saveAs": "engleski",
    "welcome": "Welcome",
    "enterEmailToContinue": "Enter e-mail to continue",
    "enterUsernameToContinue": "Enter username to continue",
    "enterValidEmail": "Enter a valid e-mail",
    "forgotEmail": "Forgot e-mail?",
    "next": "Next",
    "enterEmail": "Enter e-mail to continue",
    "enterUsername": "Enter username",
    "email": "E-mail",
    "username": "Username",
    "enterPassword": "Enter password",
    "password": "Password",
    "forgotPassword": "Forgot password?",
    "enterValidData": "Enter valid data",
    "administration": "Administration",
    "masterFranchises": "Master franchises",
    "franchises": "Franchises",
    "students": "Students",
    "homePage": "Home page",
    "notifications": "Notifications",
    "profile": "Profile",
    "logout": "Logout",
    "revenue": "Revenue",
    "expenditures": "Expenditures",
    "firstName": "First name",
    "lastName": "Last name",
    "country": "Country",
    "city": "City",
    "establishmentDate": "Establishment date",
    "franchiseOwner": "Franchise owner",
    "companyNumber": "Company number",
    "taxNumber": "Tax number",
    "franchiseNumber": "Number of franchises",
    "studentsNumber": "Number of students",
    "addPayment": "Add payment",
    "addStudent": "Add student",
    "addInstructor": "Add instructor",
    "addFranchise": "Add franchise",
    "noDataRecords": "No data records found",
    "debitDate": "Debit date",
    "paymentDate": "Date of payment",
    "description": "Description",
    "debitBalanceFor": "Debit balance for",
    "debitBalance": "Debit balance",
    "paymentsBalanceFor": "Payments balance for",
    "paymentsBalance": "Payments balance",
    "totalFor": "Total for",
    "total": "Total",
    "cancel": "Cancel",
    "save": "Save",
    "newFranchise": "New franchise",
    "chooseDate": "Choose a date",
    "territory": "Territory",
    "existingFranchisor": "Existing franchisor",
    "newFranchisor": "New franchisor",
    "chooseFranchiseOwner": "Choose a franchise owner",
    "errorOccurred": "An error has occurred",
    "info": "Info",
    "instructor": "Instructor",
    "firstLastName": "Full name",
    "group": "Group",
    "payments": "Payments",
    "dateOfBirth": "Date of birth",
    "birthplace": "Birthlplace",
    "identityNumber": "Identity number",
    "searchStudents": "Search students",
    "addingPayment": "Adding payment",
    "addingStudent": "Adding student",
    "addingFranchise": "Adding franchise",
    "amount": "Amount",
    "add": "Add",
    "gender": "Gender",
    "newStudent": "New student",
    "currency": "Currency",
    "maleGender": "Male",
    "femaleGender": "Female",
    "firstDebitDate": "First debit date",
    "sumDebit": "Total debit",
    "initiallyPaid": "Initially paid",
    "firstInstallmentAmount": "Amount of the first installment",
    "installmentAmount": "Amount of installment",
    "profilePhoto": "Profile photo",
    "choosePhoto": "Choose photo",
    "successfullyAdded": "Succesfully added",
    "close": "Close",
    "open": "Open",
    "serbian": "Serbian",
    "bosnian": "Bosnian",
    "montenegrian": "Montenegrian",
    "croatian": "Croatian",
    "slovenian": "Slovenian",
    "english": "English",
    "franchiseOverview": "Franchise overview",
    "studentOverview": "Student overview",
    "franchisorsAndInstructors": "Franchisors and instructors",
    "general": "General",
    "status": "Status",
    "name": "Name",
    "debit": "Debit",
    "fieldRequired": "The field is required",
    "isInstructor": "Is instructor",
    "franchiseSuccessfullyAdded": "Franchise successfully added",
    "studentSuccessfullyAdded": "Student successfully added",
    "paidTooMuch": "Payment exceeds planned amount",
    "successfullyPaid": "Succesfully paid",
    "passwordsNotMatch": "Passwords did not match",
    "enterNewPassword": "Enter new password",
    "oldPassword": "Old password",
    "newPassword": "New password",
    "repeatPassword": "Repeat password",
    "enterEmailForgotPass": "Enter e-mail for password change",
    "submit": "Submit",
    "chooseLanguage": "Choose language",
    "payment": "Payment",
    "loadingImageFailed": "Image has failed to load",
    "supportedImageFormats": "Supported image formats are",
    "maximumAllowedFileSizeIs": "Maximum allowed file size is",
    "cropPhoto": "Crop photo",
    "change": "Change",
    "passwordSuccessfullyChanged": "Password successfully changed",
    "backToLogin": "Back to login",
    "passwordChange": "Change password",
    "passwordsShouldNotMatch": "Passwords should not match",
    "address": "Address",
    "date": "Date",
    "admin": "Administrator",
    "franchisor": "Franchisor",
    "student": "Student",
    "addCoverPhoto": "Add cover photo",
    "addPhoto": "Add photo",
    "changeCoverPhoto": "Change cover photo",
    "changePhoto": "Change photo",
    "editProfile": "Edit profile",
    "changePassword": "Change password",
    "pageNotFound": "Page not found",
    "goBackHome": "Go back home",
    "phoneNumber": "Phone number",
    "photoSuccessfullyUploaded": "Photo has successfully uploaded",
    "dashboard": "Dashboard",
    "permissionManagement": "Permission management",
    "edu": "Edu",
    "sumRevenue": "Total Revenue",
    "course": "Course",
    "newInstructor": "New instructor",
    "addingInstructor": "Adding instructor",
    "instructorSuccessfullyAdded": "Instructor successfully added",
    "courses": "Courses",
    "courseTemplate": "Course template",
    "newCourse": "New course",
    "addGroup": "Add group",
    "addingGroup": "Adding group",
    "newGroup": "New Group",
    "price": "Price",
    "startDate": "Start date",
    "groupSuccessfullyAdded": "Group successfully added",
    "programme": "Programme",
    "location": "Location",
    "invalidInstallmentAmount": "Invalid installment amount",
    "tooManyInstallments": "Too many installments calculated",
    "readMore": "Read more",
    "learnMore": "Learn more",
    "seeMore": "See more",
    "signUp": "Sign up",
    "userLogin": "Login",
    "lessons": "Lessons",
    "yourQuestion": "Your question or comment",
    "send": "Send",
    "questionSendIntro": "IF YOU WANT TO ASK A QUESTION, PLEASE DO",
    "chooseQuestionGroup": "Choose a question group",
    "grade": "Grade",
    "language": "Language",
    "chooseLanguageHere": "Choose language here",
    "welcomeToLearnability": "Welcome to our platform!",
    "step": "Step",
    "of": "of",
    "age": "Age",
    "back": "Back",
    "registrationSuccessful": "Registration was successful",
    "showMore": "Show more",
    "enterValidFullName": "Enter valid full name",
    "newsletterSubscribeTitle": "Subscribe to a newsletter",
    "newsletterSubscribeText": "Subscribe to a newsletter for more of new great info and news",
    "searchFranchises": "Search franchises",
    "questionSubmitted": "Question successfully submitted",
    "generateCertificate": "Generate certificate",
    "searchGroups": "Search groups",
    "franchiserAlreadyExists": "Franchiser already exists",
    "groupsOverview": "Groups overview",
    "sr": "Serbian",
    "hr": "Croatian",
    "mk": "Macedonian",
    "mn": "Montenegrian",
    "bh": "Bosnian",
    "sl": "Slovenian",
    "home": "Home",
    "faq": "FAQ",
    "blog": "Blog",
    "add-page": "Add",
    "change-page": "Change",
    "see-more": "See more",
    "texts": "Tekstovi",
    "programs": "Programms",
    "news": "News",
    "category": "Category",
    "add-template": "Add template",
    "enter-city": "Enter city",
    "title": "Title",
    "enter-title": "Enter title",
    "intro": "Intro",
    "enter-intro": "Enter intro text",
    "create-news": "Create news",
    "choose-template": "Choose template",
    "one-post": "One post",
    "two-posts": "Two posts",
    "three-posts": "Three posts",
    "page-not-found": "Page not found",
    "go-back": "Go back",
    "groups": "Groups",
    "groupOverview": "Group overview",
    "editStudent": "Edit student",
    "chooseCountryBeforeProceeding": "Please choose your country before continuing.",
    "continue": "Continue",
    "emailSentCheckEmail": "Email has been sent. Check your email.",
    "editGroup": "Edit group",
    "editingGroup": "Editing group",
    "groupSuccessfullyEdited": "Group successfully edited",
    "passwordSuccessfullyReset": "Password successfully reset",
    "errorAddingInstructor": "Error occured while adding instructor",
    "editFranchise": "Edit franchise",
    "editingFranchise": "Editing franchise",
    "franchiseEditedSuccessfully": "Franchise successfully edited",
    "groupSuccessfullyDeleted": "Group successfully deleted",
    "deleteGroup": "Delete group",
    "confirm": "Confirm",
    "deleteGroupDialogText": "Are you sure you want to delete this group?",
    "certificateLocation": "Location where the certificate will be issued",
    "programmes": "Programmes",
    "addProgramme": "Add Programme",
    "royalties": "Royalties",
    "edit": "Edit",
    "editRoyalty": "Edit Royalty",
    "delete": "Delete",
    "franchise": "Franchise",
    "royaltyAlreadyExists": "Royalty already exists",
    "royaltyDoesntExist": "Royalty doesn't exist",
    "editProgramme": "Edit programme",
    "countryNotFound": "No country with that ID found",
    "errorAddingProgramme": "Error adding programme",
    "programmeNotFound": "Programme not found",
    "franchiseNotFound": "Franchise not found",
    "invalidRoyalty": "Invalid royalty",
    "errorCreatingRoyalty": "Error creating royalty",
    "errorChangingRoyalty": "Error changing royalty",
    "groupDoesntExist": "Group doesn't exist",
    "royaltySuccessfullyAdded": "Royalty successfully added",
    "royaltySuccessfullyDeleted": "Royalty successfully deleted",
    "addingProgramme": "Adding programme",
    "newProgramme": "New programme",
    "editingProgramme": "Editing programme",
    "generateCertificates": "Generate certificates"
};