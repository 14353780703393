import {AfterViewInit, Component, OnDestroy, OnInit} from '@angular/core';
import {environment} from "../../../../environments/environment";
import {Subject} from "rxjs";
import {ActivatedRoute, Router} from "@angular/router";
import {MatDialog, MatDialogConfig} from "@angular/material/dialog";
import {TableService} from "../../../services/table.service";
import {ToastService} from "../../../services/app-toast.service";
import {OverviewService} from "../../overview/overview.service";
import {MediatorService} from "../../../services/mediator.service";
import {TranslationService} from "../../../utils/translate.service";
import {FranchisesService} from "../../franchises/franchises.service";
import {LoggedUserService} from "../../../services/logged-user.service";
import {mergeMap, takeUntil} from "rxjs/operators";
import {AddDebtDialogComponent} from "../../../shared/components/dialogs/add-debt-dialog/add-debt-dialog.component";
import {RequestsService} from "../../../services/requests.service";

@Component({
  selector: 'app-programme-list',
  templateUrl: './programme-list.component.html',
  styleUrls: ['./programme-list.component.scss']
})
export class ProgrammeListComponent implements OnInit, AfterViewInit, OnDestroy {
  url = '';
  searchValue = '';
  customParams;
  mouseOverIndex = -1;
  environment = environment;
  displayedColumns = ['image', 'name', 'country', 'actions'];

  destroy$: Subject<boolean> = new Subject();

  programmes = [];

  constructor(
    private router: Router,
    private requests: RequestsService,
    public dialog: MatDialog,
    public table: TableService,
    private toast: ToastService,
    private route: ActivatedRoute,
    public overview: OverviewService,
    public mediator: MediatorService,
    public translate: TranslationService,
    public franchises: FranchisesService,
    private loggedUser: LoggedUserService) {
  }

  ngOnInit() {
    this.requests.svc_get('/api/programmes', {display: 'table'}).subscribe({
      next: value => {
        this.programmes = (value as any).programmes;
      }
    });
  }

  ngAfterViewInit() {
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  rowSelected(data) {
  }

  openDialog(e, franchiseId) {
    e.stopPropagation();
    this.dialog.open(AddDebtDialogComponent, {
      data: {id: franchiseId},
      panelClass: 'custom-dialog'
    } as MatDialogConfig)
      .afterClosed().subscribe((confirmed: boolean) => {
      if (confirmed) {
        this.franchises.transactionCompletes$.next(franchiseId);
      }
    });
  }

  countryIdToName(id) {
    return this.mediator.countryIdToCountry(id);
  }

  onMouseOver(index) {
    this.mouseOverIndex = index;
  }

  editProgramme(id) {
    this.router.navigateByUrl(this.router.url + '/programme/edit/' + id);
  }
}
