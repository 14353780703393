import {Component, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {LinkTree} from './link-tree';
import {LINK_DATA} from './link-data';
import {concatMap, takeUntil} from 'rxjs/operators';
import {BehaviorSubject, from, Subject} from 'rxjs';
import {Router} from '@angular/router';
import {SidenavService} from '../../../services/sidenav.service';
import {MediatorService} from '../../../services/mediator.service';
import {LoggedUserService} from '../../../services/logged-user.service';
import {FranchiseType} from "../../../pages/franchises/franchises.service";

@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss'],
  providers: [SidenavService]
})
export class SidenavComponent implements OnInit, OnDestroy, OnChanges {
  linkTree: LinkTree;
  activeLinkTree: LinkTree;

  linkCodes = [];
  linkDataPreparedSubject = new BehaviorSubject(false);
  linkDataPrepares$ = this.linkDataPreparedSubject.asObservable();

  public breadcrumbsChange$ = this.mediator.breadcrumbsSubject.asObservable();

  destroy$: Subject<boolean> = new Subject();

  constructor(private router: Router,
              public mediator: MediatorService,
              public loggedUser: LoggedUserService,
              private sidenavService: SidenavService
  ) {
  }

  ngOnInit() {
    this.breadcrumbsChange$.pipe(concatMap(breadcrumbs => {
      this.linkCodes = [];
      breadcrumbs.forEach(bc => this.linkCodes.push(bc.linkCode));
      return from([this.linkCodes]);
    }), takeUntil(this.destroy$)).subscribe(() => {
      if (this.mediator.isTreeBuilt) {

      } else {
        this.mediator.linkData = [...LINK_DATA];

        let url = '';
        if (this.loggedUser.isAdmin) {
          url = '/administration/master-franchises';
          // this.mediator.linkData.splice(0, 0, {
          //   title: 'courses',
          //   disabled: false,
          //   path: 'courses',
          //   linkIcon: 'library_books',
          //   active: false,
          //   code: '0.4',
          //   expandable: false
          // });
        } else if (this.loggedUser.isInstructor && !this.loggedUser.isFranchisor) {
          url = '/administration/students';
        } else {
          url = '/administration/franchises';
        }

        this.mediator.linkData.splice(0, 0, {
          title: 'administration',
          disabled: false,
          path: url,
          linkIcon: 'business_center',
          active: false,
          code: '0.2',
          expandable: false
        });

        // if (this.loggedUser.isAdmin) {
        //   this.mediator.linkData.splice(1, 0,{
        //     title: 'programmes',
        //     disabled: false,
        //     path: `/administration/${FranchiseType.MASTER}/programmes`,
        //     linkIcon: 'dashboard',
        //     active: false,
        //     code: '0.7',
        //     expandable: false
        //   });
        // }
      }
      this.linkDataPreparedSubject.next(true);
    });

    this.linkDataPrepares$.pipe(takeUntil(this.destroy$)).subscribe(isDataPrepared => {
      if (isDataPrepared) {
        if (!this.mediator.isTreeBuilt) {
          this.initializeTree();
        }
        this.mediator.isTreeBuilt = true;

        this.activeLinkTree.findAndExpand(this.linkCodes);
      }
    });
  }

  ngOnDestroy(): void {
    this.mediator.linkData = [];
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  initializeTree() {
    this.linkTree = new LinkTree('0', this.mediator.linkData);
    this.activeLinkTree = this.linkTree;
    this.linkTree.dataChange.pipe(takeUntil(this.destroy$)).subscribe(data => this.linkTree.dataSource.data = data);
  }

  setLinkPadding(node) {
    return node.level === 0 ? '1.5rem' : (node.level * 1.5) + 1.5 + 'rem';
  }

  goToRoute(node) {
    const url = node[`path`];

    this.router.navigate([url], {queryParams: node.params});
  }

  ngOnChanges(changes: SimpleChanges): void {
  }
}
