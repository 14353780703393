import {catchError, tap} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {ActivatedRoute, ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot} from '@angular/router';
import {RequestsService} from '../../services/requests.service';
import {LoggedUserService} from '../../services/logged-user.service';
import {Observable} from 'rxjs';
import {FranchisesService} from "../franchises/franchises.service";

@Injectable({providedIn: 'root'})
export class ProgrammeResolver implements Resolve<any> {
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private requests: RequestsService,
    private loggedUser: LoggedUserService,
    private franchises: FranchisesService
  ) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
    let id;
    id = route.params['id-programme'];

    return this.requests.svc_get(`/api/programme/${id}`, {}, this.loggedUser.getToken()).pipe(
      tap((data: any) => {
        this.franchises.currentProgramme = data;
        }
      ),
      catchError(err => {
        // this.loader.loading$.next(false);
        console.log(err);
        return this.router.navigate(['../'], {relativeTo: this.route});
      }));
  }
}
