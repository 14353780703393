import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-new-lessons-container',
  template: `<app-new-lessons></app-new-lessons>`,
  styles: []
})
export class NewLessonsContainerComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
