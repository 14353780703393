import { Component, Input, OnInit, OnChanges, Output, EventEmitter } from '@angular/core';
import { FormArray } from '@angular/forms';

@Component({
  selector: 'app-form-accordion',
  templateUrl: './form-accordion.component.html',
  styleUrls: ['./form-accordion.component.scss']
})
export class FormAccordionComponent implements OnInit, OnChanges {
  constructor() { }
  @Output() changed: EventEmitter<boolean> = new EventEmitter();
  @Input() config = { disabled: false, half: false, name: '' };
  @Input() formArray: FormArray;
  @Input() subOpened: boolean;
  @Input() expanded: boolean;
  @Input() indicator: any = null;

  accordionOpened = false;

  ngOnInit(): void {
  }

  ngOnChanges() {
    if (this.expanded !== undefined) {
      this.accordionOpened = this.expanded;
    }
  }

  openAccordion() {
    this.accordionOpened = !this.accordionOpened;
    this.changed.emit(this.accordionOpened);
  }

}
