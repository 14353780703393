import { Injectable } from '@angular/core';
import {RequestsService} from "../../services/requests.service";
import {MediatorService} from "../../services/mediator.service";
import {Subject} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class BannersService {

  banners$: Subject<any> = new Subject<any>();

  constructor(private requests: RequestsService, private mediator: MediatorService) {

  }

  getBanners() {
    const data = {id_country: this.mediator.languageToCountryId(localStorage.getItem('lang'))};

    this.requests.svc_get('/api/banners', data).subscribe({
      next: res => {
        this.banners$.next(res);
      }
    });
  }

}
