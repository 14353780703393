import { Injectable } from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';

@Injectable({providedIn: 'root'})
export class FormService {
  formValue = {};
  form = this.builder.group({});

  constructor(private builder: FormBuilder) { }

  formInitialized(name: string, form: FormGroup) {
    this.form.setControl(name, form);
    this.formValue = this.form.getRawValue();
  }

  setFormArray(data, length) {
    const arr = [];
    for (let i = 0; i < length; i++) {
      arr.push(this.builder.group(data));
    }
    return arr;
  }

  toggleDisabledState(formGroups: FormGroup[], isEditMode: boolean, {controlsToSkip = [], customDisableStrategy = null} = {}) {
    formGroups.forEach(group => {
      isEditMode ? group.enable() : group.disable();

      if (controlsToSkip.length > 0) {
        controlsToSkip.forEach(control => group.get(control).disable());
      }

      if (customDisableStrategy) {
        customDisableStrategy();
      }
    });
  }

  checkValidity(control) {
    control.updateValueAndValidity();
  }
}
