import {FranchisesService, FranchiseType, OverviewType} from './franchises.service';
import {catchError, tap} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {ActivatedRoute, ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot} from '@angular/router';
import {RequestsService} from '../../services/requests.service';
import {LoggedUserService} from '../../services/logged-user.service';
import {Observable} from 'rxjs';

@Injectable({providedIn: 'root'})
export class GroupResolver implements Resolve<any> {
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private requests: RequestsService,
    private loggedUser: LoggedUserService,
    private franchises: FranchisesService,
  ) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
    if (route.params && route.params['group-id']) {
      this.franchises.currentGroup = +route.params['group-id'];
      this.franchises.currentGroup$.next(this.franchises.currentGroup);
      this.franchises.groupData = this.franchises.currentFranchise.groups.find( group => group.id === +route.params['group-id']);
    }
    return true;
  }
}
