import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {LoggedUserService} from '../../services/logged-user.service';
import {ProfitAndLossService} from './profit-and-loss.service';
import {ActivatedRoute, Router} from '@angular/router';
import {FranchisesService} from '../franchises/franchises.service';
import {TranslationService} from '../../utils/translate.service';
import {MediatorService} from '../../services/mediator.service';
import {OverviewService} from '../overview/overview.service';
import * as moment from 'moment';
import {Subject} from 'rxjs';

@Component({
  selector: 'app-profit-and-loss',
  templateUrl: './profit-and-loss.component.html',
  styleUrls: ['./profit-and-loss.component.scss']
})
export class ProfitAndLossComponent implements OnInit, OnChanges, OnDestroy {
  url = '';
  overviewType = '';
  @Input() type: 'profit' | 'loss';
  @Input() paymentData: any[] = [];
  @Input() plannedPaymentData: any[] = [];
  @Input() paymentsSummary: any = {};

  date = moment().format('L');

  destroy$: Subject<boolean> = new Subject();

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    public mediator: MediatorService,
    public overview: OverviewService,
    public translate: TranslationService,
    public franchises: FranchisesService,
    public students: FranchisesService,
    public loggedUser: LoggedUserService,
    public profitAndLoss: ProfitAndLossService,
  ) {
  }

  ngOnInit() {
    this.overview.overviewType = this.route.routeConfig.data.hasOwnProperty('overviewType') ?
      this.route.routeConfig.data.overviewType : '';
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.paymentData && !changes.paymentData.firstChange) {
      this.paymentData = changes.paymentData.currentValue;
    }
    if (changes.plannedPaymentData && !changes.plannedPaymentData.firstChange) {
      this.plannedPaymentData = changes.plannedPaymentData.currentValue;
    }
    if (changes.paymentsSummary && !changes.paymentsSummary.firstChange) {
      this.paymentsSummary = changes.paymentsSummary.currentValue;
    }
  }

  ngOnDestroy() {
    this.paymentData = [];
    this.plannedPaymentData = [];
    this.paymentsSummary = {};
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  paymentSelect(data) {
    if (this.overview.isGroupOverview || (this.overview.isOwnOverview && this.loggedUser.isInstructor)) {
      this.router.navigate([data.id], {relativeTo: this.route.parent});
    }
  }
}
