import {Component, Inject, OnInit} from '@angular/core';
import {TranslationService} from '../../../../utils/translate.service';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';

@Component({
  selector: 'app-add-payment-dialog',
  templateUrl: './add-payment-dialog.component.html',
  styleUrls: ['./add-payment-dialog.component.scss']
})
export class AddPaymentDialogComponent implements OnInit {

  constructor(public translate: TranslationService,
              public dialogRef: MatDialogRef<AddPaymentDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any) {}
  ngOnInit() {
  }

}
