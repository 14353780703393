import {Component, OnDestroy, OnInit} from '@angular/core';
import {animate, keyframes, style, transition, trigger} from '@angular/animations';
import {environment} from '../../../environments/environment';
import {FormBuilder, Validators} from '@angular/forms';
import {Subject} from 'rxjs';
import {ActivatedRoute, Router} from '@angular/router';
import {ToastService} from '../../services/app-toast.service';
import {MatSnackBar} from '@angular/material';
import {OverviewService} from '../overview/overview.service';
import {MediatorService} from '../../services/mediator.service';
import {RequestsService} from '../../services/requests.service';
import {FranchisesService} from '../franchises/franchises.service';
import {LoggedUserService} from '../../services/logged-user.service';
import {TranslationService} from '../../utils/translate.service';
import {takeUntil} from 'rxjs/operators';
import {LookupService, ROLE_FLAGS} from '../../utils/lookup.service';

@Component({
  selector: 'app-instructor-form',
  templateUrl: './instructor-form.component.html',
  styleUrls: ['./instructor-form.component.scss'],
  animations: [
    trigger('fadeIn', [
      transition(':enter',
        animate(200,
          keyframes([
            style({opacity: 0, offset: 0}),
            style({opacity: 0.6, offset: 1})
          ])
        ))
    ])
  ]
})
export class InstructorFormComponent implements OnInit, OnDestroy {
  environment = environment;
  instructorFranchiseId = null;

  newInstructorForm = this.fb.group({
    first_name: this.fb.control(null, Validators.required),
    last_name: this.fb.control(null, Validators.required),
    username: this.fb.control(null, [Validators.required, Validators.email]),
    password: this.fb.control(null, [Validators.required]),
  });

  destroy$: Subject<boolean> = new Subject();

  constructor(
    private router: Router,
    private fb: FormBuilder,
    private toast: ToastService,
    public snackbar: MatSnackBar,
    private lookup: LookupService,
    private route: ActivatedRoute,
    public overview: OverviewService,
    public mediator: MediatorService,
    private requests: RequestsService,
    public franchises: FranchisesService,
    private loggedUser: LoggedUserService,
    private translate: TranslationService
  ) {
  }

  ngOnInit() {
    this.route.parent.parent.params.pipe(takeUntil(this.destroy$)).subscribe((params: any) => {
      const id = this.loggedUser.isFranchisor && !this.loggedUser.isAdmin ?
        +params['franchise-id'] : +params['master-franchise-id'];
      this.instructorFranchiseId = +params['franchise-id'];
      return this.requests.svc_get(`/api/franchises/${id}`, {}, this.loggedUser.getToken());
    });
  }

  ngOnDestroy() {
    this.mediator.isProgressBarVisible = false;
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  submit() {
    if (this.newInstructorForm.valid) {
      this.mediator.isProgressBarVisible = true;
      const data: any = {
        username: this.newInstructorForm.get('username').value,
        password: this.newInstructorForm.get('password').value,
        data: {
          first_name: this.newInstructorForm.get('first_name').value,
          last_name: this.newInstructorForm.get('last_name').value,
          // tslint:disable-next-line:no-bitwise
          role_flags: ROLE_FLAGS.USER | ROLE_FLAGS.INSTRUCTOR,
        }
      };

      delete data.role_flags;

      this.requests.svc_put(`/api/franchises/${this.instructorFranchiseId}/instructor`,
        data,
        this.loggedUser.getToken()).pipe(takeUntil(this.destroy$)).subscribe(
        {
          next: resp => {
            this.snackbar.open(this.translate.instant('instructorSuccessfullyAdded'), null, {duration: 1500})
              .afterDismissed().subscribe(() => this.routeBack());
          },
          error: err => {
            this.snackbar.open(this.translate.instant('errorAddingInstructor'), null, {duration: 1500});
            this.mediator.isProgressBarVisible = false;
          }
        });

    } else {
      this.toast.showInfoToast(this.translate.instant('enterValidData'));
    }
  }

  routeBack() {
    this.router.navigate(['../'], {relativeTo: this.route});
  }
}
