import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {FormBuilder, Validators} from '@angular/forms';
import {ToastService} from '../../services/app-toast.service';
import {MediatorService} from '../../services/mediator.service';
import {RequestsService} from '../../services/requests.service';
import {LoggedUserService} from '../../services/logged-user.service';
import {TranslationService} from '../../utils/translate.service';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit, OnDestroy {
  hash = '';
  isVisible = false;
  isFormDisabled = false;
  isEmailProvided = false;
  forgotPasswordForm = this.fb.group({
    email: this.fb.control(null, [Validators.required, Validators.email]),
    password: this.fb.control(null, [Validators.required]),
    retypePassword: this.fb.control(null, [Validators.required])
  });

  destroy$: Subject<boolean> = new Subject();

  constructor(
    private router: Router,
    private fb: FormBuilder,
    private toast: ToastService,
    private route: ActivatedRoute,
    public mediator: MediatorService,
    private requests: RequestsService,
    public loggedUser: LoggedUserService,
    private translate: TranslationService
  ) {
  }

  get email() {
    return this.forgotPasswordForm.get('email');
  }

  get newPassword() {
    return this.forgotPasswordForm.get('password');
  }

  get retypePassword() {
    return this.forgotPasswordForm.get('retypePassword');
  }

  ngOnInit() {
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  submit() {
    if (!this.isEmailProvided) {
      if (this.email.valid) {
        this.requests.svc_post('/user/forgot', {username: this.email.value}).pipe(takeUntil(this.destroy$)).subscribe((resp: any) => {
          this.isEmailProvided = true;
          this.router.navigate([], {relativeTo: this.route, queryParams: {hash: resp.hash}});
        }, err => {
          this.isEmailProvided = false;
          this.toast.showError();
        });
      } else {
        this.toast.showInfoToast(this.translate.instant('enterEmail'));
      }
    } else {
      if (this.newPassword && this.retypePassword.valid && this.hash.length > 0) {
        this.requests.svc_post(`/user/password/${this.hash}`, {
          new_password: this.newPassword.value
        }).pipe(takeUntil(this.destroy$)).subscribe(resp => {
          this.router.navigate(['login', 'first-step']);
        }, err => {
          this.toast.showError();
        });
      } else {
        this.toast.showInfoToast(this.translate.instant('passwordsNotMatch'));
      }
    }
  }

  back() {
    if (this.isEmailProvided) {
      this.isEmailProvided = false;
    } else {
      this.router.navigate(['login', 'second-step']);
    }
  }
}
