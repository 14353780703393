import {Component, OnDestroy, OnInit} from '@angular/core';
import {FormBuilder, FormControl, Validators} from '@angular/forms';
import {environment} from '../../../../environments/environment';
import {RequestsService} from '../../../services/requests.service';
import {PublicVideosService} from '../../services/public-videos.service';
import {takeUntil} from 'rxjs/operators';
import {ToastService} from '../../../services/app-toast.service';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {OverviewService} from '../../../pages/overview/overview.service';
import {MediatorService} from '../../../services/mediator.service';
import {LoggedUserService} from '../../../services/logged-user.service';
import {Observable, pipe, Subject, Subscription} from 'rxjs';
import {FranchisesService} from '../../../pages/franchises/franchises.service';
import {TranslationService} from '../../../utils/translate.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import {Location} from '@angular/common';

interface Video {
  video: {videoName: string, videoPath: string};
  countries: Array<string>;
}
@Component({
  selector: 'app-public-video-form',
  templateUrl: './public-video-form.component.html',
  styleUrls: ['./public-video-form.component.scss']
})
export class PublicVideoFormComponent implements OnInit, OnDestroy {
  constructor(private fb: FormBuilder,
              private vid: PublicVideosService,
              private router: Router,
              private snackBar: MatSnackBar,
              private toast: ToastService,
              private route: ActivatedRoute,
              public overview: OverviewService,
              public mediator: MediatorService,
              private requests: RequestsService,
              public franchises: FranchisesService,
              private loggedUser: LoggedUserService,
              private translate: TranslationService,
              private location: Location
            ) { }

  protected video: Video;
  environment = environment;
  countries = ['Serbia', 'Croatia', 'Bosnia and Herzegovina', 'Slovenia'];
  videoCountries: Array<string>;
  destroy$: Subject<boolean> = new Subject();
  isEdit = false;
  private idVid: number;
  videoAdd = this.fb.group({
    videoName: new FormControl(null, Validators.required),
    videoPath: new FormControl(null, Validators.required),
    videoDesc: new FormControl(null, Validators.required),
    countries: new FormControl([])
  });
  private url: string;
  private name: string;
  private path: string;

  ngOnInit() {
    if (this.router.url.endsWith('add')) {
      this.isEdit = false;
    } else {
      this.idVid = +this.route.snapshot.params.id;
      this.route.params.pipe(takeUntil(this.destroy$)).subscribe(
        (params: Params) => {
          this.idVid = +params.id;
        }
      );
      this.isEdit = true;
      this.url = '/api/public-videos/edit/' + this.idVid;
      this.requests.svc_get(this.url, {}, this.loggedUser.getToken()).pipe(takeUntil(this.destroy$)).subscribe(
        (resp: Video) => {
          // this.video = resp;
          // this.country = this.video.id_cnt;
          // this.name = this.video.video.videoName;
          // this.path = this.video.video.videoPath;
         this.videoAdd.patchValue({...resp.video, countries: resp.countries});
        });
    }
  }
  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  onSubmit() {
    if (this.videoAdd.valid && this.videoAdd.get('countries').value.length !== 0 && this.isEdit === false) {
      const vr = this.videoAdd.value;
      this.videoCountries = this.videoAdd.get('countries').value;
      const data = {
        countries: this.videoCountries,
        video: {
          videoName: this.videoAdd.get('videoName').value,
          videoPath: this.videoAdd.get('videoPath').value,
          videoDesc: this.videoAdd.get('videoDesc').value
        }
      };
      this.requests.svc_put('/api/public-videos/add', data, this.loggedUser.getToken()).pipe(takeUntil(this.destroy$)).subscribe(resp => {
        this.snackBar.open(this.translate.instant('Video uspešno dodat'), null, {duration: 3000});
      }, err => {
        this.toast.showInfoToast(this.translate.instant('enterValidData'));
      });
      this.videoAdd.reset({countries: [], videoName: '', videoPath: ''});
      // this.videoAdd.reset();
    } else {
      this.onSubmitEdit();
    }
  }

  countryId(val: string): string {
    switch (val) {
      case 'Serbia': return 'srb';
      case 'Croatia': return 'cro';
      case 'Slovenia': return 'slo';
      case 'Bosnia and Herzegovina': return 'bih';
      default: return 'srb';
    }
  }

  onSubmitEdit() {
    this.videoCountries = this.videoAdd.get('countries').value;
    const data = {
      id_video: this.idVid,
      countries: this.videoCountries,
      video: {videoName: this.videoAdd.get('videoName').value,
        videoDesc: this.videoAdd.get('videoDesc').value,
        videoPath: this.videoAdd.get('videoPath').value},
    };
    this.requests.svc_patch(this.url, data, this.loggedUser.getToken()).pipe(takeUntil(this.destroy$)).subscribe(resp => {
      this.snackBar.open(this.translate.instant('Video uspešno promenjen'), null, {duration: 3000});
    }, err => {
      this.toast.showInfoToast(this.translate.instant('enterValidData'));
    });
  }

  onClick() {
    this.location.back();
  }

  onCountryToggle(ev, countryId) {
    if (!this.videoAdd.get('countries').value.includes(countryId)) {
      this.videoAdd.get('countries').value.push(countryId);
    } else {
      const index = this.videoAdd.get('countries').value.indexOf(countryId);
      if (index > -1) {
        this.videoAdd.get('countries').value.splice(index, 1);
      }
    }
    console.log(this.videoAdd.get('countries').value)
  }
}
