import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  MatButtonModule,
  MatDatepickerModule,
  MatGridListModule,
  MatNativeDateModule,
  DateAdapter,
  MatChipsModule,
  MAT_DATE_LOCALE, MatButtonToggleModule, MatToolbarModule, MatBadgeModule, MAT_DATE_FORMATS, MatProgressBarModule
} from '@angular/material';
import { MatCardModule } from '@angular/material';
import { MatListModule } from '@angular/material';
import { MatIconModule } from '@angular/material';
import { MatMenuModule } from '@angular/material';
import { MatTabsModule } from '@angular/material';
import { MatSortModule } from '@angular/material';
import {MatDialogModule} from '@angular/material';
import { MatInputModule } from '@angular/material';
import { MatRadioModule } from '@angular/material';
import { MatTableModule } from '@angular/material';
import {MatSnackBarModule} from '@angular/material';
import { MatSelectModule } from '@angular/material';
import { MatTooltipModule } from '@angular/material';
import { MatSidenavModule } from '@angular/material';
import { MatCheckboxModule } from '@angular/material';
import { MatPaginatorModule } from '@angular/material';
import {MatAutocompleteModule} from '@angular/material';
import {MatSliderModule} from '@angular/material/slider';
import {MatStepperModule} from '@angular/material/stepper';
import {MatProgressSpinnerModule} from '@angular/material';
import {PlatformModule} from '@angular/cdk/platform';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {APP_DATE_FORMATS, CustomDateAdapter} from '../custom-date-adapter';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import {CdkTreeModule} from '@angular/cdk/tree';
import {LayoutModule} from '@angular/cdk/layout';
import {MatTreeModule} from '@angular/material/tree';
import {CdkStepperModule} from '@angular/cdk/stepper';

@NgModule({
  imports: [
    CommonModule,
    PlatformModule
  ],
  exports: [
    MatButtonModule,
    MatCardModule,
    MatInputModule,
    MatListModule,
    MatIconModule,
    MatSidenavModule,
    MatMenuModule,
    MatTableModule,
    MatTooltipModule,
    MatTabsModule,
    MatSelectModule,
    MatPaginatorModule,
    MatSortModule,
    MatCheckboxModule,
    MatDialogModule,
    MatRadioModule,
    BrowserAnimationsModule,
    MatGridListModule,
    MatProgressSpinnerModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatSnackBarModule,
    MatAutocompleteModule,
    MatSliderModule,
    MatStepperModule,
    MatExpansionModule,
    MatSlideToggleModule,
    MatChipsModule,
    PlatformModule,
    CdkTreeModule,
    CdkStepperModule,
    MatTreeModule,
    MatButtonToggleModule,
    LayoutModule,
    MatToolbarModule,
    MatBadgeModule,
    MatProgressBarModule
  ],
  declarations: [],
  providers: [
    {provide: DateAdapter, useClass: CustomDateAdapter},
    {provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS}
  ]
})
export class MaterialModule { }
