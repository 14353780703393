import {animate, keyframes, state, style, transition, trigger} from '@angular/animations';
import * as kf from './keyframes';

export const slideLeftRight = trigger('slideLeftRight', [
  state('default', style({})),
  state('slideLeft', style({})),
  state('slideRight', style({})),

  transition('default => slideLeft', animate(1000, keyframes(kf.slideLeft))),
  transition('default => slideRight', animate(1000, keyframes(kf.slideRight)))
]);

export const fadeIn = trigger('fadeIn', [
  transition(':enter',
    animate(400,
      keyframes([
        style({opacity: 0, offset: 0}),
        style({opacity: 1, offset: 1})
      ])
    ))
]);

export const fadeOutForm = trigger('fadeOutForm', [
  state('formVisible', style({
    opacity: 1,
  })),
  state('formNotVisible', style({
    transform: 'translateX(33%)',
    opacity: 0,
  })),
  transition('formVisible => formNotVisible', [
    animate('0.25s ease-in')
  ]),
  transition('formNotVisible => formVisible', [
    style({transform: 'translateX(-33%)'}),
    animate('0.25s ease-in')
  ]),
]);

export const inOutAnimation = trigger('inOutAnimation', [
    transition(
      ':enter',
      [
        style({ opacity: 0 }),
        animate('0.5s ease-out', style({ opacity: 1 }))
      ]
    ),
    transition(
      ':leave',
      [
        style({ opacity: 1 }),
        animate('0.5s ease-in', style({ opacity: 0 }))
      ]
    )
  ])
