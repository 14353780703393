import { Injectable } from '@angular/core';
import {OverviewType} from '../franchises/franchises.service';

@Injectable({providedIn: 'root'})
export class OverviewService {
  overviewType = '';

  get isStudentOverview() {
    return this.overviewType === OverviewType.STUDENT;
  }

  get isGroupOverview() {
      return this.overviewType === OverviewType.GROUP;
  }

  get isMasterOverview() {
    return this.overviewType === OverviewType.MASTER;
  }

  get isFranchiseOverview() {
    return this.overviewType === OverviewType.BASIC;
  }

  get isOwnOverview() {
    return this.overviewType === OverviewType.OWN;
  }

  constructor() { }
}
