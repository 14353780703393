import { Injectable } from '@angular/core';
import {FormBuilder, Validators} from '@angular/forms';
import {BehaviorSubject, Subject} from 'rxjs';
import {LoggedUserService} from '../../services/logged-user.service';
import {RequestsService} from '../../services/requests.service';

export enum FranchiseType {
  TOP = 'top-franchise',
  BASIC = 'franchises',
  MASTER = 'master-franchises'
}

export enum OverviewType {
  OWN = 'own',
  BASIC = 'franchise',
  STUDENT = 'student',
  MASTER = 'master-franchise',
  GROUP = 'group',
}

@Injectable({providedIn: 'root'})
export class FranchisesService {
  franchiseId;
  students = [];
  childrenFranchises = [];
  isSummaryVisible = false;

  franchiseList = [];
  franchiseGroups = [];
  franchiseData: any = null;
  masterFranchiseData: any = null;
  id;
  parent;
  currentFranchise;
  currentGroup;
  currentGroup$: Subject<any> = new Subject<any>();
  groupData: any = null;

  certificateStudentIds: Array<string> = [];

  currentProgramme;

  topPaymentData = [];
  paymentUntilData: {planed: any[], realised: any[], summary: any} = {planed: [], realised: [], summary: {}};
  paymentRevenueData: {planed: any[], realised: any[], summary: any} = {planed: [], realised: [], summary: {}};

  franchiseDataSets$ = new BehaviorSubject<any>(false);
  stateChange$ = new BehaviorSubject<any>(false);

  form = this.builder.group({
    name: this.builder.control(null, [Validators.required])
  });

  transactionCompletes$ = new Subject();

  royaltyAdded$ = new Subject();

  constructor(
    private builder: FormBuilder,
    private requests: RequestsService,
    private loggedUser: LoggedUserService
  ) { }

  getFranchise(id) {
    return this.requests.svc_get(`/api/franchises/${id}`, {}, this.loggedUser.getToken());
  }
}
