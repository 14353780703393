import { Component, OnInit, Output, Input, EventEmitter } from '@angular/core';
import { FormArray } from '@angular/forms';
import { getForm } from 'apc-forms';
import { MatDialog } from '@angular/material/dialog';
import { AddEditLectionComponent } from './add-edit-lection/add-edit-lection.component';


@Component({
  selector: 'app-form-lections',
  templateUrl: './form-lections.component.html',
  styleUrls: ['./form-lections.component.scss'],
})
export class FormLectionsComponent implements OnInit {
  @Output() manageFormItem: EventEmitter<any> = new EventEmitter();
  @Input() formArray: FormArray;
  @Input() config: any;
  @Input() id;
  @Input() multi = true;

  readMode = false;

  uidMap: any = {};
  counter = 0;

  constructor(
    public dialog: MatDialog
  ) { }

  ngOnInit() {
    const subs = this.formArray.valueChanges.subscribe(() => {
      const arr = this.formArray.value;
      if (arr[0] && arr[0].uid) {
        this.checkUid(arr);
        subs.unsubscribe();
      }
    });
  }

  checkUid(arr) {
    arr.forEach(element => {
      if (element.uid) {
        this.uidMap[element.uid] = `${element.uid}`;
      }
      if (element.lessons && element.lessons.length) {
        this.checkUid(element.lessons);
      }
    });
  }

  openAddLessonForm(i, lesson = null, ii = null) {
    // console.log('open');
    const dialogRef = this.dialog.open(AddEditLectionComponent, {
      width: '600px',
      // height: '620px',
      data: { lesson },
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log(result);
      if (!result) { return; }
      if (lesson) {
        (this.formArray.at(i).get('lessons') as FormArray).at(ii).patchValue({ ...lesson, ...result });
      } else {
        (this.formArray.at(i).get('lessons') as FormArray).push(getForm({ ...result, uid: '' }));
        this.generateUid(result.title, i, (this.formArray.at(i).get('lessons') as FormArray).length - 1);
      }
    });
  }

  deleteLesson(i, ii) {
    (this.formArray.at(i).get('lessons') as FormArray).removeAt(ii);
  }

  removeSection(i) {
    this.manageFormItem.emit(i);
  }

  newSection() {
    this.manageFormItem.emit();
  }

  generateUid(val, i, ii = null) {
    const _uid = val.toLocaleLowerCase().replace(/\s/g, '_');

    const checkUid = (uid) => {
      if (this.uidMap[uid]) {
        checkUid(_uid + `${++this.counter}`);
      } else {
        this.uidMap[uid] = `${uid}`;
        return ii === null ? this.formArray.at(i).patchValue({ uid }) :
          (this.formArray.at(i).get('lessons') as FormArray).at(ii).patchValue({ uid });
      }
    };
    checkUid(_uid);
  }

  addSection() {
    this.formArray.disable({emitEvent: this.readMode = true});
  }
}
