import {Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {SearchService} from '../../../services/search.service';
import {FormBuilder, FormGroup} from '@angular/forms';
import {debounceTime, distinctUntilChanged, takeUntil} from 'rxjs/operators';
import {fromEvent, Subject} from 'rxjs';
import {MediatorService} from '../../../services/mediator.service';
import {SearchContext} from '../../models/search-context.model';
import {TranslationService} from '../../../utils/translate.service';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
  providers: [SearchService]
})
export class SearchComponent implements OnInit, OnDestroy {
  searchForm: FormGroup;
  @ViewChild('searchInput', {static: true}) searchInput: ElementRef;

  destroy$: Subject<boolean> = new Subject();

  constructor(
    public mediator: MediatorService,
    public translate: TranslationService,
    private formBuilder: FormBuilder,
    public searchService: SearchService) {
  }

  ngOnInit() {
    this.searchForm = this.formBuilder.group({search: this.formBuilder.control('')});

    fromEvent(this.searchInput.nativeElement, 'input')
      .pipe(debounceTime(250), distinctUntilChanged(), takeUntil(this.destroy$))
      .subscribe((ev: any) => this.searchService.emitValue(ev.target.value));

    this.mediator.searchContextChanges$.pipe(takeUntil(this.destroy$)).subscribe((context: SearchContext) => {
      if (context != null) {
        if (context.hasAutocomplete) {
          this.searchService.list = context.list != null ? context.list : [];
          this.searchService.displayValue = context.displayValue != null ? context.displayValue : [];
        } else {
          this.searchService.list = [];
        }
        this.searchService.placeholder = context.placeholder != null ? context.placeholder : '';
        this.searchService.shouldEmitText = context.shouldEmitText != null ? context.shouldEmitText : true;
      } else {
        this.searchService.resetContext();
      }
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  get search() {
    return this.searchForm.get('search');
  }

  focus() {
    this.searchService.isActive = true;
  }

  blur() {
    this.searchService.isActive = false;
  }
}
