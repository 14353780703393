import { Component, OnInit } from '@angular/core';
import {Subject} from "rxjs";
import {Router, Scroll} from "@angular/router";
import {filter, takeUntil} from "rxjs/operators";
import {MediatorService} from '../../../services/mediator.service';
import {TranslationService} from '../../../utils/translate.service';

@Component({
  selector: 'app-courses-overview-footer',
  templateUrl: './courses-overview-footer.component.html',
  styleUrls: ['./courses-overview-footer.component.scss']
})
export class CoursesOverviewFooterComponent implements OnInit {

  constructor(public translate: TranslationService) {
  }

  ngOnInit() {
  }

}
