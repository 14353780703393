import {Component, Inject, OnInit} from '@angular/core';
import {MediatorService} from "../../../../services/mediator.service";
import {FormBuilder} from "@angular/forms";
import {ToastService} from "../../../../services/app-toast.service";
import {ActivatedRoute} from "@angular/router";
import {RequestsService} from "../../../../services/requests.service";
import {TranslationService} from "../../../../utils/translate.service";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {takeUntil} from "rxjs/operators";
import {Subject} from "rxjs";

@Component({
  selector: 'app-delete-group-dialog',
  templateUrl: './delete-group-dialog.component.html',
  styleUrls: ['./delete-group-dialog.component.scss']
})
export class DeleteGroupDialogComponent implements OnInit {

  destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    private toast: ToastService,
    private route: ActivatedRoute,
    public mediator: MediatorService,
    private requests: RequestsService,
    public translate: TranslationService,
    public dialogRef: MatDialogRef<DeleteGroupDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit() {
    console.log(this.data);
  }

  cancel() {
    this.dialogRef.close({result: false});
  }

  confirm() {
    if (this.data.group.hasOwnProperty('id')) {
      const url = `/api/group/${this.data.group.id}`;
      this.requests.svc_delete(url, null).pipe(takeUntil(this.destroy$)).subscribe(resp => {
        this.toast.showInfoToast(this.translate.instant(this.translate.instant('groupSuccessfullyDeleted')));
        this.mediator.isProgressBarVisible = false;
        setTimeout(() => this.dialogRef.close({result: true}), 500);
      }, err => {
        if (err.error.hasOwnProperty('key')) {
          this.toast.showInfoToast(this.translate.instant(err.error.key));
        } else {
          this.toast.showError();
        }
        this.mediator.isProgressBarVisible = false;
      });
    }
  }

}
