import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SlickCarouselModule } from 'ngx-slick-carousel';

import { EduRoutingModule } from './edu-routing.module';
import { CoursesOverviewBannerComponent } from './courses-overview/courses-overview-banner/courses-overview-banner.component';
import { CoursesOverviewListComponent } from './courses-overview/courses-overview-list/courses-overview-list.component';
import { CoursesOverviewContactComponent } from './courses-overview/courses-overview-contact/courses-overview-contact.component';
import { CoursesOverviewComponent } from './courses-overview/courses-overview.component';
import { CoursesOverviewFooterComponent } from './courses-overview/courses-overview-footer/courses-overview-footer.component';
import { EduContainerComponent } from './edu-container/edu-container.component';
import { EduHeaderComponent } from './edu-header/edu-header.component';
import { SharedModule } from '../shared/shared.module';
import { CoursesOverviewFaqComponent } from './courses-overview/courses-overview-faq/courses-overview-faq.component';
import { CourseContainerComponent } from './course/course-container/course-container.component';
import { LessonsContainerComponent } from './course/lessons-container/lessons-container.component';
import { ProfileComponent } from './profile/profile.component';
import { AdminDashboardComponent } from './dashboard/admin/admin.component';
import { CoursesOverviewCtaComponent } from './courses-overview/courses-overview-cta/courses-overview-cta.component';
import {CoursesOverviewTwoBannersComponent} from './courses-overview/courses-overview-two-banners/courses-overview-two-banners.component';
import {CdkTableModule} from '@angular/cdk/table';
import { EduTableComponent } from './components/edu-table/edu-table.component';
import { PublicVideosPlaceholderComponent } from './public-videos/public-videos-placeholder/public-videos-placeholder.component';
import { PublicVideoFormComponent } from './public-videos/public-video-form/public-video-form.component';
import { ProgrammeFormComponent} from './admin/programme-form/programme-form.component';
import { AdminPlaceholderComponent } from './admin/admin-placeholder/admin-placeholder.component';
import { CoursesDescrBannerComponent } from './courses-overview/courses-descr-banner/courses-descr-banner.component';
import { MatCarouselComponent, MatCarouselModule} from '@ngmodule/material-carousel';
import { BannerPageComponent } from './banner-page/banner-page.component';
import { QuestionsComponent } from './questions/questions.component';
import { AddDocumentDialogComponent } from './admin/programme-form/add-document-dialog/add-document-dialog.component';
import { ChangeProgrammeNameDialogComponent } from './admin/programme-form/change-programme-name-dialog/change-programme-name-dialog.component';
import { EduFaqComponent } from './edu-faq/edu-faq.component';
import { DeleteProgrammeDialogComponent } from './dashboard/admin/delete-programme-dialog/delete-programme-dialog.component';
import { DeleteVideoDialogComponent } from './dashboard/admin/delete-video-dialog/delete-video-dialog.component';


@NgModule({
  declarations: [
    CoursesOverviewComponent,
    CoursesOverviewBannerComponent,
    CoursesOverviewListComponent,
    CoursesOverviewContactComponent,
    CoursesOverviewFooterComponent,
    EduContainerComponent,
    EduHeaderComponent,
    CoursesOverviewFaqComponent,
    CourseContainerComponent,
    LessonsContainerComponent,
    ProfileComponent,
    AdminDashboardComponent,
    CoursesOverviewCtaComponent,
    CoursesOverviewTwoBannersComponent,
    EduTableComponent,
    PublicVideosPlaceholderComponent,
    PublicVideoFormComponent,
    ProgrammeFormComponent,
    AdminPlaceholderComponent,
    CoursesDescrBannerComponent,
    BannerPageComponent,
    QuestionsComponent,
    AddDocumentDialogComponent,
    ChangeProgrammeNameDialogComponent,
    EduFaqComponent,
    DeleteProgrammeDialogComponent,
    DeleteVideoDialogComponent
  ],
    imports: [
        CommonModule,
        EduRoutingModule,
        SharedModule,
        SlickCarouselModule,
        CdkTableModule,
        MatCarouselModule,
    ],
  entryComponents: [
    AddDocumentDialogComponent,
    ChangeProgrammeNameDialogComponent,
    DeleteProgrammeDialogComponent,
    DeleteVideoDialogComponent
  ]
})
export class EduModule { }
