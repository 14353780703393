import { Injectable } from '@angular/core';
import {Subject} from 'rxjs';

@Injectable({providedIn: 'root'})
export class StudentsService {
  studentData: any = null;
  paymentData: any = null;
  plannedPaymentData: any = null;
  paymentsSummary: any = {};

  transactionCompletes$ = new Subject();

  constructor() { }
}
