import { Injectable } from '@angular/core';

import { NgxPermissionsService } from 'ngx-permissions';

@Injectable()
export class AuthorizationService {

  private roles;

  constructor(
    private permissionsService: NgxPermissionsService,
  ) { }

  setUpPermissions(roles): void {
    this.roles = roles;

    if (this.roles.admin) this.addPermission('admin');
    if (this.roles.franchisor) this.addPermission('franchisor');
    if (this.roles.instructor) this.addPermission('instructor');
    if (this.roles.student) this.addPermission('student');
    if (this.roles.user) this.addPermission('user');
  }

  addPermission(permission: string): void {
    this.permissionsService.addPermission(permission);
  }

  getPermissions() {
    return this.permissionsService.getPermissions();
  }
}
