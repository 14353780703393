import {Component, OnDestroy, OnInit} from '@angular/core';
import * as moment from "moment";
import {Subject} from "rxjs";
import {ActivatedRoute, Router} from "@angular/router";
import {FormBuilder, FormControl, Validators} from "@angular/forms";
import {ToastService} from "../../../services/app-toast.service";
import {MatSnackBar} from "@angular/material/snack-bar";
import {OverviewService} from "../../overview/overview.service";
import {MediatorService} from "../../../services/mediator.service";
import {RequestsService} from "../../../services/requests.service";
import {FranchisesService} from "../../franchises/franchises.service";
import {LoggedUserService} from "../../../services/logged-user.service";
import {TranslationService} from "../../../utils/translate.service";
import {takeUntil} from "rxjs/operators";
import {animate, keyframes, style, transition, trigger} from "@angular/animations";
import {ProgrammeService} from "../../../services/programme.service";
import {fadeIn} from "../../../animations/animations";

@Component({
  selector: 'app-programme-form',
  templateUrl: './programme-form.component.html',
  styleUrls: ['./programme-form.component.scss'],
  animations: [fadeIn]
})
export class ProgrammeFormComponent implements OnInit, OnDestroy {
  countries;
  programmeForm;
  inEditMode: boolean = false;

  destroy$: Subject<boolean> = new Subject();

  constructor(
    private router: Router,
    private fb: FormBuilder,
    private toast: ToastService,
    public snackbar: MatSnackBar,
    private route: ActivatedRoute,
    public overview: OverviewService,
    public mediator: MediatorService,
    private requests: RequestsService,
    public franchises: FranchisesService,
    private programmeService: ProgrammeService,
    private loggedUser: LoggedUserService,
    private translate: TranslationService) {
    this.route.data.subscribe({
      next: value => {
        if (value && value.mode && value.mode === 'edit') {
          this.inEditMode = true;
        }
      }
    });
    this.programmeService.getCountries();
    this.programmeService.countries$.pipe(takeUntil(this.destroy$)).subscribe({
      next: value => {
        this.countries = (value as any).map( countryObj => {
          return {id: countryObj.id, name: this.mediator.countryIdToCountry(countryObj.id)};
        });
      }
    });
  }

  ngOnInit() {
      this.programmeForm = this.fb.group({
        name: ['', Validators.required],
        country: [null, Validators.required],
        default_royalty: [10, [Validators.required, Validators.max(100), Validators.min(0)]],
        data: ['']
      });
      if (this.inEditMode) {
        const editProgramme = this.franchises.currentProgramme;
        this.programmeForm.patchValue({
          name: editProgramme.name,
          country: editProgramme.country,
          default_royalty: editProgramme.default_royalty,
          data: editProgramme.data
        });
      }
    //     this.programmeForm.get('price').disable();
  }

  ngOnDestroy() {
    this.mediator.isProgressBarVisible = false;
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  submit() {
    if (this.programmeForm.valid) {
      this.mediator.isProgressBarVisible = true;
      const data = {
        ...this.programmeForm.getRawValue(),
      };
      const url = this.inEditMode ? `/api/programme/${this.franchises.currentProgramme.id}`
        : `/api/programmes`;
      const method = this.inEditMode ? 'PATCH' : 'PUT';

      this.requests._svc_call(method, url,
        data, this.loggedUser.getToken()).pipe(takeUntil(this.destroy$)).subscribe(resp => {
        this.inEditMode ? this.franchises.stateChange$.next('programmeEdited') : this.franchises.stateChange$.next('groupAdded');
        this.snackbar.open(this.translate.instant(
          `${this.inEditMode ? 'programmeSuccessfullyEdited' : 'programmeSuccessfullyAdded'}`), null, {duration: 1500})
          .afterDismissed().subscribe(() => this.routeBack());
      }, err => {
        if (err.error.hasOwnProperty('translate')) {
          this.toast.showInfoToast(this.translate.instant(err.error.translate));
        } else if (err.error.hasOwnProperty('message')) {
          this.toast.showInfoToast(this.translate.instant(err.error.message));
        } else {
          this.toast.showError();
        }
        this.mediator.isProgressBarVisible = false;
      });
    } else {
      this.toast.showInfoToast(this.translate.instant('enterValidData'));
    }
  }

  routeBack() {
    this.router.navigate([this.inEditMode ? '../../../' : '../../'], {relativeTo: this.route});
  }
}
