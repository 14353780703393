import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sidenav-tree',
  templateUrl: './sidenav-tree.component.html',
  styleUrls: ['./sidenav-tree.component.scss']
})
export class SidenavTreeComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
