import { Component, OnInit, Inject, Output, EventEmitter } from '@angular/core';
import {FormGroup, FormBuilder} from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import * as moment from 'moment';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { FileUploader } from 'ng2-file-upload';

@Component({
  selector: 'app-add-edit-lection',
  templateUrl: './add-edit-lection.component.html',
  styleUrls: ['./add-edit-lection.component.scss'],
})
export class AddEditLectionComponent implements OnInit {
  @Output() manageFormItem = new EventEmitter<string>();

  minDate = new Date();
  maxDate = new Date(2021, 13, 7); // month -1

  imageChangedEvent: any = '';
  croppedImage: any = '';

  private URL = 'path_to_api';

  uploader = new FileUploader({url: this.URL});

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<AddEditLectionComponent>,
    private fb: FormBuilder
  ) { }

  form: FormGroup;
  date: Date;

  ngOnInit() {
    this.form = this.fb.group({
      title: [''],
      videoURL: [''],
      poster: [''],
      files: this.fb.array([
        this.fb.control(File)
      ]),
      startDate: [new Date()],
    });

    if (this.data.lesson) {
      this.form.patchValue({ ...this.data.lesson });
      this.date = this.data.lesson.startDate;
    }

    this.uploader.onAfterAddingFile = (file) => { file.withCredentials = false; };
    this.uploader.onCompleteItem = (item: any, response: any, status: any, headers: any) => {
      console.log('ImageUpload:uploaded:', item, status, response);
    };
  }

  setDate(e) {
    this.form.patchValue({ startDate: moment(e.value).toDate().toISOString() });
  }

  saveLection() {
    this.dialogRef.close(this.form.value);
    // console.log(this.form.value);
  }

  fileChangeEvent(event: any): void {
    this.imageChangedEvent = event;
  }

  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
    this.form.get('poster').patchValue(this.croppedImage);
    // console.log(this.form.value);
  }
  imageLoaded() {
    // show cropper
  }
  cropperReady() {
    // cropper ready
  }
  loadImageFailed() {
    // show message
  }

}
