import { Component } from '@angular/core';
import {TableService} from '../../../services/table.service';

@Component({
  selector: 'app-table-paginator',
  templateUrl: './table-paginator.component.html',
  styleUrls: ['./table-paginator.component.scss']
})
export class TablePaginatorComponent {
  constructor(public tableService: TableService) { }
}
