import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {BannersService} from '../services/banners.service';

@Component({
  selector: 'app-banner-page',
  templateUrl: './banner-page.component.html',
  styleUrls: ['./banner-page.component.scss']
})
export class BannerPageComponent implements OnInit {
  image: string;
  title: string;
  ifImG: boolean;
  info: { paragraf1: string, img: string, paragraf2: string, paragraf3 };

  constructor(private mySvc: BannersService) {}

  ngOnInit() {
  }


}
