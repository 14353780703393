export var tr_map  = {
    "saveAs": "hrvatski",
    "welcome": "Dobro došli",
    "enterEmailToContinue": "Kako biste nastavili dalje, upišite e-mail",
    "enterUsernameToContinue": "Kako biste nastavili dalje, upišite korisničko ime",
    "enterValidEmail": "Unesite ispravan e-mail",
    "forgotEmail": "Zaboravili ste svoj e-mail",
    "next": "Dalje",
    "enterEmail": "Unesite e-mail",
    "enterUsername": "Unesite korisničko ime",
    "email": "E-mail",
    "username": "Korisničko ime",
    "enterPassword": "Unesite lozinku",
    "password": "Lozinka",
    "forgotPassword": "Zaboravili ste svoju lozinku",
    "enterValidData": "Unesite ispravne podatke",
    "administration": "Administracija",
    "masterFranchises": "Master-franšize",
    "franchises": "Franšize",
    "students": "Polaznici",
    "homePage": "Početna",
    "notifications": "Notifikacije",
    "profile": "Profil",
    "logout": "Odjavi se",
    "revenue": "Prihodi",
    "expenditures": "Rashodi",
    "firstName": "Ime",
    "lastName": "Prezime",
    "country": "Država",
    "city": "Grad",
    "establishmentDate": "Datum osnivanja",
    "franchiseOwner": "Nosilac franšize",
    "companyNumber": "Matični broj",
    "taxNumber": "OIB",
    "franchiseNumber": "Broj franšiza",
    "studentsNumber": "Broj polaznika",
    "addPayment": "Dodaj uplatu",
    "addStudent": "Dodaj polaznika",
    "addInstructor": "Dodaj instruktora",
    "addFranchise": "Dodaj franšizu",
    "noDataRecords": "Nije pronađen niti jedan rezultat",
    "debitDate": "Datum zaduženja",
    "paymentDate": "Datum uplate",
    "description": "Opis",
    "debitBalanceFor": "Saldo zaduženja na dan",
    "debitBalance": "Saldo zaduženja",
    "paymentsBalanceFor": "Saldo uplata na dan",
    "paymentsBalance": "Saldo uplata",
    "totalFor": "Ukupan saldo na dan",
    "total": "Ukupno",
    "cancel": "Odustani",
    "save": "Snimi",
    "newFranchise": "Nova franšiza",
    "chooseDate": "Izaberite datum",
    "territory": "Teritorija",
    "existingFranchisor": "Postojeći franšizer",
    "newFranchisor": "Novi franšizer",
    "chooseFranchiseOwner": "Izaberite nosioca franšize",
    "errorOccurred": "Došlo je do greške",
    "info": "Info",
    "instructor": "Instruktor",
    "firstLastName": "Ime i prezime",
    "group": "Grupa",
    "payments": "Plaćanja",
    "dateOfBirth": "Datum rođenja",
    "birthplace": "Mjesto rođenja",
    "identityNumber": "OIB",
    "searchStudents": "Pretraži polaznike",
    "addingPayment": "Dodavanje uplate",
    "addingStudent": "Dodavanje polaznika",
    "addingFranchise": "Dodavanje franšize",
    "amount": "Iznos",
    "add": "Dodaj",
    "gender": "Spol",
    "newStudent": "Novi polaznik",
    "currency": "Valuta",
    "maleGender": "Muški",
    "femaleGender": "Ženski",
    "firstDebitDate": "Datum prvog zaduženja",
    "sumDebit": "Ukupno zaduženje",
    "initiallyPaid": "Iniijalno plaćeno",
    "firstInstallmentAmount": "Iznos prve rate",
    "installmentAmount": "Iznos rate",
    "profilePhoto": "Slika profila",
    "choosePhoto": "Izaberite fotografiju",
    "successfullyAdded": "Uspješno dodano",
    "close": "Zatvori",
    "open": "Otvori",
    "serbian": "srpski",
    "bosnian": "bosanski",
    "montenegrian": "crnogorski",
    "croatian": "hrvatski",
    "slovenian": "slovenački",
    "english": "engleski",
    "franchiseOverview": "Pregled franšize",
    "studentOverview": "Pregled polaznika",
    "franchisorsAndInstructors": "Franšizeri i instruktori",
    "general": "Opće",
    "status": "Status",
    "name": "Naziv",
    "debit": "Zaduženje",
    "fieldRequired": "Polje je obavezno",
    "isInstructor": "Da li je instruktor",
    "franchiseSuccessfullyAdded": "Franšiza uspješno dodana",
    "studentSuccessfullyAdded": "Polaznik uspješno dodan",
    "paidTooMuch": "Uplata premašuje predviđenu sumu",
    "successfullyPaid": "Uspješno uplaćeno",
    "passwordsNotMatch": "Lozinke se ne poklapaju",
    "enterNewPassword": "Unesite novu lozinku",
    "oldPassword": "Stara lozinka",
    "newPassword": "Nova lozinka",
    "repeatPassword": "Ponovite lozinku",
    "enterEmailForgotPass": "Unesite e-mail za promjenu lozinke",
    "submit": "Potvrdi",
    "chooseLanguage": "Izaberite jezik",
    "payment": "Uplata",
    "loadingImageFailed": "Otvaranje slike neuspješno",
    "supportedImageFormats": "Dozvoljeni formati slike su",
    "maximumAllowedFileSizeIs": "Najveća dozvoljena veličina datotekeje",
    "cropPhoto": "Suzite sliku",
    "change": "Promijeni",
    "passwordSuccessfullyChanged": "Lozinka uspješno promjenjena",
    "backToLogin": "Natrag na prijavu",
    "passwordChange": "Promjena lozinke",
    "passwordsShouldNotMatch": "Lozinke moraju biti različite",
    "address": "Adresa",
    "date": "Datum",
    "admin": "Administrator",
    "franchisor": "Franšizer",
    "student": "Polaznik",
    "addCoverPhoto": "Dodajte naslovnu fotografiju",
    "addPhoto": "Dodajte fotografiju",
    "changeCoverPhoto": "Promijenite naslovnu fotografiju",
    "changePhoto": "Promijenite fotografiju",
    "editProfile": "Izmijenite podatke",
    "changePassword": "Promijenite lozinku",
    "pageNotFound": "Stranica nije dostupna",
    "goBackHome": "Povratak na početnu stranu",
    "phoneNumber": "Broj telefona",
    "photoSuccessfullyUploaded": "Fotografija uspješno postavljena",
    "dashboard": "Dashboard",
    "permissionManagement": "Upravljanje dozvolama",
    "edu": "Edu",
    "sumRevenue": "Ukupan prihod",
    "course": "Program",
    "newInstructor": "Novi instruktor",
    "addingInstructor": "Dodavanje instruktora",
    "instructorSuccessfullyAdded": "Instruktor uspješno dodat",
    "courses": "Programi",
    "courseTemplate": "Predložak programa",
    "newCourse": "Novi program",
    "addGroup": "Dodaj grupu",
    "addingGroup": "Dodavanje grupe",
    "newGroup": "Nova grupa",
    "price": "Cijena",
    "startDate": "Početak",
    "groupSuccessfullyAdded": "Grupa uspešno dodana",
    "programme": "Program",
    "location": "Lokacija",
    "invalidInstallmentAmount": "Neispravan iznos rate",
    "tooManyInstallments": "Izračunato previše rata",
    "readMore": "Read more",
    "learnMore": "Learn more",
    "seeMore": "See more",
    "signUp": "Sign up",
    "userLogin": "Login",
    "lessons": "Lessons",
    "yourQuestion": "Your question or comment",
    "send": "Send",
    "questionSendIntro": "IF YOU WANT TO ASK A QUESTION, PLEASE DO",
    "chooseQuestionGroup": "Choose a question group",
    "grade": "Grade",
    "language": "Language",
    "chooseLanguageHere": "Choose language here",
    "welcomeToLearnability": "Welcome to our platform!",
    "step": "Step",
    "of": "of",
    "age": "Age",
    "back": "Back",
    "registrationSuccessful": "Registration was successful",
    "showMore": "Show more",
    "enterValidFullName": "Enter valid full name",
    "newsletterSubscribeTitle": "Subscribe to a newsletter",
    "newsletterSubscribeText": "Subscribe to a newsletter for more of new great info and news",
    "searchFranchises": "Search franchises",
    "questionSubmitted": "Question successfully submitted",
    "generateCertificate": "Generate certificate",
    "searchGroups": "Search groups",
    "franchiserAlreadyExists": "Franchiser already exists",
    "groupsOverview": "Groups overview",
    "sr": "Srpski",
    "hr": "Hrvatski",
    "mk": "Makedonski",
    "mn": "Crnogorski",
    "bh": "Bosanski",
    "sl": "Slovenski",
    "home": "Početna",
    "faq": "FAQ",
    "blog": "Blog",
    "add-page": "Dodaj",
    "change-page": "Izmeni",
    "see-more": "Pogledaj više",
    "texts": "Tekstovi",
    "programs": "Programi",
    "news": "Vesti",
    "category": "Kategorija",
    "add-template": "Dodaj šablon",
    "enter-city": "Unesite grad",
    "title": "Naslov",
    "enter-title": "Unesite naslov",
    "intro": "Intro",
    "enter-intro": "Unesite intro text",
    "create-news": "Kreiraj vest",
    "choose-template": "Odaberi šablon",
    "one-post": "Jedan post",
    "two-posts": "Dva posta",
    "three-posts": "Tri posta",
    "page-not-found": "Stranica nije pronadjena",
    "go-back": "Idi nazad",
    "groups": "Groups",
    "groupOverview": "Group overview",
    "editStudent": "Edit student",
    "chooseCountryBeforeProceeding": "Please choose your country before continuing.",
    "continue": "Continue",
    "emailSentCheckEmail": "Email has been sent. Check your email.",
    "editGroup": "Edit group",
    "editingGroup": "Editing group",
    "groupSuccessfullyEdited": "Group successfully edited",
    "passwordSuccessfullyReset": "Password successfully reset",
    "errorAddingInstructor": "Error occured while adding instructor",
    "editFranchise": "Edit franchise",
    "editingFranchise": "Editing franchise",
    "franchiseEditedSuccessfully": "Franchise successfully edited",
    "groupSuccessfullyDeleted": "Group successfully deleted",
    "deleteGroup": "Delete group",
    "confirm": "Confirm",
    "deleteGroupDialogText": "Are you sure you want to delete this group?",
    "certificateLocation": "Location where the certificate will be issued",
    "programmes": "Programmes",
    "addProgramme": "Add Programme",
    "royalties": "Royalties",
    "edit": "Edit",
    "editRoyalty": "Edit Royalty",
    "delete": "Delete",
    "franchise": "Franchise",
    "royaltyAlreadyExists": "Royalty already exists",
    "royaltyDoesntExist": "Royalty doesn't exist",
    "editProgramme": "Edit programme",
    "countryNotFound": "No country with that ID found",
    "errorAddingProgramme": "Error adding programme",
    "programmeNotFound": "Programme not found",
    "franchiseNotFound": "Franchise not found",
    "invalidRoyalty": "Invalid royalty",
    "errorCreatingRoyalty": "Error creating royalty",
    "errorChangingRoyalty": "Error changing royalty",
    "groupDoesntExist": "Group doesn't exist",
    "royaltySuccessfullyAdded": "Royalty successfully added",
    "royaltySuccessfullyDeleted": "Royalty successfully deleted",
    "addingProgramme": "Adding programme",
    "newProgramme": "New programme",
    "editingProgramme": "Editing programme",
    "generateCertificates": "Generate certificates"
};