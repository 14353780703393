import {Component, Inject, OnInit} from '@angular/core';
import {FormBuilder, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-faq-signup-popup',
  templateUrl: './faq-signup-popup.component.html',
  styleUrls: ['./faq-signup-popup.component.scss']
})
export class FaqSignupPopupComponent  implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<FaqSignupPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder) {
  }

  ngOnInit() {
  }

  onClose(): void {
    this.dialogRef.close();
  }
}
