import { Injectable } from '@angular/core';
import {CanActivate, CanActivateChild, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router, CanDeactivate} from '@angular/router';
import { Observable } from 'rxjs';
import { GuestService } from '../services/guest.service';
import {LoggedUserService} from '../services/logged-user.service';
import {LookupService} from '../utils/lookup.service';
import {environment} from "../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class CoursesGuard implements CanActivate, CanActivateChild {

  constructor(
    private router: Router,
    private guest: GuestService,
    private lookup: LookupService,
    private loggedUser: LoggedUserService) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

      if (sessionStorage.getItem('learnability-pass') === 'true' || !environment.production) {

      } else {
        if (!(window.prompt('Enter password') === 'learnability!')) {
          window.alert('Wrong password.');
          this.router.navigate(['/login']);
          return false;
        } else {
          sessionStorage.setItem('learnability-pass', 'true');
        }
      }

      return new Promise((resolve) => {

        if (!localStorage.getItem('lang')) {
          this.router.navigate(['map']);
          resolve(true);
        }

        this.guest.checkGuest().subscribe((resp) => {
          if (resp !== false) { this.guest.loginGuest(); resolve(true); }
        }, err => {
          this.loggedUser.checkUser().subscribe((r: any) => {
            this.lookup.userRoles = r.roles;
            this.loggedUser.login(r);
            resolve(true);
          }, err => {
            this.loggedUser.logout(err);
            resolve(true);
          });
        });
    });
  }

  canActivateChild(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    return true;
  }

}
