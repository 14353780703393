import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';

@Injectable()
export class ResetPasswordGuardService implements CanActivate, CanActivateChild {
  constructor(private router: Router) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Promise<boolean> | Observable<boolean> {
    return new Promise((resolve) => {
      if (!route.params.hasOwnProperty('hash')) {
        resolve(false);
        this.router.navigate(['login', 'first-step']);
      } else {
        resolve(true);
      }
      resolve(true);
    });
  }

  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.canActivate(route, state);
  }
}

