import {Component, OnDestroy, OnInit} from '@angular/core';
import {animate, keyframes, style, transition, trigger} from '@angular/animations';
import {Subject} from 'rxjs';
import {ActivatedRoute, Router} from '@angular/router';
import {FormBuilder, FormControl, Validators} from '@angular/forms';
import {ToastService} from '../../../services/app-toast.service';
import {MatOptionSelectionChange, MatSnackBar} from '@angular/material';
import {OverviewService} from '../../overview/overview.service';
import {MediatorService} from '../../../services/mediator.service';
import {RequestsService} from '../../../services/requests.service';
import {FranchisesService} from '../../franchises/franchises.service';
import {LoggedUserService} from '../../../services/logged-user.service';
import {TranslationService} from '../../../utils/translate.service';
import * as moment from 'moment';
import {takeUntil} from 'rxjs/operators';
import {originalOrder} from '../../../utils/helpers';

@Component({
  selector: 'app-group-form',
  templateUrl: './group-form.component.html',
  styleUrls: ['./group-form.component.scss'],
  animations: [
    trigger('fadeIn', [
      transition(':enter',
        animate(200,
          keyframes([
            style({opacity: 0, offset: 0}),
            style({opacity: 0.6, offset: 1})
          ])
        ))
    ])
  ]
})
export class GroupFormComponent implements OnInit, OnDestroy {
  programmes: any = {};
  minDate = new Date(2018, 0, 1);
  maxDate = new Date(2025, 0, 1);
  startDate = moment().toISOString();
  originalOrder = originalOrder;

  groupForm;
  inEditMode: boolean = false;

  destroy$: Subject<boolean> = new Subject();

  constructor(
    private router: Router,
    private fb: FormBuilder,
    private toast: ToastService,
    public snackbar: MatSnackBar,
    private route: ActivatedRoute,
    public overview: OverviewService,
    public mediator: MediatorService,
    private requests: RequestsService,
    public franchises: FranchisesService,
    private loggedUser: LoggedUserService,
    private translate: TranslationService) {
    this.route.data.subscribe({
      next: value => {
        if (value && value.mode && value.mode === 'edit') {
          this.inEditMode = true;
        }
      }
    });
  }

  get start() {
    return this.groupForm.get('start_date');
  }

  // get items() {
  //   return this.groupForm.get('items');
  // }

  ngOnInit() {
    this.requests.svc_get('/api/programmes',
      {country: this.franchises.franchiseData.country.id},
      this.loggedUser.getToken()).pipe(takeUntil(this.destroy$)).subscribe((resp: any) => {
        this.programmes = resp.programmes;
        this.groupForm = this.fb.group({
          id_programme: new FormControl(null, [Validators.required]),
          name: new FormControl(null, [Validators.required]),
          start_date: new FormControl({value: new Date(), disabled: true}, [Validators.required]),
          price: new FormControl(null, []),
          currency: this.fb.control({value: this.franchises.franchiseData.currency.id, disabled: true}, Validators.required),
          location: new FormControl(null, []),
          certificate_location: new FormControl(null, []),
          data: new FormControl(null, []),
          // items: new FormControl([], []),
      });
        if (this.inEditMode) {
          const editGroup = this.franchises.franchiseData.groups.find( group => group.id === this.franchises.currentGroup);
          this.groupForm.patchValue({
            id_programme: editGroup.programme.id,
            start_date: editGroup.start,
            price: editGroup.price,
            location: editGroup.location,
            certificate_location: editGroup.certificate_location,
            name: editGroup.group_name,
            data: editGroup.data
          });
          this.groupForm.get('price').disable();
        }
    }, err => this.toast.showError());
  }

  ngOnDestroy() {
    this.mediator.isProgressBarVisible = false;
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  submit() {
    if (this.groupForm.valid) {
      this.mediator.isProgressBarVisible = true;
      const data = {
        ...this.groupForm.getRawValue(),
        start: moment(this.start.value).format('YYYY-MM-DD'),
      };
      delete data.start_date;
      const url = this.inEditMode ? `/api/group/${this.franchises.currentGroup}`
        : `/api/franchise/${this.franchises.franchiseData.id}/groups`;
      const method = this.inEditMode ? 'PATCH' : 'PUT';

      this.requests._svc_call(method, url,
        data, this.loggedUser.getToken()).pipe(takeUntil(this.destroy$)).subscribe(resp => {
        this.inEditMode ? this.franchises.stateChange$.next('groupEdited') : this.franchises.stateChange$.next('groupAdded');
        this.snackbar.open(this.translate.instant(`${this.inEditMode ? 'groupSuccessfullyEdited' : 'groupSuccessfullyAdded'}`), null, {duration: 1500})
          .afterDismissed().subscribe(() => this.routeBack());
      }, err => {
        this.toast.showError();
        this.mediator.isProgressBarVisible = false;
      });
    } else {
      this.toast.showInfoToast(this.translate.instant('enterValidData'));
    }
  }

  routeBack() {
    this.router.navigate(['../'], {relativeTo: this.route});
  }

  countProperties(obj) {
    return Object.keys(obj).length;
  }
}
