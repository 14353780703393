import {Injectable} from '@angular/core';
import {MatIconRegistry} from '@angular/material';
import {DomSanitizer} from '@angular/platform-browser';

@Injectable()
export class MatIconRegistryService {

  constructor( private matIconRegistry: MatIconRegistry,
               private domSanitizer: DomSanitizer ) {
    this.matIconRegistry.addSvgIcon(
      'visibility_outline',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/svg/outline-visibility.svg')
    ).addSvgIcon(
      'person_outline',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/svg/outline-person.svg')
    ).addSvgIcon(
      'notifications_outline',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/svg/outline-notifications.svg')
    ).addSvgIcon(
      'avatar_default',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/svg/header-profile-image.svg')
    ).addSvgIcon(
      'edit_fill',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/svg/edit-fill.svg')
    ).addSvgIcon(
      'more_vertical',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/svg/more-vert.svg')
    );
  }
}




