import {Component, Input, OnInit} from '@angular/core';
import {FaqService} from '../../../services/faq.service';
import { fadeIn } from '../../../animations/animations';
import {Router} from '@angular/router';

@Component({
  selector: 'app-courses-overview-faq',
  templateUrl: './courses-overview-faq.component.html',
  styleUrls: ['./courses-overview-faq.component.scss'],
  animations: [fadeIn]
})
export class CoursesOverviewFaqComponent implements OnInit {
  @Input() maxQuestions?: number;

  public activeQuestion;
  public faquestions;

  constructor(private faqService: FaqService,
              public router: Router) { }

  ngOnInit() {
    this.faquestions = this.faqService.getFAQ();

    if (!this.maxQuestions) {
      this.maxQuestions = this.faquestions.length;
    }
  }

  expandQuestion(question) {
    this.activeQuestion = question;
  }

  onShowMore() {
    this.router.navigate(['learnability/faq']);
  }

}
