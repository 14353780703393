import {AfterViewInit, Component, OnDestroy, OnInit} from '@angular/core';
import {Subject} from 'rxjs';
import {ActivatedRoute, Router} from '@angular/router';
import {MatDialog, MatDialogConfig} from '@angular/material';
import {TableService} from '../../../services/table.service';
import {ToastService} from '../../../services/app-toast.service';
import {OverviewService} from '../../overview/overview.service';
import {MediatorService} from '../../../services/mediator.service';
import {TranslationService} from '../../../utils/translate.service';
import {FranchisesService} from '../franchises.service';
import {LoggedUserService} from '../../../services/logged-user.service';
import {mergeMap, takeUntil} from 'rxjs/operators';
import {AddDebtDialogComponent} from '../../../shared/components/dialogs/add-debt-dialog/add-debt-dialog.component';
import {environment} from '../../../../environments/environment';
import {StudentsService} from '../../students/students.service';
import {DeleteGroupDialogComponent} from "../../../shared/components/dialogs/delete-group-dialog/delete-group-dialog.component";
import {group} from "@angular/animations";

@Component({
  selector: 'app-group-list',
  templateUrl: './group-list.component.html',
  styleUrls: ['./group-list.component.scss']
})
export class GroupListComponent implements OnInit, AfterViewInit, OnDestroy {
  url = '';
  searchValue = '';
  franchiseId;
  customParams;
  environment = environment;
  displayedColumns = ['image', 'name', 'start-date', 'end-date', 'programme', 'email', 'country', 'city', 'instructor', 'group', 'info', 'actions'];

  destroy$: Subject<boolean> = new Subject();

  constructor(
    private router: Router,
    public dialog: MatDialog,
    private toast: ToastService,
    private route: ActivatedRoute,
    public overview: OverviewService,
    public students: StudentsService,
    public mediator: MediatorService,
    public tableService: TableService,
    public translate: TranslationService,
    public franchises: FranchisesService,
    private loggedUser: LoggedUserService
  ) {
  }

  ngOnInit() {
    this.tableService.currentRoute = this.route.parent.parent;
    this.mediator.searchInputStateSubject.next(true);
    this.mediator.searchContextSubject.next({
      shouldEmitText: true,
      hasAutocomplete: false,
      placeholder: 'searchGroups'
    });
    this.mediator.searchValueSubject.pipe(takeUntil(this.destroy$)).subscribe({
      next: value => this.searchValue = value,
    });

    this.franchises.franchiseDataSets$.pipe(takeUntil(this.destroy$)).subscribe(isDataSet => {
      if (isDataSet) {
        this.overview.overviewType = this.route.parent.routeConfig.data.hasOwnProperty('overviewType') ?
          this.route.parent.routeConfig.data.overviewType : '';

        this.franchiseId = this.franchises.currentFranchise.id;

        if (this.loggedUser.isInstructor || this.loggedUser.isFranchisor) {
          this.displayedColumns = ['image', 'name',
            // 'programme',
            // 'start-date',
            // 'end-date',
            // 'email',
            // 'country',
            // 'city',
            'instructor',
            'studentsNum',
            // 'group', 'info',
            'actions'
          ];
        } else {
          // this.customParams = {};
          // this.url = `/api/franchises/${this.franchiseId}/students`;
          // this.tableService.getData(this.url, {responseDataTitle: 'students', customParams: this.customParams});
        }

        this.tableService.data = this.franchises.currentFranchise.groups;
        setTimeout(() => this.tableService.isContentVisible = this.tableService.data.length > 0);
      }
    });

    this.mediator.searchValue$.pipe(takeUntil(this.destroy$)).subscribe(val => {
      if (val != null) {
        this.tableService.filter(val);
      }
    });
  }

  ngAfterViewInit() {
    this.tableService.dataSource.data$.pipe(
      mergeMap(data => {
        if ((data != null && data.length > 0)) {
          this.tableService.data = data;
          this.tableService.isContentVisible = true;
        } else {
          // this.tableService.isContentVisible = false;
        }
        Promise.resolve(null).then(() => this.tableService.isContentVisible = true);
        return this.tableService.dataSource.counter$;
      }),
      mergeMap(count => {
        this.tableService.setTotal(count);
        return this.tableService.dataSource.loading$;
      }), takeUntil(this.destroy$)
    ).subscribe(isLoading => {
      // This resolves Error: ExpressionChangedAfterItHasBeenCheckedError: Expression has changed after it was checked
      Promise.resolve(null).then(() => this.tableService.isLoadingVisible = isLoading);
    }, err => {
    });
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();

    this.mediator.searchInputStateSubject.next(false);
    this.mediator.searchValueSubject.next('');
  }

  openDialog(e, groupToDelete) {
    e.stopPropagation();
    this.dialog.open(DeleteGroupDialogComponent, {
      data: {group: groupToDelete},
      panelClass: 'custom-dialog'
    } as MatDialogConfig)
      .afterClosed().subscribe((confirmed: boolean) => {
      if (confirmed) {
        // this.franchises.transactionCompletes$.next(this.franchiseId);
        this.tableService.isLoadingVisible = true;
        this.franchises.stateChange$.next('groupEdited');
      }
    });
  }

  rowSelected(data) {
    this.router.navigate(['group', data.id], {relativeTo: this.route.parent.parent});
  }
}
