import {Component, Inject, OnInit} from '@angular/core';
import {FormBuilder} from '@angular/forms';
import {ToastService} from '../../../../services/app-toast.service';
import {ActivatedRoute} from '@angular/router';
import {RequestsService} from '../../../../services/requests.service';
import {TranslationService} from '../../../../utils/translate.service';
import {LoggedUserService} from '../../../../services/logged-user.service';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {ImageCroppedEvent} from 'ngx-image-cropper';

@Component({
  selector: 'app-image-cropper-dialog',
  templateUrl: './image-cropper-dialog.component.html',
  styleUrls: ['./image-cropper-dialog.component.scss']
})
export class ImageCropperDialogComponent implements OnInit {
  profilePhotoConfig = {
    round: true,
    width: 400,
    height: 400,
    aspectRatio: 1,
    cropperWidth: 120,
    cropperHeight: 120,
    resizeToWidth: 120
  };

  coverPhotoConfig = {
    round: false,
    width: 1280,
    height: 720,
    aspectRatio: 16 / 9,
    cropperWidth: 1600,
    cropperHeight: 200,
    resizeToWidth: 1600,
    resizeToHeight: 200,
  };

  config = {};

  constructor(
    public translate: TranslationService,
    public dialogRef: MatDialogRef<ImageCropperDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
  }

  ngOnInit() {
    if (this.data.hasOwnProperty('type')) {
      if (this.data.type === 'profile') {
        this.config = this.profilePhotoConfig;
      } else if (this.data.type === 'cover') {
        this.config = this.coverPhotoConfig;
      }
    }
  }

  loadImageFailed() {
    this.data.cropper.loadImageFailed();
    this.dialogRef.close(false);
  }

  confirm() {
    this.dialogRef.close(true);
  }

  cancel() {
    this.dialogRef.close(false);
  }
}
