import {Component, Input, OnChanges, SimpleChanges} from '@angular/core';
import {MediatorService} from '../../../services/mediator.service';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss'],
})
export class LoaderComponent implements OnChanges {
  @Input('isLoading') isLoading;
  constructor(public mediator: MediatorService) { }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.isLoading) {
      this.isLoading = changes.isLoading.currentValue;
    }
  }
}
