import {Injectable} from '@angular/core';
import {ActivatedRoute, ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';
import {LoggedUserService} from '../services/logged-user.service';
import {FranchiseType} from '../pages/franchises/franchises.service';
import {MediatorService} from "../services/mediator.service";

@Injectable()
export class AdministrationGuardService implements CanActivate, CanActivateChild {
  constructor(private loggedUser: LoggedUserService, private router: Router, private route: ActivatedRoute, private mediator: MediatorService) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Promise<boolean> | Observable<boolean> {
    return new Promise((resolve) => {
      if (this.mediator.isLearnability()) {
        resolve(false);
        this.router.navigateByUrl('/learnability');
      } else {
        if (this.loggedUser.isAdmin) {
          resolve(true);
          this.router.navigate([`administration/${FranchiseType.MASTER}`], {relativeTo: this.route});
        } else if (this.loggedUser.isFranchisor && !this.loggedUser.isInstructor && this.loggedUser.isMaster) {
          resolve(true);
          this.router.navigate([`administration/${FranchiseType.BASIC}`], {relativeTo: this.route});
        } else if ((this.loggedUser.isFranchisor && !this.loggedUser.isMaster) ||
          (this.loggedUser.isFranchisor && this.loggedUser.isInstructor) ||
          (this.loggedUser.isInstructor)) {
          resolve(true);
          this.router.navigate(['administration', 'students'], {relativeTo: this.route});
        } else {
          resolve(false);
          this.router.navigate(['dashboard'], {relativeTo: this.route});
        }
      }
    });
  }

  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.canActivate(route, state);
  }
}

