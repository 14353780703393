import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {animate, keyframes, style, transition, trigger} from "@angular/animations";
import * as moment from "moment";
import {FormBuilder, Validators} from "@angular/forms";
import {Subject} from "rxjs";
import {ToastService} from "../../../../services/app-toast.service";
import {ActivatedRoute} from "@angular/router";
import {MediatorService} from "../../../../services/mediator.service";
import {RequestsService} from "../../../../services/requests.service";
import {TranslationService} from "../../../../utils/translate.service";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {takeUntil} from "rxjs/operators";
import {fadeIn} from "../../../../animations/animations";
import {FranchisesService} from "../../../../pages/franchises/franchises.service";

@Component({
  selector: 'app-add-edit-franchise-royalty',
  templateUrl: './add-edit-franchise-royalty.component.html',
  styleUrls: ['./add-edit-franchise-royalty.component.scss'],
  animations: [fadeIn]
})
export class AddEditFranchiseRoyaltyComponent implements OnInit, OnDestroy {

  royaltyForm = this.fb.group({
    id_franchise: [null, Validators.required],
    id_programme: [null, Validators.required],
    royalty: [null, [Validators.required, Validators.min(0), Validators.max(100)]]
  });

  franchises;
  programmes;

  editMode: boolean = false;

  destroy$: Subject<boolean> = new Subject();

  constructor(
    private fb: FormBuilder,
    private franchisesService: FranchisesService,
    private toast: ToastService,
    private route: ActivatedRoute,
    public mediator: MediatorService,
    private requests: RequestsService,
    public translate: TranslationService,
    public dialogRef: MatDialogRef<AddEditFranchiseRoyaltyComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
  }

  get royalty() {
    return this.royaltyForm.get('royalty');
  }

  get programme() {
    return this.royaltyForm.get('id_programme');
  }

  get franchise() {
    return this.royaltyForm.get('id_franchise');
  }

  ngOnInit() {
    this.editMode = this.data ? this.data.hasOwnProperty('programme') && this.data.hasOwnProperty('franchise') : false;

    this.franchises = this.franchisesService.currentFranchise.f_children;

    this.requests.svc_get('/api/programmes',
      {display: 'table', country: this.franchisesService.currentFranchise.country.id}).pipe(takeUntil(this.destroy$)).subscribe({
      next: value => {
        this.programmes = (value as any).programmes;
      }
    });

    if (this.editMode) {
      this.royaltyForm.patchValue({
        id_franchise: this.data.franchise.id,
        id_programme: this.data.programme.id,
        royalty: this.data.royalty
      });

      this.royaltyForm.get('id_franchise').disable();
      this.royaltyForm.get('id_programme').disable();
    }
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  confirm() {
    if (this.royaltyForm.valid) {
      let method = '';
      let url = '';
      let data: {};
      this.mediator.isProgressBarVisible = true;

      if (this.editMode) {
        url = `/api/programme/${this.data.programme.id}/franchise/${this.data.franchise.id}/royalty`;
        method = 'PATCH';
      } else {
        url = `/api/programme/${this.programme.value}/franchise/${this.franchise.value}/royalty`;
        method = 'PUT';
      }

      data = {
        royalty: this.royalty.value
      };
      this.requests._svc_call(method, url, data).pipe(takeUntil(this.destroy$)).subscribe(resp => {
        this.toast.showInfoToast(this.translate.instant('royaltySuccessfullyAdded'));
        this.mediator.isProgressBarVisible = false;
        setTimeout(() => this.dialogRef.close({result: true}), 500);
      }, err => {
        if (err.error.hasOwnProperty('translate')) {
          this.toast.showInfoToast(this.translate.instant(err.error.translate));
        } else if (err.error.hasOwnProperty('message')) {
          this.toast.showInfoToast(this.translate.instant(err.error.message));
        } else {
          this.toast.showError();
        }
        this.mediator.isProgressBarVisible = false;
      });
    } else {
      this.toast.showInfoToast(this.translate.instant('enterValidData'));
    }
  }

  cancel() {
    this.dialogRef.close({result: false});
  }

  delete() {
    const url = `/api/programme/${this.data.programme.id}/franchise/${this.data.franchise.id}/royalty`;
    this.mediator.isProgressBarVisible = true;

    this.requests.svc_delete(url, null).subscribe({
      next: value => {
        this.toast.showInfoToast(this.translate.instant('royaltySuccessfullyDeleted'));
        this.mediator.isProgressBarVisible = false;
        setTimeout(() => this.dialogRef.close({result: true}), 500);
      },
      error: err => {
        if (err.error.hasOwnProperty('translate')) {
          this.toast.showInfoToast(this.translate.instant(err.error.translate));
        } else if (err.error.hasOwnProperty('message')) {
          this.toast.showInfoToast(this.translate.instant(err.error.message));
        } else {
          this.toast.showError();
        }
        this.mediator.isProgressBarVisible = false;
      }
    });
  }

  // onProgrammeChange(ev, i) {
  //   this.royaltyForm.patchValue({
  //     id_programme: i
  //   });
  // }
  //
  // onFranchiseChange(ev, i) {
  //   this.royaltyForm.patchValue({
  //     id_franchise: i
  //   });
  // }
}
