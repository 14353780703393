import {Component, OnDestroy, OnInit} from '@angular/core';
import {FormBuilder, FormControl, Validators} from '@angular/forms';
import {PublicVideosService} from '../services/public-videos.service';
import {ActivatedRoute, Router} from '@angular/router';
import {MatSnackBar} from '@angular/material/snack-bar';
import {ToastService} from '../../services/app-toast.service';
import {OverviewService} from '../../pages/overview/overview.service';
import {MediatorService} from '../../services/mediator.service';
import {RequestsService} from '../../services/requests.service';
import {FranchisesService} from '../../pages/franchises/franchises.service';
import {LoggedUserService} from '../../services/logged-user.service';
import {TranslationService} from '../../utils/translate.service';
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {Location} from '@angular/common';

@Component({
  selector: 'app-questions',
  templateUrl: './questions.component.html',
  styleUrls: ['./questions.component.scss']
})
export class QuestionsComponent implements OnInit, OnDestroy {
  fnqAdd = this.fb.group({
    question: new FormControl(null, Validators.required),
    answer: new FormControl(null, Validators.required),
    typeOfQ: new FormControl(null, Validators.required),
    count: new FormControl(null, Validators.required)
  });
  private country: string;
  private isEdit = false;
  private typesId: string;
  types = ['tehnicko-logisticka pitanja', 'pitanja o problemima u ucenju', 'pitanja kako nesto uporediti', 'univerzalna'];
  destroy$: Subject<boolean> = new Subject();
  countries = ['Serbia', 'Croatia', 'Bosnia and Herzegovina', 'Slovenia'];
  constructor(private fb: FormBuilder,
              private vid: PublicVideosService,
              private router: Router,
              private snackBar: MatSnackBar,
              private toast: ToastService,
              private route: ActivatedRoute,
              public overview: OverviewService,
              public mediator: MediatorService,
              private requests: RequestsService,
              public franchises: FranchisesService,
              private loggedUser: LoggedUserService,
              private translate: TranslationService,
              private location: Location
  ) { }
  ngOnInit() {
    this.requests.svc_get('/api/type-of-questions', {}, this.loggedUser.getToken()).pipe(takeUntil(this.destroy$)).subscribe(
      (resp) => {
        console.log(resp);
      });
  }
  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
  countryId(val: string): string {
    if (val === 'Serbia') {
      return 'srb';
    }
    if (val === 'Croatia') {
      return 'cro';
    }
    if (val === 'Slovenia') {
      return 'slo';
    }
    return 'bih';
  }
  typeId(val: string): string{
    if (val === 'pitanja o problemima u ucenju') {
      return 'ppu';
    }
    if (val === 'pitanja kako nesto uporediti') {
      return 'pnu';
    }
    if (val === 'univerzalna') {
      return 'unv';
    }
    return 'tlp';
  }
  onSave() {
    if (this.fnqAdd.valid && this.isEdit === false) {
      this.country = this.countryId(this.fnqAdd.get('count').value);
      this.typesId = this.typeId(this.fnqAdd.get('typeOfQ').value);
      const data = {
        question: this.fnqAdd.get('question').value,
        answer: this.fnqAdd.get('answer').value,
        id_type: this.typesId,
        id_country: this.country
      };
      this.requests.svc_put('/api/faq/new', data, this.loggedUser.getToken()).pipe(takeUntil(this.destroy$)).subscribe(resp => {
        console.log(resp);
        this.snackBar.open(this.translate.instant('Question successfully added'), null, {duration: 3000});
      }, err => {
        this.toast.showInfoToast(this.translate.instant('enterValidData'));
      });
      this.fnqAdd.reset({question: '', answer: ''});
    }
  }
  onClick() {
    this.location.back();
  }
}
