import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-courses-overview-banner',
  templateUrl: './courses-overview-banner.component.html',
  styleUrls: ['./courses-overview-banner.component.scss']
})
export class CoursesOverviewBannerComponent implements OnInit {

  slides = [
    {img: "http://placehold.it/350x150/000000"},
    {img: "http://placehold.it/350x150/111111"},
    {img: "http://placehold.it/350x150/333333"},
    {img: "http://placehold.it/350x150/666666"}
  ];

  slideConfig = {
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    pauseOnHover: true,
    infinite: true,
    dots: true,
    arrows: false,
  };

  ngOnInit() {

  }

}
