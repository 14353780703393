import {Component, OnDestroy, OnInit} from '@angular/core';
import {FaqService} from '../../../services/faq.service';
import {FormBuilder, Validators} from '@angular/forms';

@Component({
  selector: 'app-courses-overview-contact',
  templateUrl: './courses-overview-contact.component.html',
  styleUrls: ['./courses-overview-contact.component.scss']
})
export class CoursesOverviewContactComponent implements OnInit, OnDestroy {

  questionForm = this.fb.group({
    question: [null, Validators.required]
  });

  constructor(private faqService: FaqService, private fb: FormBuilder) { }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
  }

  submitQuestion() {
    this.faqService.submitQuestion(this.questionForm.value.question);
  }

}
