import {catchError, tap} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {ActivatedRoute, ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot} from '@angular/router';
import {RequestsService} from '../../services/requests.service';
import {LoggedUserService} from '../../services/logged-user.service';
import {Observable} from 'rxjs';
import {StudentsService} from './students.service';

@Injectable({providedIn: 'root'})
export class StudentResolver implements Resolve<any> {
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private requests: RequestsService,
    private students: StudentsService,
    private loggedUser: LoggedUserService,
  ) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
    // this.loader.loading$.next(true);
    return this.requests.svc_get(`/api/student/${route.params['student-id']}`, {}, this.loggedUser.getToken()).pipe(
      tap((data: any) => this.students.studentData = data),
      catchError(err => {
        // this.loader.loading$.next(false);
        return this.router.navigate(['../'], {relativeTo: this.route});
      }));
  }
}
