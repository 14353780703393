import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import * as moment from 'moment';
import {FormBuilder, Validators} from '@angular/forms';
import {Subject} from 'rxjs';
import {ToastService} from '../../../../services/app-toast.service';
import {ActivatedRoute} from '@angular/router';
import {MediatorService} from '../../../../services/mediator.service';
import {RequestsService} from '../../../../services/requests.service';
import {TranslationService} from '../../../../utils/translate.service';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {takeUntil} from 'rxjs/operators';
import {animate, keyframes, style, transition, trigger} from '@angular/animations';
import {HttpClient} from "@angular/common/http";

@Component({
  selector: 'app-generate-certificate-dialog',
  templateUrl: './generate-certificate-dialog.component.html',
  styleUrls: ['./generate-certificate-dialog.component.scss'],
  animations: [
    trigger('fadeIn', [
      transition(':enter',
        animate(200,
          keyframes([
            style({opacity: 0, offset: 0}),
            style({opacity: 0.6, offset: 1})
          ])
        ))
    ])
  ]
})
export class GenerateCertificateDialogComponent implements OnInit, OnDestroy {

  certificateForm = this.fb.group({
    month: this.fb.control(null, Validators.required),
    year: this.fb.control(new Date().getFullYear(), Validators.required),
    location: this.fb.control(null, Validators.required),
    programme_name: this.fb.control(null),
    text: this.fb.control(null, Validators.required),
  });

  destroy$: Subject<boolean> = new Subject();

  constructor(
    private requests: RequestsService,
    private fb: FormBuilder,
    private http: HttpClient,
    private toast: ToastService,
    public mediator: MediatorService,
    public translate: TranslationService,
    public dialogRef: MatDialogRef<GenerateCertificateDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
  }

  get month() {
    return this.certificateForm.get('month');
  }

  get text() {
    return this.certificateForm.get('text');
  }

  getPDF(url, data) {
    return this.http.put(url, data, { observe: 'response', responseType: 'blob'} );
  }

  ngOnInit() {
    if (this.data.group) {
      this.certificateForm.patchValue({
        text: sessionStorage.getItem('certificateIntroText'),
        month: sessionStorage.getItem('certificateMonth'),
        location: this.data.group.certificate_location ? this.data.group.certificate_location : this.data.group.location,
        programme_name: this.data.group.programme.name
      });
    }
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  confirm() {
    if (this.certificateForm.valid) {
      let url = '';
      let data: {};
      this.mediator.isProgressBarVisible = true;

      sessionStorage.setItem('certificateIntroText', this.certificateForm.get('text').value);
      sessionStorage.setItem('certificateMonth', this.certificateForm.get('month').value);

      if (this.data.hasOwnProperty('studentId')) {
        url = `/api/student/${this.data.studentId}/certificate`;
        data = {
          ...this.certificateForm.getRawValue(),
        };
        this.getPDF(url, data).pipe(takeUntil(this.destroy$)).subscribe(resp => {
          this.toast.showInfoToast(this.translate.instant('Uspešno generisana diploma'));
          this.mediator.isProgressBarVisible = false;
          const objectUrl = window.URL.createObjectURL(resp.body);
          const a = document.createElement('a');
          document.body.appendChild(a);
          a.setAttribute('style', 'display: none');
          a.href = objectUrl;
          a.download = resp.headers.get('Content-Disposition').split(';')[1].trim().split('=')[1].replace(/"/g, '');
          a.click();
          window.URL.revokeObjectURL(objectUrl);
          a.remove();
          setTimeout(() => this.dialogRef.close({success: true}), 500);
        }, err => {
          if (err.error.hasOwnProperty('message')) {
            this.toast.showInfoToast(this.translate.instant(err.error.message));
          } else {
            this.toast.showError();
          }
          this.mediator.isProgressBarVisible = false;
        });
      } else if (this.data.hasOwnProperty('studentIds') && this.data.studentIds.length > 0) {
        url = `/api/student/certificates`;
        data = {
          id_students: this.data.studentIds,
          ...this.certificateForm.getRawValue(),
        };
        this.getPDF(url, data).pipe(takeUntil(this.destroy$)).subscribe(resp => {
          this.toast.showInfoToast(this.translate.instant('Uspešno generisana diploma'));
          this.mediator.isProgressBarVisible = false;
          const objectUrl = window.URL.createObjectURL(resp.body);
          const a = document.createElement('a');
          document.body.appendChild(a);
          a.setAttribute('style', 'display: none');
          a.href = objectUrl;
          a.download = resp.headers.get('Content-Disposition').split(';')[1].trim().split('=')[1].replace(/"/g, '');
          a.click();
          window.URL.revokeObjectURL(objectUrl);
          a.remove();
          setTimeout(() => this.dialogRef.close({success: true}), 500);
        }, err => {
          if (err.error.hasOwnProperty('message')) {
            this.toast.showInfoToast(this.translate.instant(err.error.message));
          } else {
            this.toast.showError();
          }
          this.mediator.isProgressBarVisible = false;
        });
      }
    } else {
      this.toast.showInfoToast(this.translate.instant('enterValidData'));
    }
  }

  cancel() {
    this.dialogRef.close({result: false});
  }
}
