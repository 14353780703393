import {Component, OnDestroy, OnInit} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Course, ProgrammeService } from 'src/app/services/programme.service';
import {Subject} from 'rxjs';
import {GuestService} from '../../../services/guest.service';
import {LoggedUserService} from '../../../services/logged-user.service';
import {takeUntil} from 'rxjs/operators';
import {Router} from '@angular/router';

@Component({
  selector: 'app-courses-overview-list',
  templateUrl: './courses-overview-list.component.html',
  styleUrls: ['./courses-overview-list.component.scss']
})
export class CoursesOverviewListComponent implements OnInit, OnDestroy {
  isGuest: boolean = false;
  isUser: boolean = false;

  destroy$: Subject<boolean> = new Subject();

  courses: Array<any>;
  publicVideos;

  activeCourse: any = {
    id: 0,
  };

  activeVideo = {
    id: null,
    videoName: null,
    videoDesc: null,
    videoPath: null,
  };

  constructor(
    public sanitizer: DomSanitizer,
    public courseService: ProgrammeService,
    private guest: GuestService,
    private loggedUser: LoggedUserService,
    private router: Router,
  ) { }

  ngOnInit() {
    this.guest.isGuest$.pipe(takeUntil(this.destroy$)).subscribe({
      next: value => {
        this.isGuest = value;
        this.initList();
        },
    });
    this.loggedUser.isUserLoggedIn$.pipe(takeUntil(this.destroy$)).subscribe({
      next: value => {
        this.isUser = value;
        this.initList();
      },
    });

    this.initList();
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  initList() {
    if (this.isGuest || this.isUser) {
      this.courseService.getAvailableCourses().subscribe({
        next: value => {
          this.courses = (value as any).guests_programs;
          if (this.courses && this.courses.length > 0) {
            for (const course of this.courses) {
              course.items.intro_video =
                this.sanitizer.bypassSecurityTrustResourceUrl(this.courseService.videoURL(course.items.intro_video));
            }
            this.makeActive(this.courses[0]);
            this.shuffleArray(this.courses);
          }
        }
      });
    } else {
      this.courseService.getAvailablePublicVideos().subscribe({
        next: (res: any) => {
          this.publicVideos = res.videos;
          if (this.publicVideos && this.publicVideos.length > 0) {
            for (const publicVideo of this.publicVideos) {
              publicVideo.videoPath =
                this.sanitizer.bypassSecurityTrustResourceUrl(this.courseService.videoURL(publicVideo.videoPath));
            }
            this.shuffleArray(this.publicVideos);
            this.makeVideoActive(this.publicVideos[0]);
          }
        }
      });
    }
  }

  shuffleArray(array) {
    let currentIndex = array.length, temporaryValue, randomIndex;

    // While there remain elements to shuffle...
    while (0 !== currentIndex) {

      // Pick a remaining element...
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex -= 1;
      // And swap it with the current element.
      temporaryValue = array[currentIndex];
      array[currentIndex] = array[randomIndex];
      array[randomIndex] = temporaryValue;
    }
    return array;
}

  makeActive(course) {
    if (course.id !== this.activeCourse.id) {
      this.activeCourse = { ...course };
    }
  }

  makeVideoActive(video) {
    if (video.id !== this.activeVideo.id) {
      this.activeVideo = { ...video };
    }
  }

  openProgramme(id) {
    this.router.navigateByUrl(`/learnability/programme/${id}/1`);
  }

}
