import {Component, OnDestroy, OnInit} from '@angular/core';
import {environment} from '../../../../environments/environment';
import {FormBuilder, FormControl} from '@angular/forms';
import {Subject} from 'rxjs';
import {ActivatedRoute, Router} from '@angular/router';
import {ToastService} from '../../../services/app-toast.service';
import {MatSnackBar} from '@angular/material';
import {OverviewService} from '../../overview/overview.service';
import {MediatorService} from '../../../services/mediator.service';
import {RequestsService} from '../../../services/requests.service';
import {FranchisesService} from '../../franchises/franchises.service';
import {LoggedUserService} from '../../../services/logged-user.service';
import {TranslationService} from '../../../utils/translate.service';
import {takeUntil} from 'rxjs/operators';
import * as moment from 'moment';
import {animate, keyframes, style, transition, trigger} from '@angular/animations';

@Component({
  selector: 'app-new-course',
  templateUrl: './new-course.component.html',
  styleUrls: ['./new-course.component.scss'],
  animations: [
    trigger('fadeIn', [
      transition(':enter',
        animate(200,
          keyframes([
            style({opacity: 0, offset: 0}),
            style({opacity: 0.6, offset: 1})
          ])
        ))
    ])
  ]
})
export class NewCourseComponent implements OnInit, OnDestroy {
  environment = environment;

  courseTemplates = [];

  minDate = new Date(2018, 0, 1);
  maxDate = new Date(2025, 0, 1);

  courseForm = this.fb.group({
    id_template: new FormControl(null, []),
    start: new FormControl(null, []),
    end: new FormControl(null, []),
    location: new FormControl(null, []),
    data: new FormControl(null, []),
  });

  destroy$: Subject<boolean> = new Subject();

  constructor(
    private router: Router,
    private fb: FormBuilder,
    private toast: ToastService,
    public snackbar: MatSnackBar,
    private route: ActivatedRoute,
    public overview: OverviewService,
    public mediator: MediatorService,
    private requests: RequestsService,
    public franchises: FranchisesService,
    private loggedUser: LoggedUserService,
    private translate: TranslationService) {
  }

  get start() {
    return this.courseForm.get('start');
  }

  get end() {
    return this.courseForm.get('end');
  }

  ngOnInit() {
    this.requests.svc_get(`/api/course_templates`, {}, this.loggedUser.getToken()).pipe(
      takeUntil(this.destroy$)).subscribe((data: any) => {
      this.courseTemplates = data.templates;
    }, err => this.toast.showError());
  }

  ngOnDestroy() {
    this.mediator.isProgressBarVisible = false;
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  submit() {
    if (this.courseForm.valid) {
      this.mediator.isProgressBarVisible = true;
      this.requests.svc_put('/api/courses',
        {
          ...this.courseForm.getRawValue(),
          start: moment(this.start.value).format('YYYY-MM-DD'),
          end: moment(this.end.value).format('YYYY-MM-DD'),
        }, this.loggedUser.getToken()).pipe(takeUntil(this.destroy$)).subscribe(resp => {
        this.snackbar.open(this.translate.instant('courseSuccessfullyAdded'), null, {duration: 1500})
          .afterDismissed().subscribe(() => this.routeBack());
      }, err => {
        this.toast.showError();
        this.mediator.isProgressBarVisible = false;
      });
    } else {
      this.toast.showInfoToast(this.translate.instant('enterValidData'));
    }
  }

  routeBack() {
    this.router.navigate(['../'], {relativeTo: this.route});
  }
}
