import { Component, OnInit } from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import {DomSanitizer} from '@angular/platform-browser';
import {MediatorService} from '../../services/mediator.service';

@Component({
  selector: 'app-edu-container',
  templateUrl: './edu-container.component.html',
  styleUrls: ['./edu-container.component.scss']
})
export class EduContainerComponent implements OnInit {
  backgroundImageUrl;

  constructor(private router: Router,
              private mediator: MediatorService,
              public sanitizer: DomSanitizer, ) { }

  ngOnInit() {
    this.mediator.isProgressBarVisible = false;
    this.setBackgroundImage();
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      this.setBackgroundImage();
      window.scrollTo(0, 0);
    });
  }

  setBackgroundImage() {
    switch (this.router.url) {
      case '/learnability/dashboard/admin': this.backgroundImageUrl = 'url("/assets/svg/dashboard-admin-bg.svg")'; break;
      default: this.backgroundImageUrl = ''; break;
    }
  }

}
