import {AfterViewInit, Component, OnInit} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import * as L from 'leaflet';
import {TranslationService} from '../../utils/translate.service';
import {Router} from '@angular/router';
import {environment} from "../../../environments/environment";

@Component({
  selector: 'app-language-map',
  templateUrl: './language-map.component.html',
  styleUrls: ['./language-map.component.scss']
})
export class LanguageMapComponent implements AfterViewInit, OnInit {
  private map;
  private json = [];
  private geoJSON;
  private countries = ['Serbia', 'Montenegro', 'Bosnia and Herzegovina', 'Croatia',
    'Slovenia', 'The former Yugoslav Republic of Macedonia'];

  public shouldCloseModal = false;
  private flagMarkers;

  public canAccess = false;

  private initMap = () => {
    this.map = L.map('map', {
      center: [ 43.994812, 19.2420203 ],
      zoom: 7,
      maxZoom: 7,
      minZoom: 7,
    });
    this.flagMarkers = new Map();

    const FlagIcon = L.Icon.extend({
      options: {
        iconSize: [80, 214],
        iconAnchor: [0, 214],
      }
    });

    const serbianFlagIcon = new FlagIcon({
      iconUrl: 'assets/svg/serbia.svg',
    });
    this.flagMarkers[this.countries[0]] = L.marker([44.7866, 20.4489], {icon: serbianFlagIcon, opacity: 0}).addTo(this.map);

    const montenegrinFlagIcon = new FlagIcon({
      iconUrl: 'assets/svg/montenegro.svg',
    });
    this.flagMarkers[this.countries[1]] = L.marker([42.4304, 19.2594], {icon: montenegrinFlagIcon, opacity: 0}).addTo(this.map);

    const bosnianFlagIcon = new FlagIcon({
      iconUrl: 'assets/svg/bosnia.svg',
    });
    this.flagMarkers[this.countries[2]] = L.marker([43.8563, 18.4131], {icon: bosnianFlagIcon, opacity: 0}).addTo(this.map);

    const croatianFlagIcon = new FlagIcon({
      iconUrl: 'assets/svg/croatia.svg',
    });
    this.flagMarkers[this.countries[3]] = L.marker([45.8150, 15.9819], {icon: croatianFlagIcon, opacity: 0}).addTo(this.map);

    const slovenianFlagIcon = new FlagIcon({
      iconUrl: 'assets/svg/slovenia.svg',
    });
    this.flagMarkers[this.countries[4]] = L.marker([46.0569, 14.5058], {icon: slovenianFlagIcon, opacity: 0}).addTo(this.map);

    const macedonianFlagIcon = new FlagIcon({
      iconUrl: 'assets/svg/macedonia.svg',
    });
    this.flagMarkers[this.countries[5]] = L.marker([41.9981, 21.4254], {icon: macedonianFlagIcon, opacity: 0}).addTo(this.map);

  }

  private style = (feature) => {
    return {
      fillColor: 'white',
      color: 'black',
      fillOpacity: '0.2'
    };
  }

  private highlightFeature = (e) => {
    const layer = e.target;
    let layerFillColor = '';

    switch (layer.feature.properties.NAME) {
      case 'Serbia': layerFillColor = '#FF8364'; this.flagMarkers[this.countries[0]].setOpacity(1); break;
      case 'Montenegro': layerFillColor = '#AA82FE'; this.flagMarkers[this.countries[1]].setOpacity(1); break;
      case 'Bosnia and Herzegovina': layerFillColor = '#FFCC4D'; this.flagMarkers[this.countries[2]].setOpacity(1); break;
      case 'Croatia': layerFillColor = '#6BD5E1'; this.flagMarkers[this.countries[3]].setOpacity(1); break;
      case 'Slovenia': layerFillColor = '#FF8364'; this.flagMarkers[this.countries[4]].setOpacity(1); break;
      case 'The former Yugoslav Republic of Macedonia':
        layerFillColor = '#96C76F'; this.flagMarkers[this.countries[5]].setOpacity(1); break;
    }

    layer.setStyle({
      weight: 5,
      color: '',
      fillColor: layerFillColor,
      dashArray: '',
      fillOpacity: 1,
    });

    if (!L.Browser.ie && !L.Browser.opera && !L.Browser.edge) {
      layer.bringToFront();
    }
  }

  private resetFeature = (e) => {
    this.geoJSON.resetStyle(e.target);
    switch (e.target.feature.properties.NAME) {
      case 'Serbia': this.flagMarkers[this.countries[0]].setOpacity(0); break;
      case 'Montenegro': this.flagMarkers[this.countries[1]].setOpacity(0); break;
      case 'Bosnia and Herzegovina': this.flagMarkers[this.countries[2]].setOpacity(0); break;
      case 'Croatia': this.flagMarkers[this.countries[3]].setOpacity(0); break;
      case 'Slovenia': this.flagMarkers[this.countries[4]].setOpacity(0); break;
      case 'The former Yugoslav Republic of Macedonia': this.flagMarkers[this.countries[5]].setOpacity(0); break;
    }
  }

  private onEachFeature = (feature, layer) => {
    layer.on({
      mouseover: this.highlightFeature,
      mouseout: this.resetFeature,
      click: () => this.onSelectCountry(feature.properties.NAME)
    });
  }

  constructor(private http: HttpClient,
              private translate: TranslationService,
              private router: Router) { }

  ngOnInit(): void {
    if (sessionStorage.getItem('learnability-pass') === 'true' || !environment.production) {
      this.canAccess = true;
    } else {
      if (!(window.prompt('Enter password') === 'learnability!')) {
        window.alert('Wrong password.');
        this.router.navigate(['/login']);
      } else {
        sessionStorage.setItem('learnability-pass', 'true');
        this.canAccess = true;
      }
    }
  }

  ngAfterViewInit(): void {
    this.initMap();

    this.http.get('assets/geojson/europe.geojson').subscribe((json: any) => {
      this.json.push(json);
      this.geoJSON = L.geoJSON(json, {
        style: this.style,
        filter: (feature, layer) => {
          return (this.countries.includes(feature.properties.NAME));
        },
        onEachFeature: this.onEachFeature,
      }).addTo(this.map);
    });

    this.map._handlers.forEach((handler) => {
      handler.disable();
    });
  }

  changeLanguage(lang) {
    this.translate.setLang(lang);
    localStorage.setItem('lang', lang);
  }

  onSelectCountry(country) {
    switch (country) {
      case 'Serbia': this.changeLanguage('RS'); break;
      case 'Montenegro': this.changeLanguage('ME'); break;
      case 'Bosnia and Herzegovina': this.changeLanguage('BA'); break;
      case 'Croatia': this.changeLanguage('HR'); break;
      case 'Slovenia': this.changeLanguage('SI'); break;
      case 'The former Yugoslav Republic of Macedonia': this.changeLanguage('MK'); break;
    }

    this.router.navigate(['learnability']);
  }
}
