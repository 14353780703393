import {AfterViewInit, Component, OnDestroy, OnInit} from '@angular/core';
import {environment} from "../../../../environments/environment";
import {Subject} from "rxjs";
import {ActivatedRoute, Router} from "@angular/router";
import {RequestsService} from "../../../services/requests.service";
import {MatDialog, MatDialogConfig} from "@angular/material/dialog";
import {TableService} from "../../../services/table.service";
import {ToastService} from "../../../services/app-toast.service";
import {OverviewService} from "../../overview/overview.service";
import {MediatorService} from "../../../services/mediator.service";
import {TranslationService} from "../../../utils/translate.service";
import {FranchisesService} from "../../franchises/franchises.service";
import {LoggedUserService} from "../../../services/logged-user.service";
import {AddEditFranchiseRoyaltyComponent} from "../../../shared/components/dialogs/add-edit-franchise-royalty/add-edit-franchise-royalty.component";
import {takeUntil} from "rxjs/operators";

@Component({
  selector: 'app-franchise-royalty-list',
  templateUrl: './franchise-royalty-list.component.html',
  styleUrls: ['./franchise-royalty-list.component.scss']
})
export class FranchiseRoyaltyListComponent implements OnInit, AfterViewInit, OnDestroy {
  url = '';
  searchValue = '';
  customParams;
  mouseOverIndex = -1;
  environment = environment;
  displayedColumns = [
    // 'image',
    'franchiseName',
    'programmeName',
    'royalty',
    // 'country',
    'actions'];

  destroy$: Subject<boolean> = new Subject();

  programmes = [];

  constructor(
    private router: Router,
    private requests: RequestsService,
    public dialog: MatDialog,
    public table: TableService,
    private toast: ToastService,
    private route: ActivatedRoute,
    public overview: OverviewService,
    public mediator: MediatorService,
    public translate: TranslationService,
    public franchises: FranchisesService) {
  }

  ngOnInit() {
    this.franchises.royaltyAdded$.pipe(takeUntil(this.destroy$)).subscribe({
      next: value => {
        if (value) {
          this.getRoyalties();
        }
      }
    });
    this.getRoyalties();
  }

  ngAfterViewInit() {
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  rowSelected(data) {
  }

  openDialog(e, franchise, programme, royalty) {
    e.stopPropagation();
    this.dialog.open(AddEditFranchiseRoyaltyComponent, {
      data: {franchise, programme, royalty},
      panelClass: 'custom-dialog'
    } as MatDialogConfig)
      .afterClosed().subscribe((confirmed: any) => {
      if (confirmed && confirmed.result) {
        this.getRoyalties();
      }
    });
  }

  countryIdToName(id) {
    return this.mediator.countryIdToCountry(id);
  }

  onMouseOver(index) {
    this.mouseOverIndex = index;
  }

  editProgramme(id) {
    this.router.navigateByUrl(this.router.url + '/programme/edit/' + id);
  }

  getRoyalties() {
    // TODO: FIX THIS TEMP WORKAROUND
    if (this.router.url === '/administration/master-franchises') {
      this.requests.svc_get('/api/franchises/royalties/all', {id_franchise: 1}).subscribe({
        next: value => {
          this.programmes = (value as any).royalties;
        }
      });
    } else {
      this.requests.svc_get('/api/franchises/royalties/all', {id_franchise: this.franchises.currentFranchise.id}).subscribe({
        next: value => {
          this.programmes = (value as any).royalties;
        }
      });
    }
  }

}
