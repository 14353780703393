import {NgModule} from '@angular/core';

import {SharedModule} from './shared/shared.module';
import {AppRoutingModule} from './app-routing.module';

import {AuthModule} from './auth/auth.module';
import {EduModule} from './edu/edu.module';

import {StudentsModule} from './pages/students/students.module';
import {FranchisesModule} from './pages/franchises/franchises.module';
import {QuillModule} from 'ngx-quill';

import {FormService} from './services/form.service';
import {Step2GuardService} from './guards/step-2-guard.service';
import { NgxPermissionsModule } from 'ngx-permissions';

import {AppComponent} from './app.component';
import {HomeComponent} from './home/home.component';
import {EduComponent} from './pages/edu/edu.component';
import {QaComponent} from './pages/edu/qa/qa.component';
import {ProfileComponent} from './pages/profile/profile.component';
import {LessonsComponent} from './pages/edu/lessons/lessons.component';
import {DashboardComponent} from './pages/dashboard/dashboard.component';
import {QaContainerComponent} from './pages/edu/qa/qa-container.component';
import {BookmarksComponent} from './pages/edu/bookmarks/bookmarks.component';
import {NewLessonsComponent} from './pages/edu/new-lessons/new-lessons.component';
import { GroupFormComponent } from './pages/courses/group-form/group-form.component';
import { NewCourseComponent } from './pages/courses/new-course/new-course.component';
import {ProfileContainerComponent} from './pages/profile/profile-container.component';
import {LessonsContainerComponent} from './pages/edu/lessons/lessons-container.component';
import {FormLearnComponent} from './pages/edu/new-lessons/form-learn/form-learn.component';
import { InstructorFormComponent } from './pages/instructor-form/instructor-form.component';
import {BookmarksContainerComponent} from './pages/edu/bookmarks/bookmarks-container.component';
import {FormLectionsComponent} from './pages/edu/new-lessons/form-lections/form-lections.component';
import {NewLessonsContainerComponent} from './pages/edu/new-lessons/new-lessons-container.component';
import { CoursesContainerComponent } from './pages/courses/courses-container/courses-container.component';
import {ChangePasswordDialogComponent} from './auth/change-password-dialog/change-password-dialog.component';
import {AddEditLectionComponent} from './pages/edu/new-lessons/form-lections/add-edit-lection/add-edit-lection.component';
import { LanguageMapComponent } from './pages/language-map/language-map.component';
import {FlexLayoutModule} from '@angular/flex-layout';
import {MatCarouselModule} from "@ngmodule/material-carousel";
import {SlickCarouselModule} from "ngx-slick-carousel";
import { ProgrammesComponent } from './pages/programmes/programmes.component';
import { ProgrammeListComponent } from './pages/programmes/programme-list/programme-list.component';
import { ProgrammeFormComponent } from './pages/programmes/programme-form/programme-form.component';
import { FranchiseRoyaltyListComponent } from './pages/franchise-royalties/franchise-royalty-list/franchise-royalty-list.component';

@NgModule({
    declarations: [
        QaComponent,
        AppComponent,
        EduComponent,
        HomeComponent,
        LessonsComponent,
        ProfileComponent,
        NewCourseComponent,
        GroupFormComponent,
        BookmarksComponent,
        DashboardComponent,
        FormLearnComponent,
        NewLessonsComponent,
        QaContainerComponent,
        FormLectionsComponent,
        AddEditLectionComponent,
        InstructorFormComponent,
        ProfileContainerComponent,
        LessonsContainerComponent,
        CoursesContainerComponent,
        BookmarksContainerComponent,
        NewLessonsContainerComponent,
        LanguageMapComponent,
        ProgrammesComponent,
        ProgrammeFormComponent,
    ],
    entryComponents: [ChangePasswordDialogComponent, AddEditLectionComponent],
    imports: [
        StudentsModule,
        FranchisesModule,
        AuthModule,
        EduModule,
        QuillModule.forRoot({
            modules: {
                toolbar: [
                    ['bold', 'italic', 'underline', 'strike'],          // toggled buttons
                    ['blockquote'],
                    [{header: 1}, {header: 2}],                         // custom button values
                    [{list: 'ordered'}, {list: 'bullet'}],
                    [{script: 'sub'}, {script: 'super'}],               // superscript/subscript
                    [{indent: '-1'}, {indent: '+1'}],                   // outdent/indent
                    [{direction: 'rtl'}],                               // text direction
                    [{header: [1, 2, 3, 4, 5, 6, false]}],
                    [{color: []}, {background: []}],                    // dropdown with defaults from theme
                    [{font: []}],
                    [{align: []}],
                    ['clean'],                                          // remove formatting button
                    ['link', 'image', 'video']                          // link and image, video
                ]
            }
        }),
        FlexLayoutModule,
        NgxPermissionsModule.forRoot(),
        SharedModule.forRoot(),
        MatCarouselModule.forRoot(),
        SlickCarouselModule,
        AppRoutingModule
    ],
    providers: [
        FormService,
        Step2GuardService,
        NgxPermissionsModule
    ],
  exports: [
  ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
