import {AvatarModule} from 'ngx-avatar';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';
import {MaterialModule} from './material.module';
import {FileUploadModule} from 'ng2-file-upload';
import {TranslateModule} from '@ngx-translate/core';
import {HttpClientModule} from '@angular/common/http';
import {FlexLayoutModule} from '@angular/flex-layout';
import {BrowserModule} from '@angular/platform-browser';
import {ModuleWithProviders, NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {ImageCropperComponent, ImageCropperModule} from 'ngx-image-cropper';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';


import {StartPipe} from '../pipes/start.pipe';
import {FromNowPipe} from '../pipes/from-now.pipe';
import {IsEmptyPipe} from '../pipes/is-empty.pipe';
import { SafeUrlPipe } from '../pipes/safe-url.pipe';
import {SafeStylePipe} from '../pipes/safe-style.pipe';
import {DynamicColorPipe} from '../pipes/status-color.pipe';
import {DateFormatterPipe} from '../pipes/date-formatter.pipe';
import {SearchAutocompleteValuePipe} from '../pipes/search-autocomplete-value.pipe';
import {SafePipe} from '../pipes/safe.pipe';
import {ArrayfilterPipe} from '../pipes/arrayfilter.pipe';

import {AutofocusDirective} from '../directives/autofocus.directive';

import {HeaderComponent} from '../header/header.component';
import {NotFoundComponent} from '../not-found/not-found.component';
import {LoaderComponent} from './components/loader/loader.component';
import {SearchComponent} from './components/search/search.component';
import {OverviewComponent} from '../pages/overview/overview.component';
import {SidenavComponent} from './components/sidenav/sidenav.component';
import {BreadcrumbsComponent} from './components/breadcrumbs/breadcrumbs.component';
import {PlaceholderComponent} from './components/placeholder/placeholder.component';
import {SidenavTreeComponent} from './components/sidenav-tree/sidenav-tree.component';
import {InfoHeaderComponent} from '../pages/overview/info-header/info-header.component';
import {ProfitAndLossComponent} from '../pages/profit-and-loss/profit-and-loss.component';
import {GeneralInfoComponent} from '../pages/overview/general-info/general-info.component';
import {TablePaginatorComponent} from './components/table-paginator/table-paginator.component';
import {ProgressStepperComponent} from './components/progress-stepper/progress-stepper.component';
import {AddDebtDialogComponent} from './components/dialogs/add-debt-dialog/add-debt-dialog.component';
import {ProfitAndLossReportComponent} from '../pages/profit-and-loss-report/profit-and-loss-report.component';
import {AddPaymentDialogComponent} from './components/dialogs/add-payment-dialog/add-payment-dialog.component';
import {ConfirmationDialogComponent} from './components/dialogs/confirmation-dialog/confirmation-dialog.component';
import {ImageCropperDialogComponent} from './components/dialogs/image-cropper-dialog/image-cropper-dialog.component';

import {LookupService} from '../utils/lookup.service';
import {CropperService} from '../services/cropper.service';
import {ToastService} from '../services/app-toast.service';
import {MediatorService} from '../services/mediator.service';
import {RequestsService} from '../services/requests.service';
import {TranslationService} from '../utils/translate.service';
import {RouterGuardService} from '../guards/router-guard.service';
import {LoggedUserService} from '../services/logged-user.service';
import {OverviewService} from '../pages/overview/overview.service';
import {AuthorizationService} from '../services/authorization.service';
import {NewFranchiseGuardService} from '../guards/new-franchise.service';
import {MatIconRegistryService} from '../utils/mat-icon-registry.service';
import {PermissionGuardService} from '../guards/permission-guard.service';
import {AdminMasterGuardService} from '../guards/admin-master-guard.service';
import {ResetPasswordGuardService} from '../guards/reset-password-guard.service';
import {AdministrationGuardService} from '../guards/administration-guard.service';
import {AdminFranchiseGuardService} from '../guards/admin-franchise-guard.service';
import {AdminInstructorGuardService} from '../guards/admin-instructor-guard.service';
import {FormAccordionComponent} from './components/form-accordion/form-accordion.component';
import {PadTreeNodePipe} from '../pipes/pad-tree-node.pipe';
import {BackButtonDirective} from "../directives/backbutton.directive";
import {GenerateCertificateDialogComponent} from './components/dialogs/generate-certificate-dialog/generate-certificate-dialog.component';
import {DeleteGroupDialogComponent} from "./components/dialogs/delete-group-dialog/delete-group-dialog.component";
import {ProgrammeListComponent} from "../pages/programmes/programme-list/programme-list.component";
import {FranchiseRoyaltyListComponent} from "../pages/franchise-royalties/franchise-royalty-list/franchise-royalty-list.component";
import {AddEditFranchiseRoyaltyComponent} from "./components/dialogs/add-edit-franchise-royalty/add-edit-franchise-royalty.component";

@NgModule({
  imports: [
    FormsModule,
    CommonModule,
    AvatarModule,
    RouterModule,
    BrowserModule,
    MaterialModule,
    FlexLayoutModule,
    HttpClientModule,
    ImageCropperModule,
    FileUploadModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    TranslateModule.forRoot(),
  ],
  declarations: [
      SafePipe,
      ArrayfilterPipe,
      StartPipe,
      IsEmptyPipe,
      FromNowPipe,
      SafeUrlPipe,
      SafeStylePipe,
      PadTreeNodePipe,
      DynamicColorPipe,
      DateFormatterPipe,
      SearchAutocompleteValuePipe,

      AutofocusDirective,

      SearchComponent,
      LoaderComponent,
      HeaderComponent,
      SidenavComponent,
      NotFoundComponent,
      OverviewComponent,
      InfoHeaderComponent,
      PlaceholderComponent,
      SidenavTreeComponent,
      BreadcrumbsComponent,
      GeneralInfoComponent,
      ProfitAndLossComponent,
      AddDebtDialogComponent,
      AddEditFranchiseRoyaltyComponent,
      ProgrammeListComponent,
      FranchiseRoyaltyListComponent,
      TablePaginatorComponent,
      ProgressStepperComponent,
      AddPaymentDialogComponent,
      DeleteGroupDialogComponent,
      ConfirmationDialogComponent,
      ImageCropperDialogComponent,
      ProfitAndLossReportComponent,
      GenerateCertificateDialogComponent,
      FormAccordionComponent,
      BackButtonDirective
  ],
  entryComponents: [
    AddDebtDialogComponent,
    AddPaymentDialogComponent,
    AddEditFranchiseRoyaltyComponent,
    ConfirmationDialogComponent,
    DeleteGroupDialogComponent,
    ImageCropperDialogComponent,
    GenerateCertificateDialogComponent,
  ],
  providers: [],
    exports: [
      FormsModule,
      CommonModule,
      AvatarModule,
      FileUploadModule,
      RouterModule,
      BrowserModule,
      MaterialModule,
      FlexLayoutModule,
      HttpClientModule,
      ReactiveFormsModule,
      BrowserAnimationsModule,

      TranslateModule,

      SafePipe,
      ArrayfilterPipe,
      StartPipe,
      SafeUrlPipe,
      IsEmptyPipe,
      FromNowPipe,
      SafeStylePipe,
      PadTreeNodePipe,
      DynamicColorPipe,
      DateFormatterPipe,
      SearchAutocompleteValuePipe,

      AutofocusDirective,

      SearchComponent,
      LoaderComponent,
      HeaderComponent,
      SidenavComponent,
      NotFoundComponent,
      OverviewComponent,
      InfoHeaderComponent,
      PlaceholderComponent,
      SidenavTreeComponent,
      BreadcrumbsComponent,
      GeneralInfoComponent,
      ProgrammeListComponent,
      ImageCropperComponent,
      AddDebtDialogComponent,
      AddEditFranchiseRoyaltyComponent,
      ProfitAndLossComponent,
      FormAccordionComponent,
      TablePaginatorComponent,
      ProgressStepperComponent,
      AddPaymentDialogComponent,
      DeleteGroupDialogComponent,
      ConfirmationDialogComponent,
      ImageCropperDialogComponent,
      ProfitAndLossReportComponent,
      GenerateCertificateDialogComponent,
      BackButtonDirective,
  ],
})
export class SharedModule {
static forRoot(): ModuleWithProviders {
  return {
      ngModule: SharedModule,
      providers: [
        ToastService,
        LookupService,
        CropperService,
        RequestsService,
        MediatorService,
        OverviewService,
        LoggedUserService,
        TranslationService,
        RouterGuardService,
        AuthorizationService,
        PermissionGuardService,
        MatIconRegistryService,
        AdminMasterGuardService,
        NewFranchiseGuardService,
        ResetPasswordGuardService,
        AdministrationGuardService,
        AdminFranchiseGuardService,
        AdminInstructorGuardService,
      ]
  };
}
}
