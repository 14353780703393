import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';
import {LoggedUserService} from '../services/logged-user.service';

@Injectable()
export class Step2GuardService implements CanActivate, CanActivateChild {
  constructor(private router: Router, private loggedUser: LoggedUserService) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Promise<boolean> | Observable<boolean> {
    return new Promise((resolve) => {
      if (this.loggedUser.getToken() || (!this.loggedUser.loginData.username || this.loggedUser.loginData.username.length === 0)) {
        resolve(false);
        this.router.navigate(['login', 'first-step']);
      } else {
        resolve(true);
      }
    });
  }

  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.canActivate(route, state);
  }
}

