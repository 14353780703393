import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-public-videos-placeholder',
  templateUrl: './public-videos-placeholder.component.html',
  styleUrls: ['./public-videos-placeholder.component.scss']
})
export class PublicVideosPlaceholderComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
