import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {ToastService} from '../../../services/app-toast.service';
import {FormBuilder, FormGroup, Validators, FormControl} from '@angular/forms';
import {TranslationService} from '../../../utils/translate.service';
import {MediatorService} from '../../../services/mediator.service';
import {animate, keyframes, style, transition, trigger} from '@angular/animations';
import { GuestService } from 'src/app/services/guest.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-guest-login',
  templateUrl: './guest-login.component.html',
  styleUrls: ['./guest-login.component.scss'],
  animations: [
    trigger('fadeIn', [
      transition(':enter',
        animate(200,
          keyframes([
            style({opacity: 0, offset: 0}),
            style({opacity: 1, offset: 1})
          ])
        ))
    ])
  ]
})
export class GuestLoginComponent implements OnInit, OnDestroy {
  formGroup: FormGroup;
  isEditable: boolean = true;
  backDeg: Number;
  frontDeg: Number;

  destroy$: Subject<boolean> = new Subject();

  objectKeys = Object.keys;
  languages: Object = { 'Srbija': 'srpski', 'Hrvatska': 'hrvatski', 'Bosna i Hercegovina': ['bosnjacki', 'hrvatski', 'srpski'], 'Crna Gora': 'crnogorski', 'Slovenija': 'slovenski' }
  languageCodes: Object = { 'srpski': 'RS', 'hrvatski': 'HR', 'bosnjacki': 'BA', 'crnogorski': 'ME', 'slovenski': 'SI' }
  countryCodes: Object = { 'Srbija': 'srb', 'Hrvatska': 'cro', 'Bosna i Hercegovina': 'bih', 'Slovenija': 'slo', 'Crna Gora': 'cg' }
  selectedCountry: String;
  shouldChooseLanguage: boolean = false;
  isCountryLanguageChosen: boolean = false;

  constructor(private _formBuilder: FormBuilder,
              private router: Router,
              public mediator: MediatorService,
              private translate: TranslationService,
              private toast: ToastService,
              private guest: GuestService) {}

  ngOnInit() {
    setTimeout(() => this.mediator.loginStepChanges$.next({back: 14, front: 0}));
    this.changeLanguage('EN');
    this.formGroup = this._formBuilder.group({
      country: ['', Validators.required],
      city: ['', [Validators.required, Validators.pattern("[a-zA-Z ]*"), Validators.minLength(2)] ],
      fullName: this._formBuilder.group({
        firstName: ['', [Validators.required, Validators.pattern("[a-zA-Z ]*"), Validators.minLength(2)]],
        lastName: ['', [Validators.required, Validators.pattern("[a-zA-Z ]*"), Validators.minLength(2)]],
      }),
      ageAndGrade: this._formBuilder.group({
        age: ['', [Validators.required, Validators.min(0), Validators.max(150)]],
        grade: ['', [Validators.required, Validators.min(1), Validators.max(20)]],
      }),
      email: ['', [ Validators.required, Validators.email ] ]
    });
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  onStepChange(event) {
    this.backDeg = (event.selectedIndex - event.previouslySelectedIndex) * 360;
    this.frontDeg = (event.selectedIndex - event.previouslySelectedIndex) * 90;
    setTimeout(() => this.mediator.loginStepChanges$.next({back: this.backDeg, front: this.frontDeg}));
  }

  onCountrySelect(event) {
    this.selectedCountry = event.value;
    if (!(this.languages[event.value] instanceof Array)) {
      this.shouldChooseLanguage = false;
      this.isCountryLanguageChosen = true;
      this.changeLanguage(this.languageCodes[this.languages[event.value]]);
    } else {
      this.changeLanguage('EN');
      this.shouldChooseLanguage = true;
      this.isCountryLanguageChosen = false;
    }
  }

  onLanguageSelect(event) {
    this.changeLanguage(this.languageCodes[event.value]);
    this.isCountryLanguageChosen = true;
  }

  changeLanguage(lang) {
    this.translate.setLang(lang);
    localStorage.setItem('lang', lang);
  }

  // registerGuest() {
  //   let body = {
  //     first_name: this.formGroup.get('fullName').get('firstName').value,
  //     last_name: this.formGroup.get('fullName').get('lastName').value,
  //     age: this.formGroup.get('ageAndGrade').get('age').value,
  //     grade: this.formGroup.get('ageAndGrade').get('grade').value,
  //     country: this.countryCodes[this.formGroup.get('country').value],
  //     city: this.formGroup.get('city').value
  //   }
  //   this.guest.registerGuest(this.formGroup.get('email').value, body)
  //     .pipe(takeUntil(this.destroy$))
  //     .subscribe(resp => {
  //       this.guest.setGuest(resp['guest']);
  //       this.guest.checkGuest().subscribe(r => {
  //         this.router.navigate(['learnability']);
  //       });
  //     }, err => {
  //       if (err.hasOwnProperty('error') && err.error.id_error === 20) {
  //         this.toast.showInfoToast(this.translate.instant('enterValidData'));
  //       } else {
  //         this.toast.showInfoToast(this.translate.instant('errorOccurred'));
  //       }
  //     });
  // }

}
