import { Component, OnInit, ViewEncapsulation } from '@angular/core';

export interface Lecture {
  id: number;
  name: string;
  videos?: string[];
  duration: number;
  watched: boolean;
  files?: string[];
  description?: string;
}

export interface Section {
  id: number;
  title: string;
  lectures?: Lecture[];
}

@Component({
  selector: 'app-lessons',
  templateUrl: './lessons.component.html',
  styleUrls: ['./lessons.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class LessonsComponent implements OnInit {
  panelOpenState = false;
  lessons: Section[] = [
    {
      id: 1,
      title: 'Getting Started',
    },
    {
      id: 2,
      title: 'The Basics',
    },
    {
      id: 3,
      title: 'Course Project - The Basics',
      lectures: [
        {
          id: 42,
          name: 'Project Introduction',
          videos: [
            'https://storage.googleapis.com/webfundamentals-assets/videos/chrome.webm',
            'https://storage.googleapis.com/webfundamentals-assets/videos/chrome.mp4'
          ],
          duration: 1,
          watched: true,
          description: '...'
        },
        {
          id: 43,
          name: 'Planning the App',
          videos: [
            'https://',
            'https://'
          ],
          duration: 13,
          watched: true,
          files: [
            'https://'
          ],
          description: '...'
        },
        {
          id: 44,
          name: 'Installing bootstrap Correctly',
          videos: [
            'https://',
            'https://'
          ],
          duration: 9,
          watched: false,
          description: 'Hi Everyone!...'
        },
        {
          id: 45,
          name: 'Setting up the application',
          videos: [
            'https://',
            'https://'
          ],
          duration: 10,
          watched: false,
          description: '...'
        },
        {
          id: 46,
          name: 'Creating components',
          videos: [
            'https://',
            'https://'
          ],
          duration: 5,
          watched: false,
          description: '...'
        },
        {
          id: 47,
          name: 'Using the components',
          videos: [
            'https://',
            'https://'
          ],
          duration: 15,
          watched: false,
          files: [
            'https://',
            'https://'
          ],
          description: '...'
        },
        {
          id: 48,
          name: 'Adding a navigation bar',
          videos: [
            'https://',
            'https://'
          ],
          duration: 13,
          watched: false,
          description: '...'
        },
      ]
    },
    {
      id: 4,
      title: 'Debugging',
    },
    {
      id: 5,
      title: 'Components & databinding deep dive',
    },
    {
      id: 6,
      title: 'Course project - Components & Databinding',
    },
    {
      id: 7,
      title: 'Directives Deep Dive',
    }
  ];

  constructor() { }

  ngOnInit() {
  }

}
