import {environment} from '../../environments/environment';
import {BehaviorSubject, Subject} from 'rxjs';
import {SearchContext} from '../shared/models/search-context.model';
import {Theme} from '../shared/models/theme.model';
import {LINK_DATA} from '../shared/components/sidenav/link-data';

export enum Language {
  EN = 'english',
}

export class MediatorService {
  _sideMenuOverflow = false;
  showLoader = false;
  appmenuOpen = false;
  submenuOpen = false;
  closeOverlay = false;
  openDropdownMenu = false;
  closeMobileOverlay = false;
  closeDropdownOverlay = false;

  questionWithoutGuestId = false;

  isProgressBarVisible = false;

  loginStepChanges$ = new Subject<any>();

  linkData: any = [...LINK_DATA];

  activeTheme: Theme;

  headerHeight = 64;
  mobileHeaderHeight = 49;

  isHeaderSticky = false;
  height = window.innerHeight;
  vh = window.innerHeight * 0.01;
  screenWidth = window.screen.width;
  mobileScreenHeight = window.innerHeight;
  contentHeight = this.screenWidth < environment.minMobWidth ? this.vh * 100 - this.mobileHeaderHeight : this.vh * 100 - this.headerHeight;

  breadCrumbs = [];
  savedBreadcrumb = {};
  savedRouteParams = {};
  breadcrumbsSubject = new BehaviorSubject<any>(null);

  isClientChosen = false;

  _isTreeBuilt = false;
  get isTreeBuilt() {
    return this._isTreeBuilt;
  }

  set isTreeBuilt(val) {
    this._isTreeBuilt = val;
  }

  searchInputStateSubject = new BehaviorSubject<any>(false);
  searchInputState$ = this.searchInputStateSubject.asObservable();

  searchValueSubject = new BehaviorSubject<any>(null);
  searchValue$ = this.searchValueSubject.asObservable();

  private _inputValue = '';
  get inputValue() {
    return this._inputValue;
  }

  set inputValue(val) {
    this._inputValue = val;
  }

  searchContextSubject = new BehaviorSubject<SearchContext>(null);
  searchContextChanges$ = this.searchContextSubject.asObservable();

  sidenavSearchSubject = new BehaviorSubject<any>(null);
  sidenavSearch = this.sidenavSearchSubject.asObservable();

  showStudentSelects$: Subject<boolean> = new Subject<boolean>();

  // constructor(@Inject(WINDOW) private window: Window) {
  constructor() {
    if (this.screenWidth < environment.maxMobWidth + 1) {
      this.isSidenavVisible = false;
      this.closeOverlay = false;
      localStorage.removeItem('menu_state');
    } else {
      const menuState = localStorage.getItem('menu_state');
      if (menuState !== null) {
        this.isSidenavVisible = menuState === 'true';
        this.closeOverlay = (this.screenWidth < environment.maxMobWidth + 1) && menuState === 'true';
      }
    }
  }

  _isSidenavVisible = true;

  get isSidenavVisible() {
    return this._isSidenavVisible;
  }

  set isSidenavVisible(isSidenavVisible: boolean) {
    this._isSidenavVisible = isSidenavVisible;
  }

  get sideMenuOverflow() {
    return this._sideMenuOverflow;
  }

  set sideMenuOverflow(sideMenuOverflow: boolean) {
    this._sideMenuOverflow = sideMenuOverflow;
  }

  get isSidenavOpen() {
    return this._isSidenavVisible || this._sideMenuOverflow;
  }

  toggleSidenav() {
    this.isSidenavVisible = !this.isSidenavVisible;
    localStorage.setItem('menu_state', `${this.isSidenavVisible}`);

    if (this.isSidenavVisible) {
      this.sideMenuOverflow = false;
    }
  }

  openLinkInNewWindow(url) {
    window.open(environment.urlOrigin + url, '_blank');
  }

  getHostname() {
    // return this.window.location.hostname;
  }

  getGrades(): Array<any> {
    return [
      {id: 0, name: 'predškolski uzrast'},
      {id: 1, name: '1. razred osnovne škole'},
      {id: 2, name: '2. razred osnovne škole'},
      {id: 3, name: '3. razred osnovne škole'},
      {id: 4, name: '4. razred osnovne škole'},
      {id: 5, name: '5. razred osnovne škole'},
      {id: 6, name: '6. razred osnovne škole'},
      {id: 7, name: '7. razred osnovne škole'},
      {id: 8, name: '8. razred osnovne škole'},
      {id: 9, name: '9. razred osnovne škole'},
      {id: 10, name: '1. razred srednje škole', except: ['SR', 'HR']},
      {id: 11, name: '2. razred srednje škole'},
      {id: 12, name: '3. razred srednje škole'},
      {id: 13, name: '4. razred srednje škole'},
      {id: 14, name: 'studije'},
      {id: 15, name: 'završeno školovanje'},
    ];
  }

  getGradeName(grade: number) {
    return this.getGrades().filter( arrayGrade => arrayGrade.id === grade)[0];
  }

  getGradeID(grade: string) {
    return this.getGrades().filter( arrayGrade => arrayGrade.grade === grade)[0];
  }

  languageToCountryId(lang) {
    switch (lang) {
      case 'RS': return 'srb';
      case 'HR': return 'cro';
      case 'SI': return 'slo';
      case 'BA': return 'bih';
      case 'ME': return 'mne';
      case 'MK': return 'mkd';
    }
  }

  countryIdToCountry(id) {
    switch (id) {
      case 'srb': return 'Srbija';
      case 'cro': return 'Hrvatska';
      case 'bih': return 'Bosna i Hercegovina';
      case 'slo': return 'Slovenija';
      case 'mne': return 'Crna Gora';
      case 'mac': return 'Makedonija';
    }
  }

  isLearnability() {
    return window.location.hostname === 'learnability.digitalcube.rs' ||
           window.location.hostname === 'speedreadingsmartlearning.com' ||
           window.location.hostname === 'learnability.dev.digitalcube.rs' ||
           window.location.hostname === 'learnability.loc';
  }
}



