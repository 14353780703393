import {Component, OnInit, ViewEncapsulation} from '@angular/core';

export interface Bookmark {
  id: number;
  title: string;
  lecture: string;
  date: Date;
}

@Component({
  selector: 'app-bookmarks',
  templateUrl: './bookmarks.component.html',
  styleUrls: ['./bookmarks.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class BookmarksComponent implements OnInit {
  bookmarks: Bookmark[] = [
    {
      id: 2,
      title: 'The Basics',
      lecture: '21. Fully understanding the commponent selector',
      date: new Date('February 13, 2018')
    },
    {
      id: 6,
      title: 'Course Project - Components & databinding',
      lecture: '88. Allowing the user to add ingredients to the shopping list',
      date: new Date('February 13, 2020')
    },
    {
      id: 3,
      title: 'Course Project - The Basics',
      lecture: '45. Setting up the application',
      date: new Date('February 10, 2020')
    },
  ];

  constructor() { }

  ngOnInit() {
  }

}
