import {Component, Inject, OnInit} from '@angular/core';
import {FormArray, FormBuilder, FormControl, Validators} from '@angular/forms';
import {ProgrammeService} from "../../../services/programme.service";
import {fadeIn} from "../../../animations/animations";
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {AddDocumentDialogComponent} from "./add-document-dialog/add-document-dialog.component";
import {ChangeProgrammeNameDialogComponent} from "./change-programme-name-dialog/change-programme-name-dialog.component";
import {ActivatedRoute} from '@angular/router';
import {ToastService} from '../../../services/app-toast.service';
import {MediatorService} from '../../../services/mediator.service';

@Component({
  selector: 'app-programme-form',
  templateUrl: './programme-form.component.html',
  styleUrls: ['./programme-form.component.scss'],
  animations: [ fadeIn ]
})
export class ProgrammeFormComponent implements OnInit {

  grades = [];
  selectedLesson: number = 0;

  editMode: boolean = false;
  programmeId = null;

  programmeForm = this.fb.group({
    name: ['Unesite ime programa', Validators.required],
    country: ['', Validators.required],
    grades: [[], this.validateGrades],
    is_public: [null, Validators.required],
    items: this.fb.group({
      description: ['', Validators.required],
      intro_video: ['', [Validators.required, this.validateVideoUrl]],
      programme: this.fb.array([
        this.fb.group({
          title: ['', Validators.required],
          description: ['', Validators.required],
          video: ['', [Validators.required, this.validateVideoUrl]],
          type: ['video', Validators.required],
        })
      ])
    })
  });

  validateVideoUrl(video: FormControl) {
    if (video.value.indexOf('youtu') === -1 && video.value.indexOf('vimeo') === -1) {
      return {
        valid: false
      };
    } else {
      return null;
    }
  }

  validateGrades(grades: FormControl) {
    if (!grades && !grades.value && grades.value.length === 0) {
      return {
        valid: false
      };
    } else {
      return null;
    }
  }

  constructor(private fb: FormBuilder,
              public dialog: MatDialog,
              private toast: ToastService,
              public route: ActivatedRoute,
              public mediator: MediatorService,
              private programmeService: ProgrammeService) {
    this.route.paramMap.subscribe({
      next: value => {
        if (value.get('id')) {
          this.programmeId = value.get('id');
          this.editMode = true;
          this.programmeService.getProgramme(value.get('id'));
          this.programmeService.programme$.subscribe({
            next: programme => {
              if (programme) {
                this.patchProgramme(programme);
              }
            }
          });
        }
      }
    });
  }

  ngOnInit() {
    this.programmeService.getCountries();
    this.grades = this.mediator.getGrades();
  }

  patchProgramme(programme) {
    this.programme.clear();
    for (const lesson of programme.items.programme) {
      this.addNewLesson(lesson.type);
    }
    this.selectedLesson = 0;
    this.programmeForm.patchValue({
      name: programme.name,
      grades: programme.grades,
      country: programme.id_country,
      items: programme.items,
      is_public: true,
    });
  }

  get programme() {
    return this.programmeForm.get('items').get('programme') as FormArray;
  }

  get currentFormType() {
    return this.programmeForm.get('items').get('programme').value[this.selectedLesson].type;
  }

  addNewLesson(type) {
    this.programme.push(
      this.fb.group({
        title: [''],
        description: [''],
        video: [''],
        type: [type],
      })
    );
    this.selectedLesson = (this.programme as FormArray).length - 1;
  }

  onSubmit() {
    if (this.programmeForm.get('name').value === 'Unesite ime programa') {
      this.toast.showInfoToast('Promenite ime programa');
      return;
    }
    if (this.programmeForm.valid) {
      if (!this.editMode) {
        this.programmeService.addProgramme(this.programmeForm.value).subscribe({
          next: value => {
            this.toast.showInfoToast('Program dodat');
          },
          error: err => {
            this.toast.showInfoToast('Greška u dodavanju programa');
          }
        });
      } else {
        this.programmeService.editProgramme(this.programmeId, this.programmeForm.value).subscribe({
          next: value => {
            this.toast.showInfoToast('Program uspešno promenjen');
          },
          error: err => {
            this.toast.showInfoToast('Greška u menjanju programa');
          }
        });
      }
    } else {
      console.log(this.programmeForm)
      this.toast.showInfoToast('enterValidData');
    }
  }

  onGradeToggle(ev, grade) {
    if (!this.programmeForm.get('grades').value.includes(grade)) {
      this.programmeForm.get('grades').value.push(grade);
    } else {
      const index = this.programmeForm.get('grades').value.indexOf(grade);
      if (index > -1) {
        this.programmeForm.get('grades').value.splice(index, 1);
      }
    }
    console.log(this.programmeForm.get('grades').value);
  }

  openAddNewLessionDialog(): void {
    const dialogRef = this.dialog.open(AddDocumentDialogComponent, {
      width: '324px',
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result && result.type && result.type !== '') {
        this.addNewLesson(result.type);
      }
    });
  }

  openChangeNameDialog(): void {
    const dialogRef = this.dialog.open(ChangeProgrammeNameDialogComponent, {
      width: '324px',
      data: {name:
        this.programmeForm.get('name').value && this.programmeForm.get('name').value !== 'Unesite ime programa'
          ? this.programmeForm.get('name').value : null
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result && result.name && result.name !== '') {
        this.programmeForm.patchValue({
          name: result.name,
        });
      }
    });
  }

  videoURL(url) {
    return this.programmeService.videoURL(url, true);
  }
}
