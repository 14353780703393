import {Component, OnDestroy, OnInit} from '@angular/core';
import { GuestService } from 'src/app/services/guest.service';
import {LoggedUserService} from '../../services/logged-user.service';
import {LookupService} from '../../utils/lookup.service';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {ToastService} from '../../services/app-toast.service';
import {RequestsService} from '../../services/requests.service';
import {environment} from '../../../environments/environment';
import {version} from '../../../app.version';

@Component({
  selector: 'app-edu-header',
  templateUrl: './edu-header.component.html',
  styleUrls: ['./edu-header.component.scss']
})
export class EduHeaderComponent implements OnInit, OnDestroy {
  isGuest: boolean = false;
  isUser: boolean = false;
  isAdmin: boolean = false;

  environment = environment;
  version = version;

  destroy$: Subject<boolean> = new Subject();

  constructor(private guest: GuestService, private loggedUser: LoggedUserService, private lookup: LookupService,
              private toast: ToastService, private requests: RequestsService) { }

  ngOnInit() {
    this.guest.isGuest$.pipe(takeUntil(this.destroy$)).subscribe({
      next: value => this.isGuest = value,
    });
    this.loggedUser.isUserLoggedIn$.pipe(takeUntil(this.destroy$)).subscribe({
      next: value => this.isUser = value,
    });
    this.loggedUser.isLearnabilityAdmin$.pipe(takeUntil(this.destroy$)).subscribe({
      next: value => this.isAdmin = value,
    });
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  isGuestOrUser() {
    return (this.isGuest || this.isUser);
  }

  signOutGuest() {
    this.guest.logoutGuest();
  }

  logoutUser() {
    this.requests.logoutUser().pipe(takeUntil(this.destroy$)).subscribe(res => {
      this.loggedUser.logout(res);
      this.loggedUser.loginData = {username: '', password: ''};
    }, error => {
      this.loggedUser.logout(error);
      this.toast.showError();
    });
  }

  // clearLocalStorage() {
  //   localStorage.removeItem('guest-id');
  //   localStorage.removeItem('lang');
  //   localStorage.removeItem('guest-info');
  //
  //   window.location.reload();
  // }
}
