import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {FormBuilder, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {ToastService} from '../../services/app-toast.service';
import {MediatorService} from '../../services/mediator.service';
import {RequestsService} from '../../services/requests.service';
import {LoggedUserService} from '../../services/logged-user.service';
import {TranslationService} from '../../utils/translate.service';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';

@Component({
  selector: 'app-change-password-dialog',
  templateUrl: './change-password-dialog.component.html',
  styleUrls: ['./change-password-dialog.component.scss']
})
export class ChangePasswordDialogComponent implements OnDestroy {
  isVisible = false;

  changePasswordForm = this.fb.group({
    oldPassword: this.fb.control(null, Validators.required),
    newPassword: this.fb.control(null, Validators.required)
  });

  destroy$: Subject<boolean> = new Subject();

  constructor(
    private fb: FormBuilder,
    private toast: ToastService,
    public mediator: MediatorService,
    private requests: RequestsService,
    public loggedUser: LoggedUserService,
    private translate: TranslationService,
    public dialogRef: MatDialogRef<ChangePasswordDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
  }

  get oldPassword() {
    return this.changePasswordForm.get('oldPassword');
  }

  get newPassword() {
    return this.changePasswordForm.get('newPassword');
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  confirm() {
    if (this.changePasswordForm.valid) {
      if (this.oldPassword.value === this.newPassword.value) {
        this.toast.showInfoToast(this.translate.instant('passwordsShouldNotMatch'));
      } else {
        this.requests.svc_post('/user/password/change', {
          old_password: this.oldPassword.value,
          new_password: this.newPassword.value,
        }, this.loggedUser.getToken())
          .pipe(takeUntil(this.destroy$)).subscribe((resp: any) => {
          this.toast.showInfoToast(this.translate.instant('passwordSuccessfullyChanged'));
          this.dialogRef.close(true);
        }, err => this.toast.showError());
      }
    } else {
      this.toast.showInfoToast(this.translate.instant('enterValidData'));
    }
  }

  cancel() {
    this.dialogRef.close(false);
  }
}
