import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {ToastService} from '../../../../services/app-toast.service';
import * as moment from 'moment';
import {TranslationService} from '../../../../utils/translate.service';
import {FranchisesService} from '../../franchises.service';
import {LoggedUserService} from '../../../../services/logged-user.service';
import {FormService} from '../../../../services/form.service';
import {FormBuilder, Validators} from '@angular/forms';
import {RequestsService} from '../../../../services/requests.service';
import {Subject} from 'rxjs';
import {concatMap, mergeMap, takeUntil} from 'rxjs/operators';
import {OverviewService} from '../../../overview/overview.service';
import {environment} from 'src/environments/environment';
import {animate, keyframes, style, transition, trigger} from '@angular/animations';
import {MatSnackBar} from '@angular/material';
import {MediatorService} from '../../../../services/mediator.service';

export enum OwnerType {
  NEW = 'new',
  EXISTING = 'existing',
}

@Component({
  selector: 'app-franchise-form',
  templateUrl: './franchise-form.component.html',
  styleUrls: ['./franchise-form.component.scss'],
  providers: [FormService],
  animations: [
    trigger('fadeIn', [
      transition(':enter',
        animate(200,
          keyframes([
            style({opacity: 0, offset: 0}),
            style({opacity: 0.6, offset: 1})
          ])
        ))
    ])
  ]
})
export class FranchiseFormComponent implements OnInit, OnDestroy {
  OwnerType = OwnerType;
  environment = environment;
  isMasterFranchise = false;
  chosenType;

  franchisorList = [];

  minDate = new Date(2018, 0, 1);
  maxDate = new Date(2025, 0, 1);

  inEditMode: boolean = false;

  newFranchisorForm = this.fb.group({
    franchiser_first_name: this.fb.control(null, Validators.required),
    franchiser_last_name: this.fb.control(null, Validators.required),
    franchiser_email: this.fb.control(null, [Validators.required, Validators.email]),
    franchiser_password: this.fb.control(null, [Validators.required]),
    owner_is_instructor: this.fb.control(false, Validators.required)
  });

  existingFranchisorForm = this.fb.group({
    owner: this.fb.control(null, Validators.required),
  });

  franchiseForm = this.fb.group({
    name: this.fb.control(null, [Validators.required]),
    company_nr: this.fb.control(null, []),
    // TODO: Validators.minLength(13) problem: on 13 chars or more, validator doesn't update the state
    tax_nr: this.fb.control(null, []),
    establishment_date: this.fb.control({value: moment().toISOString(), disabled: true}),
    // city: this.fb.control(null, Validators.required),
    // country: this.fb.control(null, Validators.required),
    description: this.fb.control(null),
  });

  destroy$: Subject<boolean> = new Subject();

  constructor(
    private router: Router,
    private fb: FormBuilder,
    private toast: ToastService,
    public snackbar: MatSnackBar,
    private route: ActivatedRoute,
    public overview: OverviewService,
    public mediator: MediatorService,
    private requests: RequestsService,
    public franchises: FranchisesService,
    public loggedUser: LoggedUserService,
    private translate: TranslationService) {
    this.route.data.subscribe({
      next: value => {
        if (value && value.mode && value.mode === 'edit') {
          this.inEditMode = true;
        }
      }
    });
  }

  get establishmentDate() {
    return this.franchiseForm.get('establishment_date');
  }

  ngOnInit() {
    this.chosenType = this.inEditMode ? OwnerType.EXISTING : OwnerType.NEW;

    if (!this.inEditMode) {
      this.franchises.currentFranchise = this.franchises.masterFranchiseData;

      this.route.parent.parent.params.pipe(
        concatMap(params => {
          this.isMasterFranchise = !params.hasOwnProperty('master-franchise-id');
          const id = this.loggedUser.isFranchisor && !this.loggedUser.isAdmin ?
            this.loggedUser.franchises[0].id : +params['master-franchise-id'];
          return this.requests.svc_get(`/api/franchises/${id}`, {}, this.loggedUser.getToken());
        }),
        mergeMap((data: any) => {
          this.franchises.franchiseData = data;
          return this.requests.svc_get(`/api/franchises/${this.franchises.franchiseData.id}/users`, {}, this.loggedUser.getToken());
        }), takeUntil(this.destroy$)).subscribe((data: any) => {
        if (data != null) {
          this.franchisorList = data.users.filter(user => user.is_franchiser === true);
        }
      });
    } else {
      if (this.loggedUser.isMaster || this.loggedUser.isAdmin) {
        this.requests.svc_get(`/api/franchises/${this.franchises.currentFranchise.parent.id}/users`,
          {}, this.loggedUser.getToken()).subscribe({
          next: (data: any) => {
            this.franchisorList = data.users.filter(user => user.is_franchiser === true);
            this.existingFranchisorForm.patchValue({
              owner: this.franchisorList.find( franchiser => {
                return franchiser.id === this.franchises.currentFranchise.owner.id;
              })
            });
          }
        });
      }
      this.franchiseForm.patchValue(this.franchises.currentFranchise);
    }
  }

  ngOnDestroy() {
    this.mediator.isProgressBarVisible = false;
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  changeOwnerForm(type) {
    this.chosenType = type;
    this.newFranchisorForm.reset();
    this.existingFranchisorForm.reset();
    this.newFranchisorForm.get('owner_is_instructor').setValue(false);
  }

  submit() {
    if (this.franchiseForm.valid && ((this.existingFranchisorForm.valid || this.newFranchisorForm.valid) || this.inEditMode)) {
      this.mediator.isProgressBarVisible = true;
      let ownerData = {};

      if (this.chosenType === this.OwnerType.NEW && !this.inEditMode) {
        ownerData = {...this.newFranchisorForm.getRawValue()};
      } else if (this.chosenType === this.OwnerType.EXISTING && !this.inEditMode) {
        ownerData = {
          id_owner: this.existingFranchisorForm.get('owner').value.id,
          owner_is_instructor: this.existingFranchisorForm.get('owner').value.is_instructor,
        };
      }

      if (!this.inEditMode) {
        this.requests.svc_put('/api/franchises',
          {
            ...this.franchiseForm.getRawValue(),
            master_franchise: false, // this.isMasterFranchise
            city: this.franchises.franchiseData.city,
            id_country: this.franchises.franchiseData.country.id,
            id_parent_franchise: this.loggedUser.isFranchisor && !this.loggedUser.isAdmin ?
              this.loggedUser.franchises[0].id : this.franchises.franchiseData.id,
            establishment_date: moment(this.establishmentDate.value).format('YYYY-MM-DD'),
            ...ownerData,
          }, this.loggedUser.getToken()).pipe(takeUntil(this.destroy$)).subscribe(resp => {
          this.snackbar.open(this.translate.instant('franchiseSuccessfullyAdded'), null, {duration: 1500})
            .afterDismissed().subscribe(() => this.routeBack());
        }, err => {
          if (err.error.key) {
            this.toast.showError(err.error.key);
          } else {
            this.toast.showError();
          }
          this.mediator.isProgressBarVisible = false;
        });
      } else {
        this.requests.svc_patch(`/api/franchise/${this.franchises.currentFranchise.id}`, {
          ...this.franchiseForm.getRawValue(),
          // ...ownerData
        })
          .pipe(takeUntil(this.destroy$)).subscribe({
          next: value => {
            this.franchises.stateChange$.next('franchiseEdited');
            this.snackbar.open(this.translate.instant('franchiseEditedSuccessfully'), null, {duration: 1500})
              .afterDismissed().subscribe(() => this.routeBack());
          },
          error: err => {
            if (err.error.key) {
              this.toast.showError(err.error.key);
            } else {
              this.toast.showError();
            }
            this.mediator.isProgressBarVisible = false;}
        });
      }

    } else {
      this.toast.showInfoToast(this.translate.instant('enterValidData'));
    }
  }

  routeBack() {
    this.router.navigate(['../'], {relativeTo: this.route});
  }
}
